import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, UncontrolledPopover } from 'reactstrap';
import logoBox from '../../../assets/calima_box_icon.svg';
import logoConnect from '../../../assets/calima_connect_icon.svg';
import logoGer from '../../../assets/calima_ger_icon.svg';
import logoMcg from '../../../assets/calima_mcg_icon.svg';
import logoMcp from '../../../assets/calima_mcp_icon.svg';
import logoMfp from '../../../assets/calima_mfp_icon.svg';
import logoMho from '../../../assets/calima_mho_icon.svg';
import logoMic from '../../../assets/calima_mic_icon.svg';
import logoMlf from '../../../assets/calima_mlf_icon.svg';
import logoMpr from '../../../assets/calima_mpr_icon.svg';
import logoNitrus from '../../../assets/calima_nitrus_icon.svg';
import logoSite from '../../../assets/calima_site_icon.svg';
import { Reducers } from '../../../store/ducks';
import { GlobalState, toggleMenuModulosMobile } from '../../../store/ducks/global.duck';
import MenuModulos from '../menuModulos/MenuModulos';
import './HeaderModulos.css';

const ICON_MENU = {
  BOX: logoBox,
  CONNECT: logoConnect,
  GER: logoGer,
  MCG: logoMcg,
  MCP: logoMcp,
  MFP: logoMfp,
  MHO: logoMho,
  MHO_NITRUS: logoNitrus,
  MIC: logoMic,
  MLF: logoMlf,
  MPR: logoMpr,
  SITE: logoSite,
};

const HeaderModulos: FC = () => {
  const { accessOldHonorarios, currentState, globalParameter, menuModulosMobile } = useSelector<Reducers, GlobalState>(state => state.globalReducer);
  const location = useLocation();
  const dispatch = useDispatch();

  const toggle = () => {
    dispatch(toggleMenuModulosMobile());
  };

  useEffect(
    () => {
      if (menuModulosMobile) {
        toggle();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  );

  const getIcon = () => {
    let result = ICON_MENU.GER;
    if (currentState) {
      result = ICON_MENU[currentState];
      if (globalParameter.acesso.temAcessoAoNitrus && accessOldHonorarios && currentState === 'MHO') {
        result = ICON_MENU.MHO_NITRUS;
      }
    }
    return result;
  };

  return (
    <>
      <Button
        id="menuModulosPopover"
        color="link"
        size="sm"
        onClick={toggle}
        className="modulosMobile"
        style={{ paddingLeft: '1em', paddingRight: '1em' }}
      >
        <img src={getIcon()} alt="Módulo Icon" style={{ width: 17, height: 17, filter: 'brightness(100)' }} />
      </Button>
      <UncontrolledPopover
        placement="auto"
        trigger="legacy"
        target="menuModulosPopover"
        hideArrow={true}
        isOpen={menuModulosMobile}
        className="popover-custom-wrapper popover-custom-lg border-0 popover-modulos"
      >
        <MenuModulos mobile={true} />
      </UncontrolledPopover>
    </>
  );
};

export default HeaderModulos;
