import React from 'react';
import { Card, CardText, Col, Row } from 'reactstrap';
import {
  alertManager,
  AlertType,
  boolColumn,
  ColumnsProps,
  dateColumn,
  decimalFormatter,
  DefaultSortProps,
  DetailManagerFormProps,
  enumColumn,
  ErroList,
  FilterType,
  InputGroupInline,
  InputType,
  isEmpty,
  OrderType,
  referenceColumn,
  UseFormProps,
} from 'summer';
import { Vinculo } from '../../../../models/dtos/mfp/vinculo';
import { CATEGORIA_ENUM_VALUES } from '../../../../models/enumerated/mfp/categoriaEnum';
import { ORIGEM_PROCESSO_ENUM_VALUES } from '../../../../models/enumerated/mfp/origemProcessoEnum';
import { SIM_NAO_ENUM_VALUE } from '../../../../models/enumerated/mfp/simNaoEnum';
import { TIPO_CONTRATO_PROCESSO_JUDICIAL_ENUM_VALUES } from '../../../../models/enumerated/mfp/tipoContratoProcessoJudicialEnum';
import ProcessoTrabalhistaAnoAbonoDetailView from './ProcessoTrabalhistaAnoAbonoDetailView';
import ProcessoTrabalhistaBaseCalculoDetailView from './ProcessoTrabalhistaBaseCalculoDetailView';
import ProcessoTrabalhistaContratoDetailView from './ProcessoTrabalhistaContratoDetailView';
import ProcessoTrabalhistaMudancaCategoriaDetailView from './ProcessoTrabalhistaMudancaCategoriaDetailView';
import ProcessoTrabalhistaUnicidadeContratualDetailView from './ProcessoTrabalhistaUnicidadeContratualDetailView';

export const ERRO_CAMPO_MSG = '- Campo não pode estar em branco.';
export const MAX_MONEY_VALUE = 999999999999.99;
export const VINCULO_BROWSER_PREF = 'vinculoSelecionadoProcessoTrabalhista';

export interface VinculoField {
  vinculo?: Vinculo;
}

export const defaultSorted: DefaultSortProps[] = [
  {
    dataField: 'vinculo.trabalhador.nome',
    order: OrderType.ASC,
  },
];

export const columns: ColumnsProps[] = [
  {
    dataField: 'vinculo.trabalhador.nome',
    filter: {
      type: FilterType.TEXT,
    },
    formatter: (cell, row) => {
      return row.vinculo.nome;
    },
    sort: true,
    text: 'Vínculo',
  },
  {
    dataField: 'numero',
    filter: {
      type: FilterType.TEXT,
    },
    sort: true,
    text: 'Número',
  },
  enumColumn(
    {
      dataField: 'origem',
      filter: {
        type: FilterType.SELECT,
      },
      sort: true,
      text: 'Origem',
    },
    ORIGEM_PROCESSO_ENUM_VALUES
  ),
];

export const contratoTrabalhoDetailProps = (formProps: UseFormProps): DetailManagerFormProps => {
  const onBeforeAdd = (done: any) => {
    const { vinculo } = formProps.getValues();
    if (vinculo) {
      done();
    } else {
      alertManager.emit({
        message: <ErroList erros={[`Vínculo ${ERRO_CAMPO_MSG}`]} />,
        type: AlertType.ERROR,
      });
    }
    return { async: true };
  };

  const onBeforeEdit = (_, done) => onBeforeAdd(done);

  return {
    columns: [
      enumColumn(
        {
          dataField: 'tipo',
          sort: true,
          text: 'Tipo de contrato a que se refere o processo',
        },
        TIPO_CONTRATO_PROCESSO_JUDICIAL_ENUM_VALUES
      ),
      referenceColumn({
        dataField: 'referenciaInicial',
        sort: true,
        text: 'Referência inicial',
      }),
      referenceColumn({
        dataField: 'referenciaFinal',
        sort: true,
        text: 'Referência final',
      }),
    ],
    defaultSorted: [
      {
        dataField: 'referenciaInicial',
        order: OrderType.DESC,
      },
    ],
    detailView: ProcessoTrabalhistaContratoDetailView,
    detailViewSize: 'extralarge',
    formProps,
    modalTitle: ['eSocial', 'Configurações', 'S-2500 - Processo Trabalhista', 'Contrato de trabalho'],
    onBeforeAdd,
    onBeforeEdit,
    propName: 'contratos',
    title: 'Contratos',
  };
};

export const verificarItensEmBranco = (erros, itens, todosItensObrigatorios = false, grupo = undefined) => {
  const itensEmBranco = itens.filter(it => isEmpty(it.value));
  const condicao = todosItensObrigatorios ? itensEmBranco.length < itens.length : true;
  if (condicao) {
    if (itensEmBranco.length > 0 && grupo) {
      erros.push(grupo);
    }
    itensEmBranco.forEach(it => {
      erros.push(`${it.label} ${ERRO_CAMPO_MSG}`);
    });
  }
};

export const CardSimNao = ({ label, id, formProps, required = false, classNameCard = 'mb-2' }) => (
  <Card className={classNameCard}>
    <Row className="align-items-center my-2 px-3">
      <Col>
        <CardText>{label}</CardText>
      </Col>
      <Col xl={3}>
        <InputGroupInline
          id={id}
          formProps={{ ...formProps, validation: { required } }}
          inputDropDownProps={{ optionsProvider: SIM_NAO_ENUM_VALUE }}
          type={InputType.DROP_DOWN}
          inputProps={{ className: 'mb-0' }}
        />
      </Col>
    </Row>
  </Card>
);

export const mudancaCategoriaDetailProps = (formProps: UseFormProps): DetailManagerFormProps => {
  return {
    columns: [
      boolColumn({
        dataField: 'trabalhadorRural',
        sort: true,
        text: 'Trabalhador rural',
      }),
      enumColumn(
        {
          dataField: 'categoria',
          sort: true,
          text: 'Categoria',
        },
        CATEGORIA_ENUM_VALUES
      ),
      dateColumn({
        dataField: 'dataMudanca',
        sort: true,
        text: 'Data da mudança',
      }),
    ],
    defaultSorted: [
      {
        dataField: 'dataMudanca',
        order: OrderType.DESC,
      },
    ],
    detailView: ProcessoTrabalhistaMudancaCategoriaDetailView,
    detailViewSize: 'default',
    formProps,
    modalTitle: 'Mudança de categoria',
    propName: 'mudancasCategoria',
    title: 'Mudanças de categoria',
  };
};

export const unicidadeContratualDetailProps = (formProps: UseFormProps): DetailManagerFormProps => {
  return {
    columns: [
      {
        dataField: 'matriculaIncorporada',
        sort: true,
        text: 'Matrícula incorporada',
      },
      enumColumn(
        {
          dataField: 'categoria',
          sort: true,
          text: 'Categoria',
        },
        CATEGORIA_ENUM_VALUES
      ),
      dateColumn({
        dataField: 'dataInicioTSVE',
        sort: true,
        text: 'Data de início de TSVE',
      }),
    ],
    defaultSorted: [
      {
        dataField: 'dataInicioTSVE',
        order: OrderType.DESC,
      },
    ],
    detailView: ProcessoTrabalhistaUnicidadeContratualDetailView,
    detailViewSize: 'default',
    formProps,
    modalTitle: 'Unicidade contratual',
    propName: 'unicidadesContratuais',
    title: 'Unicidades contratuais',
  };
};

export const baseCalculoDetailProps = (formProps: UseFormProps): DetailManagerFormProps => {
  const onBeforeAdd = (done: any) => {
    const erros = [];
    const { referenciaInicial, referenciaFinal } = formProps.getValues();

    if (isEmpty(referenciaInicial)) {
      erros.push(`Referência inicial ${ERRO_CAMPO_MSG}`);
    }
    if (isEmpty(referenciaFinal)) {
      erros.push(`Referência final ${ERRO_CAMPO_MSG}`);
    }

    if (erros.length === 0) {
      done();
    } else {
      alertManager.emit({
        message: <ErroList erros={erros} />,
        type: AlertType.ERROR,
      });
    }

    return { async: true };
  };

  const onBeforeEdit = (_, done) => onBeforeAdd(done);

  return {
    columns: [
      referenceColumn({
        dataField: 'referencia',
        sort: true,
        text: 'Referência',
      }),
      {
        dataField: 'vrContribuicaoMensal',
        formatter: decimalFormatter,
        sort: true,
        text: 'Contribuição previdenciária mensal',
      },
      {
        dataField: 'vrContribuicao13',
        formatter: decimalFormatter,
        sort: true,
        text: 'Contribuição previdenciária 13º',
      },
      {
        dataField: 'vrFGTSNaoDeclaradoSefipEsocial',
        formatter: decimalFormatter,
        sort: true,
        text: 'Valor do FGTS não declarado em SEFIP e eSocial',
      },
      {
        dataField: 'vrFGTSDeclaradoSefip',
        formatter: decimalFormatter,
        sort: true,
        text: 'Valor do FGTS declarado apenas em SEFIP',
      },
    ],
    defaultSorted: [
      {
        dataField: 'referencia',
        order: OrderType.DESC,
      },
    ],
    detailView: ProcessoTrabalhistaBaseCalculoDetailView,
    detailViewSize: 'extralarge',
    formProps,
    modalTitle: 'Base de cálculo',
    onBeforeAdd,
    onBeforeEdit,
    propName: 'basesCalculo',
    title: 'Bases de cálculo',
  };
};

export const abonosDetailProps = (formProps: UseFormProps): DetailManagerFormProps => {
  return {
    columns: [
      {
        dataField: 'ano',
        sort: true,
        text: 'Ano base em que houve o abono',
      },
    ],
    detailView: ProcessoTrabalhistaAnoAbonoDetailView,
    detailViewSize: 'small',
    formProps,
    modalTitle: 'Ano(s)-base de abono',
    propName: 'anosAbono',
    title: 'Ano(s)-base de abono',
  };
};
