import { Basic } from 'summer';

export interface EmpresaVW extends Basic {
  cei?: string;
  cnpj?: string;
  cpf?: string;
  codigo?: number;
  grupo?: string;
  idEmpresaApi?: string;
  ie?: string;
  im?: string;
  inativa?: boolean;
  paralisada?: boolean;
  nomeFantasia?: string;
  razaoSocial?: string;
  referenciaMCG?: string;
  referenciaMCP?: string;
  referenciaMFP?: string;
  referenciaMLF?: string;
  origem?: string;
  removerIntegracaoNitrus?: boolean;
}

export const CLASS_NAME = 'br.com.projetusinformatica.calima.ger.model.dto.empresavw';
