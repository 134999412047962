import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { alertManager, AlertType, ERROR, InputGroupInline, InputType, isEmpty, ProcessBar, showToast } from 'summer';
import useLoading from '../../../../hooks/useLoading';
import { Satisfacao } from '../../../../models/dtos/ger/satisfacao';
import { GRAU_SATISFACAO_ENUM_VALUES } from '../../../../models/enumerated/ger/grauSatisfacaoEnum';
import { notificarSatisfacao } from '../../../../services/ger.service';
import { Reducers } from '../../../../store/ducks';
import { GlobalState } from '../../../../store/ducks/global.duck';
import { EnumWithExtraFields } from '../../../../utilities/enum.util';

import './SatisfacaoView.css';

export interface SatisfacaoViewProps {
  onClose: () => void;
}

const SatisfacaoView: FC<SatisfacaoViewProps> = props => {
  const { globalParameter } = useSelector<Reducers, GlobalState>(state => state.globalReducer);

  const formProps = useForm<Satisfacao>({ mode: 'onBlur' });
  const loading = useLoading();

  const enumSorted = [...GRAU_SATISFACAO_ENUM_VALUES];

  const handleSubmit = () => {
    const values = formProps.getValues();

    if (isEmpty(values.grauSatisfacao)) {
      alertManager.emit({
        message: 'Por favor, informe pelo menos um grau de satisfação.',
        type: AlertType.INFORMATION,
      });
    } else {
      loading.show();

      values.codigoClienteProjetus = globalParameter.usuario.usuario.replace(/\D/g, '');
      values.idGerUsuario = globalParameter.usuario.id;

      notificarSatisfacao(values, {
        errorFunction: mensagem => {
          loading.hide();
          showToast(mensagem, ERROR);
        },
        thenFunction: () => {
          loading.hide();
          props.onClose();
          alertManager.emit({
            message: 'Obrigado por participar!',
            type: AlertType.INFORMATION,
          });
        },
      });
    }
  };

  const itemSatisfacao = (enumItem: EnumWithExtraFields) => {
    return (
      <Col className="text-center">
        <FormGroup check={true}>
          <Label check={true} style={{ cursor: 'pointer' }}>
            <FontAwesomeIcon style={{ color: enumItem.extraFields.color }} icon={enumItem.extraFields.icon} />
            <div>
              <Input type="radio" name="grauSatisfacao" value={enumItem.value} innerRef={formProps.register} />
              {enumItem.label}
            </div>
          </Label>
        </FormGroup>
      </Col>
    );
  };

  return (
    <Modal isOpen={true} centered={true} backdrop="static" toggle={props.onClose} size="lg">
      <ModalHeader toggle={props.onClose}>Pesquisa de satisfação</ModalHeader>
      <ModalBody>
        <div className="text-center">
          <p className="mb-0">Ajude-nos a melhorar o Calima, participe da nossa enquete.</p>
          <p>Sua opinião é muito importante para nós.</p>
        </div>
        <Row className="satisfacao mt-4">{enumSorted.reverse().map(itemSatisfacao)}</Row>
        <Row className="mt-4">
          <Col>
            <InputGroupInline formProps={formProps} id="nome" label="Seu nome" />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputGroupInline
              formProps={{ ...formProps, validation: { maxLength: 1000 } }}
              id="comentario"
              label="Motivo"
              type={InputType.TEXT_AREA}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <ProcessBar handleSubmit={handleSubmit} handleClose={props.onClose} btnSubmitIcon="paper-plane" btnSubmitLabel="Enviar avaliação" />
      </ModalFooter>
    </Modal>
  );
};

export default SatisfacaoView;
