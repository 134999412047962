import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Col, FormGroup, Row } from 'reactstrap';
import { Box, Checkbox, DetailEditForm, DetailEditViewFormProps, InputGroupInline, InputType, isEmpty, isNotEmpty, validateUniqueProp } from 'summer';
import useCepManager from '../../../../hooks/useCepManager';
import useSearchFilter from '../../../../hooks/useSearchFilter';
import { AdministradoraEndereco } from '../../../../models/dtos/ger/administradoraEndereco';
import { tipoEnderecoSelection } from '../../../../utilities/tipoEnderecoFormatter';
import CepBairroIS from '../../../components/ger/cepBairroIS/CepBairroIS';
import CepLocalidadeIS from '../../../components/ger/cepLocalidadeIS/CepLocalidadeIS';
import CepLogradouroIS from '../../../components/ger/cepLogradouro/CepLogradouroIS';
import CepTituloIS from '../../../components/ger/cepTituloIS/CepTituloIS';
import CepUFIS from '../../../components/ger/cepUFIS/CepUFIS';

const AdministradoraEnderecoEditView: FC<DetailEditViewFormProps<AdministradoraEndereco>> = props => {
  const formProps = useForm<AdministradoraEndereco>({
    defaultValues: { ...props.selectedItem },
    mode: 'onBlur',
  });
  const cepManager = useCepManager(formProps, {
    bairro: true,
    cep: true,
    localidade: true,
    nome: 'logradouro',
    titulo: 'tipoLogradouro',
    uf: true,
  });
  const searchFilter = useSearchFilter();

  useEffect(
    () => {
      if (props.selectedItem && props.selectedItem.id) {
        setTimeout(() => {
          // @ts-ignore
          formProps.setValue('cepFmt', { cep: props.selectedItem.cep, label: props.selectedItem.cep });
        }, 500);
      }
      if (
        isEmpty(formProps.getValues().cep) &&
        !validateUniqueProp(formProps.getValues(), props.parentFormProps.getValues(), 'enderecos', 'tipoEnderecoPrincipal')
      ) {
        formProps.setValue('tipoEnderecoPrincipal', true);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const validateExtras = (formValues, currentValues, parentObject) => {
    const erros = [];

    if (!tipoEnderecoSelection(formProps.getValues())) {
      erros.push('Pelo menos um tipo de endereço deve ser marcado.');
    }

    if (parentObject.enderecos && formProps.getValues().tipoEnderecoPrincipal === true) {
      if (validateUniqueProp(formValues, parentObject, 'enderecos', 'tipoEnderecoPrincipal')) {
        erros.push('Já existe um Endereço cadastrado como principal.');
      }
    }

    return erros;
  };

  const handleChangeUf = () => {
    formProps.setValue('localidade', null);
    formProps.setValue('bairro', null);
    formProps.setValue('cepFmt', null);
    formProps.setValue('cep', null);
    formProps.setValue('tipoLogradouro', null);
  };

  const handleChangeCidade = () => {
    formProps.setValue('bairro', null);
    formProps.setValue('cepFmt', null);
    formProps.setValue('cep', null);
    formProps.setValue('tipoLogradouro', null);
  };

  const handleChangeCEP = () => {
    formProps.triggerValidation('localidade');
    formProps.triggerValidation('tipoLogradouro');

    if (isNotEmpty(formProps.getValues().bairro) && isEmpty(formProps.getValues().bairro.bairro)) {
      formProps.setValue('bairro', null);
    }
  };

  return (
    <DetailEditForm handleSubmit={props.handleSubmit} handleCancel={props.handleCancel} formProps={formProps} validateExtras={validateExtras}>
      <Row>
        <Col>
          <CepLogradouroIS
            formProps={{ ...formProps, validation: { required: true } }}
            label="CEP"
            id="cepFmt"
            inputSearchProps={{ selectProps: { isMulti: false } }}
            cepManager={cepManager}
            searchFilter={searchFilter}
            onChange={handleChangeCEP}
          />
        </Col>
        <input type="hidden" name="cep" ref={formProps.register} />
        <Col>
          <CepTituloIS
            formProps={{ ...formProps, validation: { required: true } }}
            label="Tipo de Logradouro"
            id="tipoLogradouro"
            inputSearchProps={{ selectProps: { isMulti: false } }}
            disabled={!formProps.getValues().cep}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <CepUFIS
            formProps={{ ...formProps, validation: { required: true } }}
            id="uf"
            inputSearchProps={{ selectProps: { isMulti: false } }}
            searchFilter={searchFilter}
            onChange={handleChangeUf}
          />
        </Col>
        <Col>
          <CepLocalidadeIS
            formProps={{ ...formProps, validation: { required: true } }}
            id="localidade"
            inputSearchProps={{ selectProps: { isMulti: false } }}
            searchFilter={searchFilter}
            onChange={handleChangeCidade}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <CepBairroIS
            formProps={{ ...formProps, validation: { required: true } }}
            id="bairro"
            inputSearchProps={{ selectProps: { isMulti: false } }}
            searchFilter={searchFilter}
          />
        </Col>
        <Col>
          <InputGroupInline formProps={{ ...formProps, validation: { maxLength: 150, required: true } }} id="logradouro" label="Logradouro" />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGroupInline formProps={{ ...formProps, validation: { maxLength: 10 } }} id="numero" label="Número" type={InputType.NUMBER} />
        </Col>
        <Col>
          <InputGroupInline formProps={{ ...formProps, validation: { maxLength: 60 } }} id="complemento" label="Complemento" />
        </Col>
      </Row>
      <Box title="Tipo de Endereço" className="mb-3">
        <FormGroup tag={Row}>
          <Col>
            <Checkbox id="tipoEnderecoPrincipal" label="Principal" formProps={formProps} />
          </Col>
          <Col>
            <Checkbox id="tipoEnderecoResidencial" label="Residencial" formProps={formProps} />
          </Col>
          <Col>
            <Checkbox id="tipoEnderecoCobranca" label="Cobrança" formProps={formProps} />
          </Col>
          <Col>
            <Checkbox id="tipoEnderecoComercial" label="Comercial" formProps={formProps} />
          </Col>
        </FormGroup>
      </Box>
    </DetailEditForm>
  );
};

export default AdministradoraEnderecoEditView;
