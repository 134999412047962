import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { dec, ERROR, InputGroupInline, InputType, ProcessBar, showToast, userActions } from 'summer';
import { atualizarGlobalParameter, salvarCPF } from '../../../../services/ger.service';
import { Reducers } from '../../../../store/ducks';
import { GlobalState, setGlobalParameter } from '../../../../store/ducks/global.duck';
import { redirectToLogin } from '../../../../utilities/login.util';

const UsuarioValidarExistenciaCPF: FC = () => {
  const { globalParameter } = useSelector<Reducers, GlobalState>(state => state.globalReducer);
  const formProps = useForm({ mode: 'onBlur' });
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setShow(globalParameter && globalParameter.usuario && !globalParameter.usuario.cpf);
  }, [globalParameter]);

  const close = () => {
    dispatch(userActions.logout());
    redirectToLogin();
  };

  const submit = formData =>
    new Promise<void>(resolve => {
      salvarCPF(
        { id: globalParameter.usuario.id, cpf: formData.cpf },
        {
          errorFunction: mensagem => {
            showToast(mensagem, ERROR);
            resolve();
          },
          thenFunction: () => {
            atualizarParameter(resolve);
          },
        }
      );
    });

  const atualizarParameter = resolve => {
    atualizarGlobalParameter(globalParameter.usuario, {
      errorFunction: mensagem => {
        showToast(mensagem, ERROR);
      },
      thenFunction: result => {
        dispatch(setGlobalParameter(result));
        resolve();
      },
    });
  };

  return (
    <Modal centered={true} backdrop="static" isOpen={show}>
      <ModalBody>
        <div className="alert d-flex align-items-center pl-2 pr-3 align-content-center alert-warning alert-dismissible fade show mb-0" role="alert">
          <span className="font-size-lg d-block d-40 mr-2 text-center">
            <FontAwesomeIcon icon="exclamation-triangle" />
          </span>
          <span className="text-justify">
            <strong className="d-block">Atenção!</strong>Usuário <b>{dec(globalParameter.usuario.usuario)}</b> não possui CPF cadastrado. Para
            continuar acessando o sistema informe o CPF logo abaixo.
          </span>
        </div>
        <Row className="mt-2">
          <Col>
            <InputGroupInline formProps={{ ...formProps, validation: { required: true } }} id="cpf" label="CPF" type={InputType.CPF} />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className="justify-content-end">
        <ProcessBar
          btnSubmitLabel="Salvar"
          btnCloseLabel="Fechar"
          handleSubmit={formProps.handleSubmit(submit)}
          handleClose={close}
          btnSubmitIcon="save"
        />
      </ModalFooter>
    </Modal>
  );
};

export default UsuarioValidarExistenciaCPF;
