import { Basic } from 'summer';
import { CepPais } from './cepPais';

export interface CepUF extends Basic {
  codigo?: string;
  sigla?: string;
  nome?: string;
  exterior?: boolean;
  pais?: CepPais;
}

export const CLASS_NAME = 'br.com.projetusinformatica.calima.ger.model.dto.cepuf';
