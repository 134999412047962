import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { InputGroupInline, InputProps, InputType, OperationEnum, SearchExpression } from 'summer';
import { CLASS_NAME } from '../../../../models/dtos/ger/empresaVW';
import { Reducers } from '../../../../store/ducks';
import { GlobalState } from '../../../../store/ducks/global.duck';

export interface EmpresaInputSearchProps extends InputProps {
  dtoClassName?: string;
  filtrarEmpresas?: number[];
  naoBuscarPorCnpj?: boolean;
  naoBuscarPorCpf?: boolean;
  naoBuscarPorCei?: boolean;
  naoBuscarPorIe?: boolean;
  naoBuscarPorIm?: boolean;
}

const EmpresaIS: FC<EmpresaInputSearchProps> = props => {
  const { currentState } = useSelector<Reducers, GlobalState>(state => state.globalReducer);

  const getSearchExpression = (): SearchExpression[] => {
    const expressions: SearchExpression[] = [];

    if (props.filtrarEmpresas && Array.isArray(props.filtrarEmpresas)) {
      expressions.push({
        fieldName: 'id',
        operation: OperationEnum.NUMBER_IN,
        value: props.filtrarEmpresas,
      });
    }
    return expressions;
  };

  const getColumns = () => {
    const colunas = [];
    colunas.push(
      {
        dataField: 'codigo',
        isNumber: true,
        text: 'Código',
      },
      {
        dataField: 'razaoSocial',
        text: 'Razão social',
      },
      {
        dataField: 'nomeFantasia',
        text: 'Nome fantasia',
      },
      {
        dataField: 'grupo',
        text: 'Grupo',
      }
    );

    if (!props.naoBuscarPorCnpj) {
      colunas.push({
        dataField: 'cnpj',
        text: 'CNPJ',
      });
    }
    if (!props.naoBuscarPorCpf) {
      colunas.push({
        dataField: 'cpf',
        text: 'CPF',
      });
    }
    if (!props.naoBuscarPorCei) {
      colunas.push({
        dataField: 'cei',
        text: 'CEI',
      });
    }
    if (!props.naoBuscarPorIe) {
      colunas.push({
        dataField: 'ie',
        text: 'IE',
      });
    }
    if (!props.naoBuscarPorIm) {
      colunas.push({
        dataField: 'im',
        text: 'IM',
      });
    }

    return colunas;
  };

  const existeReferencia = () => {
    let result = false;
    switch (currentState) {
      case 'MCG':
        result = true;
        break;
      case 'MCP':
        result = true;
        break;
      case 'MFP':
        result = true;
        break;
      case 'MLF':
        result = true;
        break;
    }
    return result;
  };

  return (
    <InputGroupInline
      {...props}
      searchViewProps={{
        ...props.searchViewProps,
        dtoClassName: props.dtoClassName || null,
        existeReferencia: existeReferencia() || '',
        filtrarEmpresas: props.filtrarEmpresas || [],
      }}
      inputSearchProps={{
        ...props.inputSearchProps,
        columns: getColumns(),
        dtoClassName: props.dtoClassName || CLASS_NAME,
        searchExpressions: getSearchExpression,
        searchViewId: 'EmpresaSearchView',
      }}
      label={props.label ? props.label : 'Empresa'}
      type={InputType.INPUT_SEARCH}
    />
  );
};

export default EmpresaIS;
