import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { progressActions } from 'summer';

const useLoading = () => {
  const dispatch = useDispatch();
  const loading = useRef(false);

  const show = (message = 'Aguarde processando...', topContent = null, modalSize = 'sm') => {
    if (!loading.current) {
      loading.current = true;
      dispatch(
        progressActions.showProgress({
          animated: true,
          className: 'progress-bar-rounded progress-animated-alt',
          color: 'first',
          label: message,
          modalSize,
          striped: true,
          topContent,
          value: 100,
        })
      );
    }
  };

  const hide = () => {
    if (loading.current) {
      loading.current = false;
      dispatch(progressActions.hideProgress());
    }
  };

  return { show, hide, isLoading: () => loading.current };
};

export default useLoading;
