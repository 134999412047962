import cx from 'clsx';
import React, { FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { BarLoader } from 'react-spinners';
import { Badge, Button, ListGroup, ListGroupItem, UncontrolledTooltip } from 'reactstrap';
import { EXECUTANDO, JOB_STATUS_ENUM_VALUES } from '../../../models/enumerated/ger/jobStatusEnum';
import { Reducers } from '../../../store/ducks';
import { GlobalState, toggleHeaderDrawer } from '../../../store/ducks/global.duck';
import { JobState } from '../../../store/ducks/jobs.duck';

const HeaderDrawer: FC = () => {
  const { data } = useSelector<Reducers, JobState>(state => state.jobReducer);
  const { headerDrawerToggle } = useSelector<Reducers, GlobalState>(state => state.globalReducer);
  const dispatch = useDispatch();

  function toggle() {
    dispatch(toggleHeaderDrawer());
  }

  return (
    <>
      <div className="app-drawer-content">
        <Button onClick={toggle} className="close-drawer-btn btn btn-sm" id="CloseDrawerTooltip">
          <div className={cx('navbar-toggler hamburger hamburger--elastic', { 'is-active': headerDrawerToggle })}>
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </div>
        </Button>
        <UncontrolledTooltip target="CloseDrawerTooltip" placement="left">
          Fechar
        </UncontrolledTooltip>
        <div className="vh-100 shadow-overflow">
          <PerfectScrollbar options={{ wheelPropagation: false }}>
            <div className="p-4">
              <div className="text-center">
                <div className="font-weight-bold font-size-lg mb-0 text-black">Processos</div>
              </div>

              {data && data.length > 0 && (
                <div className="rounded p-3 bg-neutral-second mt-3">
                  <div className="task-wrapper">
                    {data.map(job => (
                      <div className="task-item" key={job.id}>
                        <ListGroup flush={true}>
                          <ListGroupItem className="bg-white d-flex justify-content-between align-items-center p-4">
                            <div className="w-100">
                              <div className="d-flex flex-wrap justify-content-between mb-2">
                                <small className="line-height-xs text-primary font-weight-bold">Dt. Início: {job.dtInicio}</small>
                                {job.dtFim && <small className="line-height-xs text-success font-weight-bold">Dt. Fim: {job.dtFim}</small>}
                              </div>
                              <p className="mb-3">{job.mensagem}</p>
                              <div className="d-flex justify-content-between align-items-center">
                                <Badge pill={true} color={JOB_STATUS_ENUM_VALUES.find(it => it.value === job.status).color} className="pl-3 pr-3">
                                  {JOB_STATUS_ENUM_VALUES.find(it => it.value === job.status).label}
                                </Badge>
                              </div>
                            </div>
                          </ListGroupItem>
                          {EXECUTANDO.value === job.status && (
                            <ListGroupItem className="h-25 d-flex align-items-center justify-content-center">
                              <BarLoader color="#f83245" loading={true} height={10} width="100%" />
                            </ListGroupItem>
                          )}
                        </ListGroup>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </PerfectScrollbar>
        </div>
      </div>

      <div onClick={toggle} className={cx('app-drawer-overlay', { 'is-active': headerDrawerToggle })} />
    </>
  );
};

export default HeaderDrawer;
