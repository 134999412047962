import moment from 'moment';
import { FilterType, getObjectSubValue, SortDirection, valToString } from 'summer';

export const onSortGridTable = (state, result) => {
  return result.sort((a, b) => {
    const valA = valToString(getObjectSubValue(a, state.column.dataField));
    const valB = valToString(getObjectSubValue(b, state.column.dataField));

    let sort: number;

    if (state.column.filter && typeof state.column.filter === 'object' && state.column.filter.type === FilterType.DATE) {
      sort = moment(valA, 'DD/MM/YYYY').unix() - moment(valB, 'DD/MM/YYYY').unix();
    } else {
      sort = valA.localeCompare(valB, undefined, { numeric: true });
    }

    return sort * (state.direction === SortDirection.DESC ? -1 : 1);
  });
};
