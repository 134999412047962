import { compose, createStore } from 'redux';
import { reducers } from './ducks';

const store = createStore(reducers, {},
                          compose(
                            window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
                          ),
);

export { store };
