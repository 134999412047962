import React, { FC } from 'react';
import { Button } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNotEmpty } from 'summer';
import './ButtonHeaderApp.css';

export interface ButtonHeaderAppProps {
  /** url do site a ser aberto */
  url?: string;
  /** logo a ser exibida no botão */
  logo?: any;
  /** dependência que verifica se é possível acessar o botão */
  dependency?: any[];
  /** função que controla se o Popover será aberto ou fechado */
  toggle?: () => void;
  /** função que irá ser chamada ao clicar no botão */
  onClick?: () => void;
  /** ícone a ser exibido no botão */
  icon?: any;
  /** título que vai abaixo do ícone */
  title?: string;
  /** classes se necessarias para o botão */
  className?: string;
  /** Desabilitar o botão */
  disabled?: boolean;
}

const ButtonHeaderApp: FC<ButtonHeaderAppProps> = props => {
  const onClick = () => {
    props.toggle();
    if (props.dependency && props.dependency.length > 0) {
      if (props.dependency.every(it => it())) {
        open();
      }
    } else {
      open();
    }
  };

  const open = () => {
    if (props.onClick) {
      props.onClick();
    } else {
      openLink();
    }
  };

  const openLink = () => {
    window.open(props.url, '_blank');
  };

  return (
    <Button className={`button-header-app ${props.className}`} onClick={onClick} disabled={props.disabled}>
      <div className="icon">
        {isNotEmpty(props.icon) ? (
          <FontAwesomeIcon icon={props.icon} size={'2x'} />
        ) : (
          <img src={props.logo} alt="..." className="h1 my-2" style={{ width: 28, height: 28 }} />
        )}
      </div>
      <span>{props.title}</span>
    </Button>
  );
};

export default ButtonHeaderApp;
