import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ERROR, proActions, showToast } from 'summer';
import { salvarUsuarioVisualizouTour } from '../../../../services/ger.service';
import { Reducers } from '../../../../store/ducks';
import ButtonCalimaPro from '../../../components/buttonCalimaPro/ButtonCalimaPro';
import FullSizeModal, { FullSizeModalRef } from '../../../components/fullSizeModal/FullSizeModal';

import wellcome1 from '../../../../assets/wellcome1.png';
import wellcome2 from '../../../../assets/wellcome2.png';
import wellcome3 from '../../../../assets/wellcome3.png';
import wellcome4 from '../../../../assets/wellcome4.png';
import wellcome5 from '../../../../assets/wellcome5.png';

import './ModalWellcomeCalimaPro.css';

const IMAGES = [wellcome1, wellcome2, wellcome3, wellcome4, wellcome5];

const ModalWellcomeCalimaPro: FC = () => {
  const {
    proReducer: { showWellcomeModal },
    globalReducer: { globalParameter },
  } = useSelector<Reducers, Reducers>(state => state);

  const modal = useRef<FullSizeModalRef>();
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(0);

  useEffect(() => {
    if (!showWellcomeModal) {
      setVisible(0);
    }
  }, [showWellcomeModal]);

  const onClose = () => {
    dispatch(proActions.toggleWellcomeModal(false));
    salvarUsuarioVisualizouTour(globalParameter.usuario.id, 'BEM_VINDO_PRO', {
      errorFunction: mensagem => showToast(mensagem, ERROR),
      thenFunction: () => null,
    });
  };

  const prev = () => {
    if (visible > 0) {
      setVisible(visible - 1);
    }
  };

  const next = () => {
    if (visible < IMAGES.length - 1) {
      setVisible(visible + 1);
    }
  };

  const navigate = index => {
    if (index >= 0 && index < IMAGES.length && index !== visible) {
      setVisible(index);
    }
  };

  const close = () => modal.current.close();

  return (
    showWellcomeModal && (
      <FullSizeModal className="modal-wellcome-calima-pro" onClose={onClose} ref={modal} aspectRatio={1.7769}>
        <div className="slide">
          {IMAGES.map((src, index) => (
            <div key={index} className={cx('image', { visible: visible === index })} style={{ backgroundImage: `url(${src})` }} />
          ))}
          <div className="nav-wrapper">
            <div className="nav">
              <button className="arrow-left" onClick={prev}>
                <FontAwesomeIcon icon="chevron-left" />
              </button>
              {IMAGES.map((_, index) => (
                <div key={index} className={cx('item', { active: visible === index })} onClick={navigate.bind(null, index)} />
              ))}
              <button className="arrow-right" onClick={next}>
                <FontAwesomeIcon icon="chevron-right" />
              </button>
            </div>
          </div>
          <button className="area next" onClick={next} disabled={visible === IMAGES.length - 1} />
          <button className="area prev" onClick={prev} disabled={visible === 0} />
        </div>
        <ButtonCalimaPro label="Começar a usar o " onClick={close} className={cx({ visible: visible === IMAGES.length - 1 })} />
      </FullSizeModal>
    )
  );
};

export default ModalWellcomeCalimaPro;
