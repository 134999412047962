import React, { FC, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import {
  AutoCompleteOff,
  ERROR,
  FileSelector,
  FileSelectorRef,
  FileUploader,
  FileUploaderRef,
  InputGroupInline,
  InputType,
  ProcessBar,
  showToast,
  SUCCESS,
} from 'summer';
import useLoading from '../../../../hooks/useLoading';
import { AbrirTicketParam } from '../../../../models/dtos/ger/abrirTicketParam';
import { getModulosComAcessoTicket } from '../../../../models/enumerated/ger/moduloTicketEnum';
import { abrirTicket, notificaTickets } from '../../../../services/ger.service';
import { Reducers } from '../../../../store/ducks';
import { GlobalState } from '../../../../store/ducks/global.duck';
import EmpresaIS from '../../../components/ger/empresaIS/EmpresaIS';

interface AbrirTicketModalViewProps {
  arquivoObrigatorio?: boolean;
  consultarTickets?: () => void;
  files?: File[];
  onClose: () => void;
  openFromTickets?: boolean;
  selectedItem?: AbrirTicketParam;
}

const AbrirTicketModalView: FC<AbrirTicketModalViewProps> = props => {
  const { globalParameter } = useSelector<Reducers, GlobalState>(selector => selector.globalReducer);

  const fileUpload = useRef<FileUploaderRef>();
  const fileSelector = useRef<FileSelectorRef>();
  const loading = useLoading();
  const history = useHistory();

  const formProps = useForm<AbrirTicketParam>({
    defaultValues: {
      empresas: [globalParameter.empresa],
      ...props.selectedItem,
    },
    mode: 'onBlur',
  });

  useEffect(() => {
    setTimeout(() => {
      if (fileSelector.current && props.files) {
        fileSelector.current.setFiles(props.files);
      }
    }, 500); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = formData => {
    fileUpload.current.upload().then(resultUpload => {
      loading.show();
      abrirTicket(
        { ...formData, idArquivos: resultUpload.id, usuario: globalParameter.usuario.nomeCompleto, email: globalParameter.usuario.email },
        {
          errorFunction: mensagem => {
            loading.hide();
            showToast(mensagem, ERROR);
          },
          thenFunction: result => {
            loading.hide();
            let message = 'Ticket aberto com sucesso!';
            if (history.location.pathname !== '/ger/ticketSearchView') {
              message += `<ToastRedirect path='/ger/ticketSearchView/${result.numero}' label='Clique aqui para visualizar'/>`;
            }
            showToast(message, SUCCESS);
            notificaTickets(globalParameter.usuario, {
              thenFunction: () => null,
            });
            onClose();
            if (props.consultarTickets) {
              props.consultarTickets();
            }
          },
        }
      );
    });
  };

  const onClose = () => {
    formProps.reset();
    if (props.onClose) {
      props.onClose();
    }
  };

  const validateExtras = () => {
    const erros = [];
    if (props.arquivoObrigatorio && fileUpload.current && fileUpload.current.count() === 0) {
      erros.push('Para esse tipo de ticket, é obrigatório informar pelo menos um arquivo.');
    }
    return erros;
  };

  return (
    <Modal isOpen={true} centered={true} size="xl" backdrop="static" toggle={onClose}>
      <ModalHeader toggle={onClose}>Novo ticket</ModalHeader>
      <ModalBody>
        <AutoCompleteOff>
          <Row>
            <Col>
              <InputGroupInline formProps={{ ...formProps, validation: { required: true, maxLength: 250 } }} id="assunto" label="Assunto" />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroupInline
                formProps={{ ...formProps, validation: { required: true, maxLength: 1000 } }}
                inputProps={{ rows: 5 }}
                type={InputType.TEXT_AREA}
                id="descricao"
                label="Descrição"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroupInline
                id="modulo"
                label="Módulo"
                formProps={{ ...formProps, validation: { required: true } }}
                inputDropDownProps={{ optionsProvider: getModulosComAcessoTicket(globalParameter.usuario.acessoModulos) }}
                type={InputType.DROP_DOWN}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EmpresaIS
                id="empresas"
                label="Empresa(s)"
                formProps={{ ...formProps, validation: { required: true } }}
                tooltipProps={{ message: 'Selecione a(s) empresa(s) afetada(s)' }}
                inputSearchProps={{ bsSize: 'xl', selectProps: { isMulti: true } }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FileUploader formProps={formProps} ref={fileUpload}>
                <FileSelector ref={fileSelector} accept="*" multiple={true} id="arquivosTicket" />
              </FileUploader>
            </Col>
          </Row>
        </AutoCompleteOff>
      </ModalBody>
      <ModalFooter>
        <ProcessBar handleSubmit={formProps.handleSubmit(onSubmit)} handleClose={onClose} hideIcons={true} validateExtras={validateExtras} />
      </ModalFooter>
    </Modal>
  );
};

export default AbrirTicketModalView;
