import { MenuItem } from '../../../models/dtos/ger/menuItem';

const siteMenu: MenuItem[] = [
  {
    icon: 'pe-7s-home',
    label: 'Home',
    to: '/site/dashboard',
  },
];

export default siteMenu;
