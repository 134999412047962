import React, { FC } from 'react';
import { InputGroupInline, InputProps, InputType } from 'summer';
import { SearchFilterProps } from '../../../../hooks/useSearchFilter';
import { CLASS_NAME } from '../../../../models/dtos/ger/cepBairro';
import { CLASS_NAME as CLASS_NAME_LOCALIDADE } from '../../../../models/dtos/ger/cepLocalidade';
import { CLASS_NAME as CLASS_NAME_UF } from '../../../../models/dtos/ger/cepUF';

export interface CepBairroISProps extends InputProps {
  searchFilter?: SearchFilterProps;
}

const CepBairroIS: FC<CepBairroISProps> = props => {
  const searchExpressionsFull = defaultExpressions => {
    let expressions = [];

    if (props.searchFilter) {
      const localidade = props.searchFilter.getFilter(CLASS_NAME_LOCALIDADE);
      const uf = props.searchFilter.getFilter(CLASS_NAME_UF);
      if (localidade) {
        expressions = [
          {
            fieldName: 'localidade.id',
            value: localidade.id,
          },
        ];
      } else if (uf) {
        expressions = [
          {
            fieldName: 'localidade.uf.id',
            value: uf.id,
          },
        ];
      }
    }

    if (expressions.length > 0) {
      expressions[0].expressions = defaultExpressions;
    } else {
      expressions.push(...defaultExpressions);
    }

    return expressions;
  };

  const onChange = value => {
    if (props.searchFilter) {
      props.searchFilter.setFilter(CLASS_NAME, value);
    }
    if (props.onChange) {
      props.onChange(value);
    }
  };

  return (
    <InputGroupInline
      {...props}
      inputSearchProps={{
        ...props.inputSearchProps,
        columns: [
          {
            dataField: 'bairro',
            text: 'Nome do Bairro',
          },
          {
            dataField: 'localidade.nome',
            text: 'Nome da cidade',
          },
          {
            dataField: 'localidade.uf.sigla',
            text: 'Sigla da UF',
          },
        ],
        dtoClassName: CLASS_NAME,
        searchExpressionsFull,
        searchViewId: 'CepBairroSearchView',
      }}
      label={props.label ? props.label : 'Bairro'}
      type={InputType.INPUT_SEARCH}
      onChange={onChange}
      searchViewProps={{ searchFilter: props.searchFilter }}
    />
  );
};

export default CepBairroIS;
