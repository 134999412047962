import { alertManager, AlertProps, AlertType, ERROR, findByExample, showToast } from 'summer';
import { CLASS_NAME, EmpresaMLF } from '../models/dtos/mlf/empresaMLF';

export function checkContabilistaResponsavel(empresaMLF: EmpresaMLF) {
  findByExample(empresaMLF, CLASS_NAME, {
    errorFunction: mensagem => {
      showToast(mensagem, ERROR);
    },
    thenFunction: (result: EmpresaMLF) => {
      if (result && !result.responsavel) {
        const params: AlertProps = {
          message: `A empresa selecionada não possui um contabilista ou responsável pela escrituração fiscal do estabelecimento. Para configurar acesse:
    \n Módulo Fiscal > Manutenção > Configurações > Empresa`,
          type: AlertType.WARNING,
        };
        alertManager.emit(params);
      }
    },
  });
}
