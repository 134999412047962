const tiposEndereco = {
  tipoEnderecoCobranca: 'Cobrança',
  tipoEnderecoComercial: 'Comercial',
  tipoEnderecoPrincipal: 'Principal',
  tipoEnderecoResidencial: 'Residencial',
};

export function tipoEnderecoSelection(tipoEndereco: any) {
  const resultado = [];
  // tslint:disable-next-line:forin
  for (const campo in tiposEndereco) {
    // tslint:disable-next-line:no-unused-expression
    tipoEndereco[campo] && resultado.push(tiposEndereco[campo]);
  }
  return resultado.join(', ');
}

export function tipoEnderecoFormatter(cell, row) {
  return tipoEnderecoSelection(row);
}
