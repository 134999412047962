import { Basic } from 'summer';
import { GrupoRelatorioItem } from './grupoRelatorioItem';

export interface GrupoRelatorio extends Basic {
  codigo?: number;
  /** Este campo utiliza o ModuloEnum */
  modulo?: string;
  nome?: string;
  relatorios?: GrupoRelatorioItem[];
}

export const CLASS_NAME = 'br.com.projetusinformatica.calima.ger.model.dto.gruporelatorio';
export const GRUPO_REL_UPDATE_EVENT = 'GRUPO_REL_UPDATE_EVENT';
