import { MenuItem } from '../../../models/dtos/ger/menuItem';

const contasAPagar = 'Conta a pagar';
const lancamento = 'Lançamento';

const mhoMenu: MenuItem[] = [
  {
    icon: 'pe-7s-home',
    label: 'Home',
    to: '/mho/dashboard',
  },
  {
    content: [
      {
        content: [
          {
            label: lancamento,
            to: '/mho/contaPagarMHOSearchView',
          },
          {
            label: 'Histórico',
            to: '/mho/historicoPagarMHOSearchView',
          },
        ],
        label: contasAPagar,
      },
      {
        content: [
          {
            label: lancamento,
            to: '/mho/movimentacaoMensalSearchView',
          },
        ],
        label: 'Conta a receber',
      },
      {
        content: [
          {
            label: 'Evento',
            to: '/mho/eventoMHOSearchView',
          },
          {
            label: 'Código de tributação - Município',
            to: '/mho/codigoTributacaoServicoSearchView',
          },
          {
            label: 'Customização de termos e documentos',
            to: '/mho/customizacaoTermoMHOSearchView',
          },
          {
            label: 'Lista item serviço',
            to: '/mho/listaServicoSearchView',
          },
          {
            label: 'Multa',
            to: '/mho/multaSearchView',
          },
          {
            label: 'Selic',
            to: '/mho/selicSearchView',
          },
        ],
        label: 'Tabelas',
      },
      {
        content: [
          {
            label: 'Administradora',
            to: '/mho/administradoraMHOEditView',
          },
          {
            label: 'Empresa',
            to: '/mho/configEmpresaMHOSearchView',
          },
          {
            label: 'Integração contábil - Evento',
            to: '/mho/contabilizacaoEventoSearchView',
          },
        ],
        label: 'Configurações',
      },
    ],
    icon: 'pe-7s-edit',
    label: 'Manutenção',
  },
  {
    content: [
      {
        label: 'Calcular honorários',
        to: '/mho/calcularHonorarioProcessView',
      },
      {
        label: 'Consultar honorários calculados',
        to: '/mho/consultarCalculosHonorarioProcessView',
      },
      {
        label: 'Reajustar valores fixos',
        to: '/mho/reajusteValoresFixosProcessView',
      },
      {
        label: 'Baixar honorários',
        to: '/mho/baixaHonorarioProcessView',
      },
      {
        label: 'Fechamento/Restauração',
        to: '/mho/fechamentoRestauracaoMHOEditView',
      },
    ],
    icon: 'pe-7s-play',
    label: 'Processos',
  },
  {
    content: [
      {
        content: [
          {
            content: [
              {
                label: 'Gerar remessa',
                to: '/mho/gerarRemessaMHOSearchView',
              },
              {
                label: 'Configuração',
                to: '/mho/configAdministradoraMHOEditView',
              },
            ],
            label: 'Boletos',
          },
          {
            label: 'Emissão de NFS-e',
            to: '/mho/emissaoNfseProcessView',
          },
        ],
        label: 'Exportação',
      },
      {
        content: [
          {
            label: 'Contas a pagar - Planilha do excel (Formato CSV, XLS e XLSX)',
            to: '/mho/importacaoExcelContasPagarProcessView',
          },
        ],
        label: 'Importação',
      },
    ],
    icon: 'pe-7s-folder',
    label: 'Arquivos digitais',
  },
  {
    content: [
      {
        label: 'Carta de aviso de cobrança',
        to: '/mho/cartaCobrancaReportView',
      },
      {
        label: contasAPagar,
        to: '/mho/contaPagarMHOReportView',
      },
      {
        label: 'Contrato de prestação de serviços',
        to: '/mho/contratoPrestacaoServicoReportView',
      },
      {
        label: 'Fluxo de caixa',
        to: '/mho/fluxoCaixaMHOReportView',
      },
      {
        label: 'Recibo de honorários',
        to: '/mho/reciboHonorarioReportView',
      },
      {
        content: [
          {
            label: 'Referência',
            to: '/mho/totalFaturamentoReportView',
          },
          {
            label: 'Evento',
            to: '/mho/totalFaturamentoEventoReportView',
          },
        ],
        label: 'Total de faturamento',
      },
      {
        label: 'Relação de documentos baixados/abertos',
        to: '/mho/baixaHonorarioReportView',
      },
      {
        label: 'Relação de boletos exportados',
        to: '/mho/boletoExportadosReportView',
      },
      {
        label: 'Termo de consentimento para tratamento de dados pessoais - LGPD',
        to: '/mho/consentimentoLGPDReportView',
      },
    ],
    icon: 'pe-7s-print',
    isReportList: true,
    label: 'Relatórios',
  },
];

export default mhoMenu;
