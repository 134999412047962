import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import { saveAs } from 'file-saver';
import React, { FC, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router-dom';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Collapse,
  ListGroup,
  ListGroupItem,
  Nav,
  NavItem,
  NavLink as NavLinkStrap,
  UncontrolledTooltip,
} from 'reactstrap';
import { ERROR, generateTestId, showToast } from 'summer';
import { Notificacao } from '../../../models/dtos/ger/notificacao';
import { fileDownload } from '../../../services/report.service';

interface NotificationProps {
  color: string;
  hour: string;
  notification: Notificacao;
  esconderNotificacao: (notification: Notificacao) => void;
  codigosApagados: string[];
  fecharJanelaNotificacoes: () => void;
}

const Notification: FC<NotificationProps> = ({ color, hour, notification, esconderNotificacao, codigosApagados, fecharJanelaNotificacoes }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [icon, setIcon] = useState<IconName>('angle-right');
  const history = useHistory();

  const toggle = () => {
    setIsOpen(!isOpen);
    setIcon(isOpen ? 'angle-right' : 'angle-up');
  };

  const closeNotification = () => {
    esconderNotificacao(notification);
  };

  const downloadRelatorio = () => {
    const { urlArquivo } = notification.paramMap;
    if (urlArquivo) {
      fileDownload(urlArquivo, {
        errorFunction: (mensagem: string) => {
          showToast(mensagem, ERROR);
        },
        thenFunction: (response: any) => {
          const filename = response.headers['content-disposition'].split('filename=')[1];
          saveAs(response.data, filename);
        },
      });
    }
  };

  const copytoClipboard = () => {
    showToast('Mensagem copiada!', { title: 'Notificação', icon: 'bell', type: 'default' });
  };

  const renderDetails = () => {
    let result = null;
    if (notification.categoria === 'empresa' && notification.detalhes) {
      result = (
        <>
          <div className="my-2 divider" />
          <div className="panel-title">
            <div className="accordion-toggle">
              <Button className="w-100 d-flex align-items-center justify-content-between" color="link" onClick={toggle}>
                <span>Ver Detalhes</span>
                <span>
                  <FontAwesomeIcon icon={icon} className="font-size-xl accordion-icon" />
                </span>
              </Button>
            </div>
          </div>
          <Collapse isOpen={isOpen}>
            <PerfectScrollbar options={{ wheelPropagation: false }}>
              <ListGroup flush={true} style={{ maxHeight: 230 }}>
                {notification.detalhes.map(detalhe => {
                  let detailResult;
                  if (detalhe.trabalhadores) {
                    detailResult = (
                      <>
                        <ListGroupItem key={detalhe.empresa.id} tag="a" className="align-items-center d-flex list-group-item-action pl-3">
                          <FontAwesomeIcon icon={['far', 'building']} className="nav-icon-wrapper text-primary font-size-lg mr-2" />
                          <span>{detalhe.empresa.razaoSocial}</span>
                        </ListGroupItem>
                        <Nav pills={true} className="nav-neutral-first nav-lg flex-column p-3">
                          {detalhe.trabalhadores.map(vinculo => (
                            <NavItem key={vinculo.id}>
                              <NavLinkStrap className="pl-2">
                                <div className="nav-link-icon opacity-7">
                                  <FontAwesomeIcon icon={['far', 'user-circle']} />
                                </div>
                                <span>{vinculo.nome}</span>
                              </NavLinkStrap>
                            </NavItem>
                          ))}
                        </Nav>
                      </>
                    );
                  } else {
                    detailResult = (
                      <ListGroupItem key={detalhe.empresa.id} tag="a" className="align-items-center d-flex list-group-item-action pl-3">
                        <FontAwesomeIcon icon={['far', 'building']} className="nav-icon-wrapper text-primary font-size-lg mr-2" />
                        <span>{detalhe.empresa.razaoSocial}</span>
                      </ListGroupItem>
                    );
                  }

                  return detailResult;
                })}
              </ListGroup>
            </PerfectScrollbar>
          </Collapse>
        </>
      );
    }
    return result;
  };

  const redirecionarTela = () => {
    fecharJanelaNotificacoes();
    history.push(/path='(.*?)'/.exec(notification.mensagem)[1]);
  };

  const renderNotification = () => {
    let result = (
      <div className="mb-3 d-flex justify-content-between">
        <strong>{notification.mensagem}</strong>
      </div>
    );

    if (notification.paramMap.urlArquivo) {
      result = (
        <div
          id="dowloadNotificationPopover"
          className="mb-3 d-flex justify-content-between"
          style={{ cursor: 'pointer' }}
          onClick={downloadRelatorio}
          {...generateTestId('dowloadNotificationPopover')}
        >
          <strong>{notification.mensagem.replace('Abra a janela de notificações para baixar.', 'Clique aqui para baixar.')}</strong>
          <UncontrolledTooltip placement="top" target="dowloadNotificationPopover">
            Clique para baixar
          </UncontrolledTooltip>
        </div>
      );
    } else if (notification.link) {
      result = (
        <div className="mb-3 d-flex flex-column">
          <strong>{notification.mensagem}</strong>
          <span style={{ marginTop: 10 }}>
            <a href={notification.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>
              Clique aqui para acessar!
            </a>
          </span>
        </div>
      );
    } else if (notification.mensagem.indexOf('<ToastRedirect') >= 0) {
      result = (
        <div className="mb-3 d-flex flex-column">
          <strong>{notification.mensagem.replace(/<ToastRedirect[^>]*>/, '')}</strong>
          <br />
          <span>
            <Button className="p-0" onClick={redirecionarTela} style={{ textDecoration: 'underline' }} color="link" size="sm">
              Clique aqui para acessar!
            </Button>
          </span>
        </div>
      );
    }

    return result;
  };

  return (
    <Card className={cx('card-box mx-3 mb-3 mt-0 card-notification', { hidden: codigosApagados.includes(notification.codigo) })}>
      <div className={`card-indicator bg-${color}`} />
      <CardBody className="px-4 py-3">
        <div className="mb-3 d-flex justify-content-end">
          <CopyToClipboard
            text={
              notification.mensagem.indexOf('<ToastRedirect') >= 0 ? notification.mensagem.replace(/<ToastRedirect[^>]*>/, '') : notification.mensagem
            }
            onCopy={copytoClipboard}
          >
            <span className="mr-3 copy-notification" style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon icon="copy" size="lg" />
            </span>
          </CopyToClipboard>
          <span className="text-dark remove-notification" onClick={closeNotification} style={{ cursor: 'pointer' }}>
            <FontAwesomeIcon icon="times" size="lg" />
          </span>
        </div>
        {renderNotification()}
        <div className="d-flex align-items-center justify-content-start">
          <Badge color={color} className="px-3">
            <FontAwesomeIcon icon="clock" className="mr-1" />
            {hour}
          </Badge>
        </div>
        {renderDetails()}
      </CardBody>
    </Card>
  );
};

export default Notification;
