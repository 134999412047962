import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { AutoCompleteOff, DetailEditForm, DetailEditViewFormProps, InputGroupInline } from 'summer';
import { AnoAbonoProcessoTrabalhista } from '../../../../models/dtos/mfp/anoAbonoProcessoTrabalhista';

const ProcessoTrabalhistaAnoAbonoDetailView: FC<DetailEditViewFormProps<AnoAbonoProcessoTrabalhista>> = props => {
  const formProps = useForm<AnoAbonoProcessoTrabalhista>({ defaultValues: props.selectedItem || {}, mode: 'onBlur' });

  return (
    <DetailEditForm handleSubmit={props.handleSubmit} handleCancel={props.handleCancel} formProps={formProps}>
      <Row>
        <Col>
          <AutoCompleteOff>
            <InputGroupInline formProps={{ ...formProps, validation: { required: true, maxLength: 4, minLength: 4 } }} id="ano" label="Ano abono" />
          </AutoCompleteOff>
        </Col>
      </Row>
    </DetailEditForm>
  );
};

export default ProcessoTrabalhistaAnoAbonoDetailView;
