import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { forwardRef, ReactNode, useEffect, useImperativeHandle, useState } from 'react';

import './FullSizeModal.css';

interface FullSizeModalProps {
  children: ReactNode;
  className?: string;
  onClose: () => void;
  aspectRatio: number;
}

export interface FullSizeModalRef {
  close: () => void;
}

const FullSizeModal = forwardRef<FullSizeModalRef, FullSizeModalProps>((props, ref) => {
  const [sizeOrientation, setSizeOrientation] = useState('height-orientation');
  const [visible, setVisible] = useState(false);

  useEffect(
    () => {
      setTimeout(() => setVisible(true), 50);
      window.addEventListener('resize', onResize, false);
      onResize();
      return () => window.removeEventListener('resize', onResize);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useImperativeHandle(ref, () => ({
    close: handleOnClose,
  }));

  const onResize = () => {
    const modalWidth = Math.round(window.innerHeight * 0.85 * props.aspectRatio);
    setSizeOrientation(modalWidth > window.innerWidth * 0.8 ? 'width-orientation' : 'height-orientation');
  };

  const handleOnClose = () => {
    setVisible(false);
    setTimeout(props.onClose, 500);
  };

  return (
    <div className={cx('full-size-modal', { visible })}>
      <div className={cx('modal-window', props.className, sizeOrientation)}>
        <button className="close-modal" onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        {props.children}
      </div>
    </div>
  );
});

export default FullSizeModal;
