export const CLASSIFICACAO_01 = {
  label: '01 - Empresa enquadrada no regime de tributação Simples Nacional com tributação previdenciária substituída',
  value: 'CLASSIFICACAO_01',
};
export const CLASSIFICACAO_02 = {
  label: '02 - Empresa enquadrada no regime de tributação Simples Nacional com tributação previdenciária não substituída',
  value: 'CLASSIFICACAO_02',
};
export const CLASSIFICACAO_03 = {
  label: '03 - Empresa enquadrada no regime de tributação Simples Nacional com tributação previdenciária substituída e não substituída',
  value: 'CLASSIFICACAO_03',
};
export const CLASSIFICACAO_04 = { label: '04 - MEI - Micro Empreendedor Individual', value: 'CLASSIFICACAO_04' };
export const CLASSIFICACAO_06 = { label: '06 - Agroindústria', value: 'CLASSIFICACAO_06' };
export const CLASSIFICACAO_07 = { label: '07 - Produtor Rural Pessoa Jurídica', value: 'CLASSIFICACAO_07' };
export const CLASSIFICACAO_08 = { label: '08 - Consórcio Simplificado de Produtores Rurais', value: 'CLASSIFICACAO_08' };
export const CLASSIFICACAO_09 = { label: '09 - Órgão Gestor de Mão de Obra', value: 'CLASSIFICACAO_09' };
export const CLASSIFICACAO_10 = { label: '10 - Entidade Sindical a que se refere a Lei 12.023/2009', value: 'CLASSIFICACAO_10' };
export const CLASSIFICACAO_11 = { label: '11 - Associação Desportiva que mantém Clube de Futebol Profissional', value: 'CLASSIFICACAO_11' };
export const CLASSIFICACAO_13 = {
  label:
    '13 - Banco, caixa econômica, sociedade de crédito, financiamento e investimento e demais empresas relacionadas no parágrafo 1º do art. 22 da Lei 8.212./91',
  value: 'CLASSIFICACAO_13',
};
export const CLASSIFICACAO_14 = { label: '14 - Sindicatos em geral, exceto aquele classificado no código [10]', value: 'CLASSIFICACAO_14' };
export const CLASSIFICACAO_21 = { label: '21 - Pessoa Física, exceto Segurado Especial', value: 'CLASSIFICACAO_21' };
export const CLASSIFICACAO_22 = { label: '22 - Segurado Especial', value: 'CLASSIFICACAO_22' };
export const CLASSIFICACAO_60 = { label: '60 - Missão Diplomática ou Repartição Consular de carreira estrangeira', value: 'CLASSIFICACAO_60' };
export const CLASSIFICACAO_70 = { label: '70 - Empresa de que trata o Decreto 5.436/2005', value: 'CLASSIFICACAO_70' };
export const CLASSIFICACAO_80 = {
  label: '80 - Entidade Beneficente de Assistência Social isenta de contribuições sociais',
  value: 'CLASSIFICACAO_80',
};
export const CLASSIFICACAO_85 = { label: '85 - Ente Federativo, Órgãos da União, Autarquias e Fundações Públicas', value: 'CLASSIFICACAO_85' };
export const CLASSIFICACAO_99 = { label: '99 - Pessoas Jurídicas em Geral', value: 'CLASSIFICACAO_99' };

export const CLASSIFICACAO_TRIBUTARIA_ENUM_VALUES = [
  CLASSIFICACAO_01,
  CLASSIFICACAO_02,
  CLASSIFICACAO_03,
  CLASSIFICACAO_04,
  CLASSIFICACAO_06,
  CLASSIFICACAO_07,
  CLASSIFICACAO_08,
  CLASSIFICACAO_09,
  CLASSIFICACAO_10,
  CLASSIFICACAO_11,
  CLASSIFICACAO_13,
  CLASSIFICACAO_14,
  CLASSIFICACAO_21,
  CLASSIFICACAO_22,
  CLASSIFICACAO_60,
  CLASSIFICACAO_70,
  CLASSIFICACAO_80,
  CLASSIFICACAO_85,
  CLASSIFICACAO_99,
];

export const CONTRIB_PREVIDENCIARIA_PATRONAL_VALUES = [CLASSIFICACAO_02, CLASSIFICACAO_03, CLASSIFICACAO_09, CLASSIFICACAO_99];

export const CONTRIB_PREVIDENCIARIA_PRODUTOR_VALUES = [CLASSIFICACAO_07, CLASSIFICACAO_08, CLASSIFICACAO_21];
