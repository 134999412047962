import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { BarLoader } from 'react-spinners';
import { Badge, Button, Popover, TabContent, TabPane, UncontrolledTooltip } from 'reactstrap';
import { currentDate, ERROR, formatTime, generateTestId, isNotEmpty, showToast } from 'summer';
import { Notificacao } from '../../../models/dtos/ger/notificacao';
import { AGUARDANDO, EXECUTANDO } from '../../../models/enumerated/ger/jobStatusEnum';
import { limparNotificacoes, marcarNotificacaoVista } from '../../../services/ger.service';
import { Reducers } from '../../../store/ducks';
import { abrirJanelaProcessos } from '../../../store/ducks/global.duck';
import { clearNotifications, removeNotification } from '../../../store/ducks/notifications.duck';
import { hasUtilitario, isChrome } from '../../../utilities/navigator.util';
import Notification from './Notification';

import './HeaderNotifications.css';

const TIME_PATTERN = 'HH:mm';

interface GlobalNotification {
  process?: Notificacao[];
  messages?: Notificacao[];
  tickets?: Notificacao[];
}

const HeaderNotifications: FC = () => {
  const {
    notificationReducer: { data },
    jobReducer: { data: jobs },
    globalReducer: { globalParameter },
  } = useSelector<Reducers, Reducers>(state => state);

  const [globalNotification, setGlobalNotification] = useState<GlobalNotification>({ process: [], messages: [], tickets: [] });
  const [activeTab, setActiveTab] = useState('process');
  const [isOpen, setIsOpen] = useState(false);
  const [runningJobs, setRunningJobs] = useState(false);
  const [todasNotificacoesApagadas, setTodasNotificacoesApagadas] = useState(false);
  const [codigosApagados, setCodigosApagados] = useState<string[]>([]);

  const renderDownloadButton = hasUtilitario() && isChrome() && activeTab !== 'tickets';
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const process: Notificacao[] = [];
    const messages: Notificacao[] = [];
    const tickets: Notificacao[] = [];

    data.forEach(item => {
      if (item.tipo === 'ticket') {
        tickets.push(item);
      } else if (item.tipo === 'mensagemController') {
        messages.push(item);
      } else {
        if (item.tipo !== 'mensagensNotification') {
          process.push(item);
        }
      }
    });

    setGlobalNotification({
      messages,
      process,
      tickets,
    });
  }, [data]);

  useEffect(() => {
    const filterJobs = jobs.filter(it => it.status === EXECUTANDO.value || it.status === AGUARDANDO.value);
    setRunningJobs(filterJobs.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs]);

  const sortNotifications = (notificationA: Notificacao, notificationB: Notificacao) => {
    let comparison = 0;
    if (notificationA.data > notificationB.data) {
      comparison = 1;
    } else if (notificationA.data < notificationB.data) {
      comparison = -1;
    }
    return comparison * -1;
  };

  const toggleTab = (tab: string) => {
    return () => {
      if (activeTab !== tab) {
        setActiveTab(tab);
      }
    };
  };

  const getTotalLabel = () => {
    let label;
    const total = getTotal();
    if (total === 0) {
      label = 'Você não possui nenhuma notificação';
    } else {
      const msg = total === 1 ? 'notificação' : 'notificações';
      label = (
        <>
          Você tem <b className="text-info">{total}</b> {msg}
        </>
      );
    }

    return <span>{label}</span>;
  };

  const openProcessos = () => {
    toggle();
    dispatch(abrirJanelaProcessos());
  };

  const openDownloads = () => {
    window.UTILITARIO_CALIMA_INSTANCE.openDownloads();
  };

  const openTickets = () => {
    toggle();
    history.push('/ger/ticketSearchView');
  };

  const toggle = () => {
    if (isOpen) {
      if (todasNotificacoesApagadas) {
        limparNotificacoes(globalParameter.usuario.id, {
          errorFunction: mensagem => showToast(mensagem, ERROR),
          thenFunction: () => {
            dispatch(clearNotifications());
            setTodasNotificacoesApagadas(false);
          },
        });
      } else {
        Object.values(globalNotification).forEach(arr =>
          arr.forEach(notificacao => {
            if (codigosApagados.includes(notificacao.codigo)) {
              marcarNotificacaoVista(notificacao, {
                thenFunction: () => dispatch(removeNotification({ codigo: notificacao.codigo })),
              });
            }
          })
        );
      }
    }
    setIsOpen(p => !p);
  };

  const limpar = () => {
    setTodasNotificacoesApagadas(true);
    const codigos = [];
    Object.values(globalNotification).forEach(arr => {
      arr.forEach(it => codigos.push(it.codigo));
    });
    setCodigosApagados(codigos);
  };

  const renderPaneItem = (list: Notificacao[]) => {
    list.sort(sortNotifications);
    let items = [];
    if (list && list.length > 0) {
      items = list
        .filter(item => isNotEmpty(item.mensagem))
        .map(item => {
          let hour = formatTime(currentDate(), TIME_PATTERN);

          if (item.hora) {
            const splitedHour = item.hora.split(':');
            hour = `${splitedHour[0]}:${splitedHour[1]}`;
          }

          let color;
          switch (item.status) {
            case 'information':
              color = 'info';
              break;
            case 'warning':
              color = 'warning';
              break;
            case 'error':
              color = 'danger';
              break;
            default:
              color = 'first';
          }

          return (
            <Notification
              key={item.codigo}
              color={color}
              hour={hour}
              notification={item}
              codigosApagados={codigosApagados}
              esconderNotificacao={esconderNotificacao}
              fecharJanelaNotificacoes={toggle}
            />
          );
        });
    } else {
      items.push(
        <div className="d-flex justify-content-center align-items-center" key="no-notification">
          <h4 className="mt-2 font-weight-bold text-second">Nenhuma notificação</h4>
        </div>
      );
    }

    return <PerfectScrollbar>{items}</PerfectScrollbar>;
  };

  const filtrarItens = (list: Notificacao[], apagados = false) => {
    return list.filter(it => codigosApagados.includes(it.codigo) === apagados).length;
  };

  const getTotal = (apagados = false) =>
    Object.values(globalNotification)
      .map(list => filtrarItens(list, apagados))
      .reduce((t, n) => n + t, 0);

  const esconderNotificacao = (notification: Notificacao) => {
    setCodigosApagados(p => [...p, notification.codigo]);
  };

  const mostrarNotificacoes = () => {
    if (todasNotificacoesApagadas) {
      setCodigosApagados([]);
      setTodasNotificacoesApagadas(false);
    } else {
      const codigosTabAtiva = globalNotification[activeTab].map(it => it.codigo);
      setCodigosApagados(codigosApagados.filter(it => !codigosTabAtiva.includes(it)));
    }
  };

  const NotificacoesApagadas = () => {
    const total = todasNotificacoesApagadas ? getTotal(true) : filtrarItens(globalNotification[activeTab], true);
    const msg = total === 1 ? 'notificação' : 'notificações';
    return (
      <div className={cx('font-weight-bold text-center py-1 text-notification-deleted', { invisible: total === 0 })}>
        <span>
          Você apagou {total} {msg}.
        </span>
        <Button color="link" className="font-weight-bold p-0" onClick={mostrarNotificacoes}>
          <u>Clique aqui para desfazer</u> <FontAwesomeIcon className="ml-1" icon="undo" />
        </Button>
      </div>
    );
  };

  const TabNotificacao = ({ name, tabName, list }) => {
    const item = name.toLowerCase();
    return (
      <>
        <input type="radio" name="tab" id={item} checked={activeTab === tabName} onChange={toggleTab(tabName)} />
        <label htmlFor={item} className={`${item} mb-0 py-3 font-weight-bold position-relative`}>
          <div>
            {name}
            <span className="badge-total rounded-circle">{filtrarItens(list)}</span>
          </div>
        </label>
      </>
    );
  };

  return (
    <>
      <Button
        id="alertsPopover"
        className={cx('btn-pill d-40 p-0 badge-wrapper', { 'notification-active': isOpen })}
        {...generateTestId('alertsPopover')}
      >
        <span>
          {getTotal() > 0 && (
            <Badge color="first" className="badge-position badge-position--top-right badge-pill badge-notification">
              <span className="text-white badge-notification-count">{getTotal()}</span>
            </Badge>
          )}
          <FontAwesomeIcon icon={['fas', 'bell']} size="lg" />
        </span>
        <UncontrolledTooltip placement="left" target="alertsPopover">
          Visualizar Notificações
        </UncontrolledTooltip>
      </Button>
      <Popover
        target="alertsPopover"
        trigger="legacy"
        container="body"
        className="popover-custom-wrapper popover-custom-xl popover-notification"
        placement="auto"
        isOpen={isOpen}
        toggle={toggle}
      >
        <div className="bg-notification text-center rounded-sm">
          <div className="bg-composed-wrapper--content text-white px-2 py-4">
            <h2 className="font-weight-bold mt-1 mb-2">Notificações</h2>
            {getTotalLabel()}
          </div>
        </div>
        <div className="notification-tabs m-3">
          <TabNotificacao name="Processos" tabName="process" list={globalNotification.process} />
          <TabNotificacao name="Mensagens" tabName="messages" list={globalNotification.messages} />
          <TabNotificacao name="Tickets" tabName="tickets" list={globalNotification.tickets} />
          <div className="tab" />
        </div>
        <div className="d-flex align-items-center flex-row-reverse justify-content-between mx-3 pt-3 notification-actions-container">
          <Button className="btn-clear-notifications" onClick={limpar} id="clear-notifications" {...generateTestId('clear-notifications')}>
            Limpar tudo
            <FontAwesomeIcon className="ml-2" icon={['fas', 'trash']} />
          </Button>
          <UncontrolledTooltip target="clear-notifications">Limpar todas as notificações</UncontrolledTooltip>
          {renderDownloadButton && (
            <>
              <Button className="btn-toggle-downloads" onClick={openDownloads} id="toggle-downloads" {...generateTestId('toggle-downloads')}>
                <span>Meus downloads</span>
                <FontAwesomeIcon icon={['fas', 'file-download']} />
              </Button>
              <UncontrolledTooltip target="toggle-downloads">Exibir meus downloads</UncontrolledTooltip>
            </>
          )}
          {activeTab === 'tickets' && (
            <>
              <Button color="primary" onClick={openTickets} {...generateTestId('ver-todos-tickets')}>
                <span className="font-weight-normal">Ver todos os tickets</span>
                <FontAwesomeIcon icon="external-link-alt" />
              </Button>
            </>
          )}
        </div>
        <NotificacoesApagadas />
        <TabContent activeTab={activeTab} className={cx({ 'd-none': todasNotificacoesApagadas })}>
          <TabPane className="scrollbar-container" tabId="process">
            {renderPaneItem(globalNotification.process)}
          </TabPane>
          <TabPane className="scrollbar-container" tabId="messages">
            {renderPaneItem(globalNotification.messages)}
          </TabPane>
          <TabPane className="scrollbar-container" tabId="tickets">
            {renderPaneItem(globalNotification.tickets)}
          </TabPane>
        </TabContent>
        <div className="notification-footer-container m-3 pt-2 d-flex align-items-center justify-content-center">
          <Button className="btn-toggle-processos rounded-circle" onClick={openProcessos} id="toggle-processos">
            <FontAwesomeIcon icon={['fas', 'redo-alt']} spin={runningJobs} />
          </Button>
          <Button color="link" size="sm" onClick={openProcessos} id="toggle-processos" {...generateTestId('toggle-processos')}>
            <h6 className={cx('font-weight-bold mb-0', { 'text-decoration-underline': !runningJobs })}>Visualizar processos em execução ></h6>
            {runningJobs && <BarLoader color="#253053" loading={true} width="100%" css="margin-top: -2px" />}
          </Button>
          <UncontrolledTooltip target="toggle-processos">Exibir processos em andamento</UncontrolledTooltip>
        </div>
      </Popover>
    </>
  );
};

export default HeaderNotifications;
