export const TRABALHADOR_FORMALIZADO_SEM_ALTERACAO_ADMISSAO_DESLIGAMENTO = {
  label: 'Trabalhador com vínculo formalizado, sem alteração nas datas de admissão e de desligamento',
  value: 'TRABALHADOR_FORMALIZADO_SEM_ALTERACAO_ADMISSAO_DESLIGAMENTO',
};
export const TRABALHADOR_FORMALIZADO_COM_ALTERACAO_ADMISSAO = {
  label: 'Trabalhador com vínculo formalizado, com alteração na data de admissão',
  value: 'TRABALHADOR_FORMALIZADO_COM_ALTERACAO_ADMISSAO',
};
export const TRABALHADOR_FORMALIZADO_COM_INCLUSAO_ALTERACAO_DESLIGAMENTO = {
  label: 'Trabalhador com vínculo formalizado, com inclusão ou alteração de data de desligamento',
  value: 'TRABALHADOR_FORMALIZADO_COM_INCLUSAO_ALTERACAO_DESLIGAMENTO',
};
export const TRABALHADOR_FORMALIZADO_COM_ALTERACAO_ADMISSAO_DESLIGAMENTO = {
  label: 'Trabalhador com vínculo formalizado, com alteração nas datas de admissão e de desligamento',
  value: 'TRABALHADOR_FORMALIZADO_COM_ALTERACAO_ADMISSAO_DESLIGAMENTO',
};
export const EMPREGADO_RECONHECIMENTO_VINCULO = { value: 'EMPREGADO_RECONHECIMENTO_VINCULO', label: 'Empregado com reconhecimento de vínculo' };
export const TRABALHADOR_SEM_VINCULO_EMPREGO_ESTATUTARIO = {
  label: 'Trabalhador sem vínculo de emprego/estatutário (TSVE), sem reconhecimento de vínculo empregatício',
  value: 'TRABALHADOR_SEM_VINCULO_EMPREGO_ESTATUTARIO',
};

export const TRABALHADOR_COM_VINCULO_EMPREGO_ANTERIOR_ESOCIAL = {
  label: 'Trabalhador com vínculo de emprego formalizado em período anterior ao eSocial',
  value: 'TRABALHADOR_COM_VINCULO_EMPREGO_ANTERIOR_ESOCIAL',
};

export const RESPONSABILIDADE_INDIRETA = {
  label: 'Responsabilidade indireta',
  value: 'RESPONSABILIDADE_INDIRETA',
};

export const TRABALHADOR_CONTRATOS_UNIFICADOS = {
  label: 'Trabalhador cujos contratos foram unificados (unicidade contratual)',
  value: 'TRABALHADOR_CONTRATOS_UNIFICADOS',
};

export const TIPO_CONTRATO_PROCESSO_JUDICIAL_ENUM_VALUES = [
  TRABALHADOR_FORMALIZADO_SEM_ALTERACAO_ADMISSAO_DESLIGAMENTO,
  TRABALHADOR_FORMALIZADO_COM_ALTERACAO_ADMISSAO,
  TRABALHADOR_FORMALIZADO_COM_INCLUSAO_ALTERACAO_DESLIGAMENTO,
  TRABALHADOR_FORMALIZADO_COM_ALTERACAO_ADMISSAO_DESLIGAMENTO,
  EMPREGADO_RECONHECIMENTO_VINCULO,
  TRABALHADOR_SEM_VINCULO_EMPREGO_ESTATUTARIO,
  TRABALHADOR_COM_VINCULO_EMPREGO_ANTERIOR_ESOCIAL,
  RESPONSABILIDADE_INDIRETA,
  TRABALHADOR_CONTRATOS_UNIFICADOS,
];
