import React, { FC } from 'react';
import { InputGroupInline, InputProps, InputType } from 'summer';
import { CLASS_NAME } from '../../../../models/dtos/ger/grupoRelatorio';

const GrupoRelatorioIS: FC<InputProps> = props => {
  return (
    <InputGroupInline
      {...props}
      inputSearchProps={{
        ...props.inputSearchProps,
        columns: [
          {
            dataField: 'codigo',
            isNumber: true,
            text: 'Código',
          },
          {
            dataField: 'descricao',
            text: 'Descrição',
          },
        ],
        dtoClassName: CLASS_NAME,
        searchViewId: 'GrupoRelatorioSearchView',
      }}
      label={props.label ? props.label : 'Grupo de Relatórios'}
      type={InputType.INPUT_SEARCH}
    />
  );
};

export default GrupoRelatorioIS;
