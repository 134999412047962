import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Col, FormGroup, Row } from 'reactstrap';
import {
  Box,
  Checkbox,
  DetailEditForm,
  DetailEditViewFormProps,
  InputGroupInline,
  isEmpty,
  isNotEmpty,
  onlyAlphaNum,
  validateUniqueProp,
} from 'summer';
import useCepManager from '../../../../hooks/useCepManager';
import useSearchFilter from '../../../../hooks/useSearchFilter';
import { Endereco } from '../../../../models/dtos/ger/endereco';
import { tipoEnderecoSelection } from '../../../../utilities/tipoEnderecoFormatter';
import CepBairroIS from '../../../components/ger/cepBairroIS/CepBairroIS';
import CepLocalidadeIS from '../../../components/ger/cepLocalidadeIS/CepLocalidadeIS';
import CepLogradouroIS from '../../../components/ger/cepLogradouro/CepLogradouroIS';
import CepTituloIS from '../../../components/ger/cepTituloIS/CepTituloIS';
import CepUFIS from '../../../components/ger/cepUFIS/CepUFIS';

const EmpresaEnderecoDetailEditView: FC<DetailEditViewFormProps<Endereco>> = props => {
  const formProps = useForm<Endereco>({ defaultValues: props.selectedItem || {}, mode: 'onBlur' });
  const [isDisableEndPrincipal, setDisableEndPrincipal] = useState(false);
  const searchFilter = useSearchFilter();
  const cepManager = useCepManager(formProps, {
    bairro: true,
    cep: true,
    localidade: true,
    nome: 'logradouro',
    titulo: 'tipoLogradouro',
    uf: true,
  });

  useEffect(
    () => {
      setTimeout(() => {
        // @ts-ignore
        if (props.selectedItem) {
          formProps.setValue('cepFmt', { cep: props.selectedItem.cep, label: props.selectedItem.cep }, true);
        }
      }, 500);

      const { enderecos } = props.parentFormProps.getValues();
      const { cep } = formProps.getValues();
      const validadeEmpty = isEmpty(enderecos) && isEmpty(cep);
      const validadeEnderecos =
        enderecos && !validateUniqueProp(formProps.getValues(), props.parentFormProps.getValues(), 'enderecos', 'tipoEnderecoPrincipal');

      formProps.setValue('tipoEnderecoPrincipal', false);
      setDisableEndPrincipal(true);

      if (validadeEmpty || validadeEnderecos) {
        formProps.setValue('tipoEnderecoPrincipal', true);
        setDisableEndPrincipal(false);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const validateExtras = (formValues, currentValues, parentObject) => {
    const erros = [];

    if (!tipoEnderecoSelection(formProps.getValues())) {
      erros.push('Pelo menos um tipo de endereço deve ser marcado.');
    }

    if (parentObject.enderecos && formProps.getValues().tipoEnderecoPrincipal === true) {
      if (validateUniqueProp(formValues, parentObject, 'enderecos', 'tipoEnderecoPrincipal')) {
        erros.push('Já existe um endereço cadastrado como principal.');
      }
    }

    return erros;
  };

  const handleChangeUf = () => {
    formProps.setValue('localidade', null);
    formProps.setValue('bairro', null);
    formProps.setValue('cepFmt', null);
    formProps.setValue('cep', null);
    formProps.setValue('tipoLogradouro', null);
  };

  const handleChangeCidade = () => {
    formProps.setValue('bairro', null);
    formProps.setValue('cepFmt', null);
    formProps.setValue('cep', null);
    formProps.setValue('tipoLogradouro', null);
  };

  const handleChangeCEP = () => {
    formProps.triggerValidation('localidade');
    formProps.triggerValidation('tipoLogradouro');

    if (isNotEmpty(formProps.getValues().bairro) && isEmpty(formProps.getValues().bairro.bairro)) {
      formProps.setValue('bairro', null);
    }
  };

  return (
    <DetailEditForm handleSubmit={props.handleSubmit} handleCancel={props.handleCancel} formProps={formProps} validateExtras={validateExtras}>
      <Row>
        <Col>
          <CepLogradouroIS
            formProps={{ ...formProps, validation: { required: true } }}
            id="cepFmt"
            label="CEP"
            cepManager={cepManager}
            searchFilter={searchFilter}
            onChange={handleChangeCEP}
          />
          <input type="hidden" name="cep" ref={formProps.register} />
        </Col>
        <Col>
          <CepTituloIS id="tipoLogradouro" formProps={{ ...formProps, validation: { required: true } }} disabled={!formProps.getValues().cep} />
        </Col>
      </Row>
      <Row>
        <Col>
          <CepUFIS formProps={{ ...formProps, validation: { required: true } }} id="uf" searchFilter={searchFilter} onChange={handleChangeUf} />
        </Col>
        <Col>
          <CepLocalidadeIS
            formProps={{ ...formProps, validation: { required: true } }}
            id="localidade"
            searchFilter={searchFilter}
            onChange={handleChangeCidade}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <CepBairroIS formProps={{ ...formProps, validation: { required: true } }} id="bairro" searchFilter={searchFilter} />
        </Col>
        <Col>
          <InputGroupInline formProps={{ ...formProps, validation: { required: true, maxLength: 150 } }} id="logradouro" label="Logradouro" />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGroupInline formProps={{ ...formProps, validation: { ...onlyAlphaNum(0, 10) } }} id="numero" label="Número" />
        </Col>
        <Col>
          <InputGroupInline formProps={{ ...formProps, validation: { maxLength: 60 } }} id="complemento" label="Complemento" />
        </Col>
      </Row>
      <Box title="Tipo de Endereço" className="mb-3">
        <FormGroup tag={Row}>
          <Col>
            <Checkbox formProps={formProps} id="tipoEnderecoPrincipal" label="Principal" disabled={isDisableEndPrincipal} />
          </Col>
          <Col>
            <Checkbox formProps={formProps} label="Residencial" id="tipoEnderecoResidencial" />
          </Col>
          <Col>
            <Checkbox formProps={formProps} label="Cobrança" id="tipoEnderecoCobranca" />
          </Col>
          <Col>
            <Checkbox formProps={formProps} label="Comercial" id="tipoEnderecoComercial" />
          </Col>
        </FormGroup>
      </Box>
    </DetailEditForm>
  );
};

export default EmpresaEnderecoDetailEditView;
