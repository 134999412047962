import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useState } from 'react';
import IFrame from 'react-frame-component';
import { useForm } from 'react-hook-form';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Checkbox, dec, ERROR, showToast, SuspenseLoading } from 'summer';
import { Notificacao } from '../../../../models/dtos/ger/notificacao';
import {
  cancelarRecebimentoMensagemController,
  marcarNotificacaoVista,
  notificarVisualizacaoMensagemController,
} from '../../../../services/ger.service';
import { Reducers } from '../../../../store/ducks';
import { GlobalState, setTaExibindoMensageria } from '../../../../store/ducks/global.duck';

import './MensageriaView.css';

interface MensageriaProps {
  notifications?: Notificacao[];
}

enum ViewStates {
  AMBOS,
  AVANCAR,
  RETORNAR,
  PADRAO,
}

let podeAvancar = false;
let podeRetornar = false;
let indexAtual = 0;
let notifications = [];

const MensageriaView: FC<MensageriaProps> = props => {
  const {
    globalParameter: { acesso, usuario },
    taExibindoMensageria,
    taExibindoPropaganda,
    taExibindoRetrospectiva,
  } = useSelector<Reducers, GlobalState>(state => state.globalReducer);
  const [currentState, setCurrentState] = useState(ViewStates.PADRAO);
  const [title, setTitle] = useState('');
  const [statusExibicao, setStatusExibicao] = useState('');
  const [htmlContent, setHtmlContent] = useState(null);
  const dispatch = useDispatch();
  const disablePreviousButton = currentState !== ViewStates.AMBOS && currentState !== ViewStates.RETORNAR;
  const disableNextButton = currentState !== ViewStates.AMBOS && currentState !== ViewStates.AVANCAR;
  const formProps = useForm<{ doNotSeeNotification: boolean }>({ defaultValues: { doNotSeeNotification: false } });

  useEffect(
    () => {
      notifications = props.notifications;
      ajustaLimites();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.notifications]
  );

  useEffect(
    () => {
      if (!taExibindoPropaganda && !taExibindoRetrospectiva && notifications.length > 0) {
        dispatch(setTaExibindoMensageria(true));
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [taExibindoPropaganda, taExibindoRetrospectiva]
  );

  const ajustaLimites = () => {
    podeAvancar = notifications.length - 1 > indexAtual;
    podeRetornar = indexAtual > 0;

    if (podeAvancar) {
      if (podeRetornar) {
        setCurrentState(ViewStates.AMBOS);
      } else {
        setCurrentState(ViewStates.AVANCAR);
      }
    } else if (podeRetornar) {
      setCurrentState(ViewStates.RETORNAR);
    } else {
      setCurrentState(ViewStates.PADRAO);
    }

    const notificacao: Notificacao = getNotificacao();
    if (notificacao) {
      setTitle(notificacao.mensagem);
      setStatusExibicao(`Mensagem ${indexAtual + 1} de ${notifications.length}`);
      setHtmlContent(notificacao.param);
      formProps.setValue('doNotSeeNotification', notificacao.visto);
      if (acesso && usuario) {
        notificarVisualizacaoMensagemController(
          {
            codigoCliente: acesso.codigoCliente,
            idMensagem: notificacao.codigo,
            usuario: dec(usuario.usuario),
          },
          {
            errorFunction: mensagem => {
              showToast(mensagem, ERROR);
            },
            thenFunction: () => undefined,
          }
        );
      }
    }
  };

  const getNotificacao = () => {
    let result: Notificacao = null;
    if (notifications.length > indexAtual) {
      result = notifications[indexAtual];
    }
    return result;
  };

  const avancar = () => {
    if (podeAvancar) {
      indexAtual++;
      ajustaLimites();
    }
  };

  const retornar = () => {
    if (podeRetornar) {
      indexAtual--;
      ajustaLimites();
    }
  };

  const marcarComoVisto = () => {
    const notificacao: Notificacao = getNotificacao();
    if (notificacao) {
      notificacao.visto = !notificacao.visto;
      marcarNotificacaoVista(notificacao, {
        errorFunction: mensagem => {
          showToast(mensagem, ERROR);
        },
        thenFunction: () => undefined,
      });
      if (acesso && usuario) {
        cancelarRecebimentoMensagemController(
          {
            codigoCliente: acesso.codigoCliente,
            idMensagem: notificacao.codigo,
            usuario: dec(usuario.usuario),
          },
          {
            errorFunction: mensagem => {
              showToast(mensagem, ERROR);
            },
            thenFunction: () => undefined,
          }
        );
      }
    }
  };

  const hideScreen = () => {
    dispatch(setTaExibindoMensageria(false));
  };

  return (
    <Modal className="modal-mensageria" centered={true} backdrop="static" isOpen={taExibindoMensageria} toggle={hideScreen}>
      <ModalHeader toggle={hideScreen}>{title}</ModalHeader>
      <ModalBody>
        {htmlContent ? (
          <>
            <Row>
              <Col>
                <IFrame>{ReactHtmlParser(htmlContent, {})}</IFrame>
              </Col>
            </Row>
            <Row className="row-checkbox">
              <Col>
                <Checkbox
                  formProps={formProps}
                  label="Não visualizar novamente essa notificação. :("
                  id="doNotSeeNotification"
                  tooltipProps={{ message: 'Marque essa opção para não visualizar novamente essa notificação. :(' }}
                  onChange={marcarComoVisto}
                />
              </Col>
            </Row>
          </>
        ) : (
          <SuspenseLoading message="Carregando..." />
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button
          size="sm"
          variant="secondary"
          onClick={retornar}
          disabled={disablePreviousButton}
          style={disablePreviousButton ? { cursor: 'not-allowed' } : null}
        >
          <FontAwesomeIcon icon="arrow-left" /> Anterior
        </Button>

        {statusExibicao ? <span className="justify-content-center">{statusExibicao}</span> : null}

        <Button
          size="sm"
          variant="secondary"
          onClick={avancar}
          disabled={disableNextButton}
          style={disableNextButton ? { cursor: 'not-allowed' } : null}
        >
          Próximo <FontAwesomeIcon icon="arrow-right" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MensageriaView;
