import { createLoadable } from 'summer';

const siteRoutes = [
  {
    component: createLoadable(() => import('./dashboard/DashboardSite'), 'DashboardSite'),
    path: '/site/dashboard',
  },
];

export default siteRoutes;
