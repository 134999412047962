import React, { FC } from 'react';
import { Alert, Col, Row } from 'reactstrap';
import iconeCertificado from '../../../../assets/certificado.png';

interface AlertCertificadoProps {
  className?: string;
}

const AlertCertificado: FC<AlertCertificadoProps> = ({ className }) => (
  <Row>
    <Col>
      <Alert color="primary" className={`text-center ${className}`}>
        <div>
          O sistema <span className="text-danger font-weight-bold">não tem suporte</span> ao tipo de{' '}
          <span className="text-danger font-weight-bold">certificado A3</span>.
        </div>
        <div>
          Adquira um certificado A1 com um preço diferenciado para nossos clientes{' '}
          <a
            className="text-decoration-underline text-first font-weight-bold"
            href="https://www.calimaerp.com/certificado_digital"
            target="_blank"
            rel="noopener noreferrer"
          >
            clicando aqui.
            <img src={iconeCertificado} width="17" alt="certificado" style={{ marginTop: -7 }} />
          </a>
        </div>
      </Alert>
    </Col>
  </Row>
);

export default AlertCertificado;
