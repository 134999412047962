import { combineReducers, Reducer } from 'redux';
import {
  blockAccessReducer,
  blockAccessState,
  dockReducer,
  DockState,
  editFormReducer,
  EditFormState,
  progressReducer,
  ProgressState,
  proReducer,
  ProState,
  userReducer,
  UserState,
} from 'summer';
import { GlobalState, reducer as globalReducer } from './global.duck';
import { GrupoRelatorioState, reducer as grupoRelatorioReducer } from './grupoRelatorio.duck';
import { HistAcessoState, reducer as histAcessoReducer } from './histAcesso.duck';
import { JobState, reducer as jobReducer } from './jobs.duck';
import { MultiPageState, reducer as multiPageReducer } from './multipage.duck';
import { NotificationState, reducer as notificationReducer } from './notifications.duck';

export { actions as multiPageActions } from './multipage.duck';
export { actions as histAcessoActions } from './histAcesso.duck';
export { actions as grupoRelatorioActions } from './grupoRelatorio.duck';

export interface Reducers {
  dockReducer: DockState;
  globalReducer: GlobalState;
  grupoRelatorioReducer: GrupoRelatorioState;
  histAcessoReducer: HistAcessoState;
  jobReducer: JobState;
  multiPageReducer: MultiPageState;
  notificationReducer: NotificationState;
  progressReducer: ProgressState;
  proReducer: ProState;
  blockAccessReducer: blockAccessState;
  userReducer: UserState;
  editFormReducer: EditFormState;
}

export const reducers: Reducer<Reducers> = combineReducers({
  blockAccessReducer,
  dockReducer,
  editFormReducer,
  globalReducer,
  grupoRelatorioReducer,
  histAcessoReducer,
  jobReducer,
  multiPageReducer,
  notificationReducer,
  proReducer,
  progressReducer,
  userReducer,
});
