import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { MoonLoader } from 'react-spinners';
import { Button, Modal, ModalBody } from 'reactstrap';
import { isEmpty } from 'summer';
import { Reducers } from '../../../store/ducks';
import { GlobalState } from '../../../store/ducks/global.duck';

import './SidebarVersionModal.css';

interface SidebarVersionModalProps {
  onClose: () => void;
  type: 'EM_BREVE' | 'HISTORICO';
}

const SidebarVersionModal: FC<SidebarVersionModalProps> = ({ onClose, type }) => {
  const { globalParameter, systemVersion } = useSelector<Reducers, GlobalState>(state => state.globalReducer);
  const MODAL_TYPES = {
    EM_BREVE: {
      pageUrl: globalParameter.urlEmBreveVersao,
      textExternalLink: 'Veja as últimas versões',
      title: 'Em breve!',
    },
    HISTORICO: {
      pageUrl: globalParameter.urlHistoricoVersao,
      textExternalLink: 'Veja o histórico completo',
      title: 'Histórico de versões',
    },
  };
  const { pageUrl, textExternalLink, title } = MODAL_TYPES[type];
  const [frameLoaded, setFrameLoaded] = useState(isEmpty(pageUrl));

  const onLoad = () => {
    setFrameLoaded(true);
  };

  const recarregar = () => {
    window.location.reload();
  };

  return (
    <Modal className="modal-sidebar-version" size="xl" centered={true} isOpen={true} toggle={onClose}>
      <ModalBody>
        <button className="close close-modal" aria-label="Close" onClick={onClose}>
          <span aria-hidden="true">×</span>
        </button>
        <div className="container-header mt-3">
          <div className="d-flex align-items-center justify-content-between">
            <div className="title">{title}</div>
            <div className="d-flex flex-column container-versao">
              {type === 'HISTORICO' && (
                <h6 className="font-weight-bold mb-0">
                  Versão atual <span className="info">{systemVersion.split('-')[0]}</span>
                </h6>
              )}
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="external-link"
                href="https://ajuda.calimaerp.com/pt/category/novidades-do-calima-5-v0v3um/"
              >
                {textExternalLink} <FontAwesomeIcon icon="clock" className="ml-1" />
              </a>
            </div>
          </div>
          <div className="divider my-2" />
        </div>
        <div className="container-frame">
          {!frameLoaded && (
            <div className="container-loader">
              <MoonLoader color="#2C87F2" loading={true} size={130} />
            </div>
          )}
          {pageUrl ? (
            <iframe
              width="100%"
              height="100%"
              title={title}
              onLoad={onLoad}
              frameBorder="0"
              id="frame-version"
              {...(!frameLoaded && { style: { display: 'none' } })}
              src={`${pageUrl}/reader`}
            />
          ) : (
            <>
              <div className="container-error">×</div>
              <h5 className="mb-3 font-weight-bold">Não conseguimos encontrar essa página!</h5>
              <h5 className="mb-0">Estamos trabalhando para solucionar esse problema o mais rápido possível.</h5>
              <h5>Por favor, recarregue a página para tentarmos novamente ou retorne mais tarde.</h5>
              <div className="d-flex mt-5">
                <Button color="primary" size="sm" onClick={recarregar}>
                  Recarregar
                </Button>
                <Button color="neutral-second" size="sm" className="ml-4" onClick={onClose}>
                  Fechar
                </Button>
              </div>
            </>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SidebarVersionModal;
