import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useState } from 'react';
import {
  Badge,
  Button,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Modal,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from 'reactstrap';
import { ProcessBar, useInputSearchClipboard, WikiButton } from 'summer';

const ClipboardManager: FC = () => {
  const clipboard = useInputSearchClipboard();

  const [show, setShow] = useState(false);
  const [list, setList] = useState<any>(clipboard.getAll());

  useEffect(
    () => {
      clipboard.listen(onClipboardChange);
      return () => clipboard.removeListener(onClipboardChange);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onClipboardChange = store => setList(store);

  const toggle = () => setShow(false);
  const showClickHandle = () => setShow(true);

  const label = item => `${item.value} - ${item.label}`;
  const getContent = item =>
    Array.isArray(item)
      ? item.map((it, index) => (
          <Badge key={index} color="dark" className="m-1" style={{ whiteSpace: 'break-spaces', height: 'auto', textAlign: 'left' }}>
            {label(it)}
          </Badge>
        ))
      : label(item);

  const excluir = dtoClassName => e => {
    e.preventDefault();
    clipboard.remove(dtoClassName);
  };

  const keys = Object.keys(list);

  return (
    <>
      <Button
        className="btn-transition-none px-2 mx-2"
        size="sm"
        id="areaTransferencia"
        color="link"
        style={{
          fontSize: 19,
          verticalAlign: 'top',
        }}
        onClick={showClickHandle}
      >
        <FontAwesomeIcon icon="clipboard-list" />
      </Button>
      <UncontrolledTooltip target="areaTransferencia" container=".app-sidebar--footer">
        Área de Transferência
      </UncontrolledTooltip>
      <Modal isOpen={show} toggle={toggle} centered={true}>
        <div className="modal-header">
          <h6 className="modal-title w-100 justify-content-between">
            Área de Transferência
            <div className="float-right">
              <WikiButton pageId={10790} small={true} />
            </div>
          </h6>
          <button className="close" aria-label="Close" onClick={toggle}>
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <ModalBody>
          <ListGroup>
            {keys.length ? (
              keys.map((it, index) => (
                <ListGroupItem className="pr-2 pl-3" key={index}>
                  <ListGroupItemHeading style={{ fontSize: '1rem' }}>
                    {list[it].date} - {list[it].label}
                    <Button
                      className="excluir-item-area-transferencia btn-transition-none p-0 float-right"
                      size="sm"
                      color="link"
                      style={{ marginTop: -6, color: '#f83245', fontSize: '1rem' }}
                      onClick={excluir(list[it].dtoClassName)}
                    >
                      <FontAwesomeIcon icon={['fas', 'times-circle']} />
                    </Button>
                  </ListGroupItemHeading>
                  <ListGroupItemText className="mb-0">{getContent(list[it].value)}</ListGroupItemText>
                </ListGroupItem>
              ))
            ) : (
              <p className="text-center m-0">Nenhum registro à exibir.</p>
            )}
          </ListGroup>
          {keys.length > 0 && <UncontrolledTooltip target=".excluir-item-area-transferencia">Excluir este item.</UncontrolledTooltip>}
        </ModalBody>
        <ModalFooter>
          <ProcessBar hideSubmitButton={true} btnCloseLabel="Fechar" handleClose={toggle} />
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ClipboardManager;
