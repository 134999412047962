import React, { createContext, FC, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ERROR, eventManager, EventType, showToast } from 'summer';
import { CLIQUE, FAV } from '../models/enumerated/ger/usuarioHistAcessoTipoEnum';
import { atualizarAcessoFavorito, buscarHistoricoAcesso } from '../services/ger.service';
import { histAcessoActions, Reducers } from '../store/ducks';

interface FavoritosRecentesHook {
  update: () => void;
}

const FavoritosRecentesContext = createContext<FavoritosRecentesHook>(null);

export const useFavoritosRecentes = () => useContext(FavoritosRecentesContext);

export const FavoritosRecentesProvider: FC = ({ children }) => {
  const {
    histAcessoReducer: { favs },
    globalReducer: { globalParameter },
  } = useSelector<Reducers, Reducers>(state => state);

  const dispatch = useDispatch();

  useEffect(
    () => {
      update();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      eventManager.on(EventType.FAV_CHECK, onFavCheck);
      eventManager.on(EventType.FAV_TOGGLE, onFavToggle);
      return () => {
        eventManager.off(EventType.FAV_CHECK, onFavCheck);
        eventManager.off(EventType.FAV_TOGGLE, onFavToggle);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [favs]
  );

  const update = () =>
    buscarHistoricoAcesso(globalParameter.usuario.id, {
      errorFunction: message => showToast(message, ERROR),
      thenFunction: res => {
        dispatch(histAcessoActions.setFavs(res.filter(it => it.tipo === FAV.value)));
        dispatch(histAcessoActions.setRecents(res.filter(it => it.tipo === CLIQUE.value)));
      },
    });

  const onFavCheck = data => {
    eventManager.emit(
      EventType.FAV_RESULT,
      data.modulo,
      data.id,
      favs.find(it => it.modulo === data.modulo && it.itemMenu === data.id) !== undefined
    );
  };

  const onFavToggle = data => {
    atualizarAcessoFavorito(globalParameter.usuario.id, data.modulo, data.id, {
      errorFunction: message => showToast(message, ERROR),
      thenFunction: res => {
        const newFavs = res
          ? [...favs, { modulo: data.modulo, itemMenu: data.id }]
          : favs.filter(it => it.modulo !== data.modulo || it.itemMenu !== data.id);

        dispatch(histAcessoActions.setFavs(newFavs));

        eventManager.emit(EventType.FAV_RESULT, data.modulo, data.id, res);
      },
    });
  };

  return <FavoritosRecentesContext.Provider value={{ update }}>{children}</FavoritosRecentesContext.Provider>;
};
