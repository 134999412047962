import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import {
  AutoCompleteOff,
  DetailEditForm,
  DetailEditViewFormProps,
  DetailManagerForm,
  InputGroupInline,
  InputType,
  isEmpty,
  parseDateToRef,
  refToFormat,
  Tab,
  TabGroup,
  toDate,
} from 'summer';
import { ContratoProcessoTrabalhista } from '../../../../models/dtos/mfp/contratoProcessoTrabalhista';
import {
  DECISAO_COM_PAGAMENTO,
  DECISAO_SEM_PAGAMENTO,
  REPERCUSSAO_PROCESSO_ENUM_VALUES,
} from '../../../../models/enumerated/mfp/repercussaoProcessoEnum';
import { NAO } from '../../../../models/enumerated/mfp/simNaoEnum';
import {
  EMPREGADO_RECONHECIMENTO_VINCULO,
  RESPONSABILIDADE_INDIRETA,
  TIPO_CONTRATO_PROCESSO_JUDICIAL_ENUM_VALUES,
  TRABALHADOR_COM_VINCULO_EMPREGO_ANTERIOR_ESOCIAL,
  TRABALHADOR_CONTRATOS_UNIFICADOS,
  TRABALHADOR_FORMALIZADO_COM_ALTERACAO_ADMISSAO,
  TRABALHADOR_FORMALIZADO_COM_ALTERACAO_ADMISSAO_DESLIGAMENTO,
  TRABALHADOR_FORMALIZADO_COM_INCLUSAO_ALTERACAO_DESLIGAMENTO,
  TRABALHADOR_FORMALIZADO_SEM_ALTERACAO_ADMISSAO_DESLIGAMENTO,
} from '../../../../models/enumerated/mfp/tipoContratoProcessoJudicialEnum';
import {
  abonosDetailProps,
  baseCalculoDetailProps,
  CardSimNao,
  ERRO_CAMPO_MSG,
  mudancaCategoriaDetailProps,
  unicidadeContratualDetailProps,
  VinculoField,
} from './processoTrabalhista.util';

const ProcessoTrabalhistaContratoDetailView: FC<DetailEditViewFormProps<ContratoProcessoTrabalhista>> = props => {
  const formProps = useForm<ContratoProcessoTrabalhista>({
    defaultValues: {
      indicativoCategoria: NAO.value,
      indicativoDesligamento: NAO.value,
      indicativoNatureza: NAO.value,
      ...props.selectedItem,
    },
    mode: 'onBlur',
  });

  const repercussaoProcesso = formProps.watch('repercussaoProcesso');

  useEffect(
    () => {
      formProps.register({ name: 'vinculo' });
      formProps.setValue('vinculo', props.parentFormProps.getValues().vinculo);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const validateExtras = (formData: ContratoProcessoTrabalhista & VinculoField, _, parentObject) => {
    const erros = [];
    const { basesCalculo, dataAdmissaoAnteriorVinculo, referenciaFinal, referenciaInicial, tipo, unicidadesContratuais, vinculo } = formData;
    const { dataSentenca, dataConciliacao } = parentObject;

    const refDataAdmissao = parseDateToRef(toDate(vinculo.dataAdmissao));
    switch (tipo) {
      case TRABALHADOR_FORMALIZADO_SEM_ALTERACAO_ADMISSAO_DESLIGAMENTO.value:
      case TRABALHADOR_FORMALIZADO_COM_INCLUSAO_ALTERACAO_DESLIGAMENTO.value:
      case TRABALHADOR_COM_VINCULO_EMPREGO_ANTERIOR_ESOCIAL.value:
      case RESPONSABILIDADE_INDIRETA.value:
      case TRABALHADOR_CONTRATOS_UNIFICADOS.value:
        if (referenciaInicial < refDataAdmissao) {
          erros.push(`Referência inicial deve ser igual ou posterior ao mês/ano da admisão do vínculo (${refToFormat(refDataAdmissao)}).`);
        }
        break;
      case TRABALHADOR_FORMALIZADO_COM_ALTERACAO_ADMISSAO.value:
      case TRABALHADOR_FORMALIZADO_COM_ALTERACAO_ADMISSAO_DESLIGAMENTO.value:
      case EMPREGADO_RECONHECIMENTO_VINCULO.value:
        if (referenciaInicial !== refDataAdmissao) {
          erros.push(`Referência inicial deve ser igual ao mês/ano de admisão do vínculo (${refToFormat(refDataAdmissao)}).`);
        }

        if (isEmpty(dataAdmissaoAnteriorVinculo)) {
          erros.push(`Data de admissão do vínculo antes da alteração ${ERRO_CAMPO_MSG}`);
        }
        break;
      default:
        break;
    }

    if (dataSentenca && dataConciliacao) {
      const refDataSentenca = parseDateToRef(toDate(dataSentenca));
      const refDataConciliacao = parseDateToRef(toDate(dataConciliacao));
      if (referenciaFinal < referenciaInicial) {
        erros.push('Referência final deve ser igual ou posterior à referência inicial');
      } else if (refDataSentenca > referenciaFinal || refDataConciliacao > referenciaFinal) {
        if (refDataSentenca > referenciaFinal) {
          erros.push(`Referência final deve ser igual ou anterior ao mês/ano da data da sentença (${refToFormat(refDataSentenca)}).`);
        } else {
          erros.push(`Referência final deve ser igual ou anterior ao mês/ano da data da conciliação (${refToFormat(refDataConciliacao)}).`);
        }
      }
    }

    if (basesCalculo) {
      const referenciasInvalidas = basesCalculo.filter(it => it.referencia > referenciaFinal || it.referencia < referenciaInicial);
      if (referenciasInvalidas.length > 0) {
        erros.push(
          `Existe(m) ${referenciasInvalidas.length} base(s) de cálculo com a referência fora do período (${refToFormat(
            referenciaInicial
          )} até ${refToFormat(referenciaFinal)}).`
        );
      }
    }

    if ((!basesCalculo || basesCalculo.length === 0) && repercussaoProcesso === DECISAO_COM_PAGAMENTO.value) {
      erros.push('Pelo menos uma base de cálculo deve ser preenchida.');
    }

    if (tipo === TRABALHADOR_CONTRATOS_UNIFICADOS.value && (!unicidadesContratuais || unicidadesContratuais.length === 0)) {
      erros.push('Pelo menos uma unicidade contratual deve ser preenchida.');
    }

    return erros;
  };

  const changeRepercussaoProcesso = value => {
    if (value === DECISAO_SEM_PAGAMENTO.value) {
      formProps.setValue('vrVerbasRemuneratorias', 0);
      formProps.triggerValidation('vrVerbasRemuneratorias');
    }
  };

  return (
    <DetailEditForm handleSubmit={props.handleSubmit} handleCancel={props.handleCancel} formProps={formProps} validateExtras={validateExtras}>
      <TabGroup formProps={formProps}>
        <Tab title="Informações gerais">
          <Row>
            <Col>
              <InputGroupInline
                id="tipo"
                label="Tipo de contrato a que se refere o processo"
                formProps={{ ...formProps, validation: { required: true } }}
                inputDropDownProps={{ optionsProvider: TIPO_CONTRATO_PROCESSO_JUDICIAL_ENUM_VALUES }}
                type={InputType.DROP_DOWN}
                tooltipProps={{ message: 'Tipo de contrato a que se refere o processo judicial ou a demanda submetida à CCP ou ao NINTER' }}
              />
            </Col>
            <Col className="col-auto">
              <InputGroupInline
                formProps={formProps}
                id="dataAdmissaoAnteriorVinculo"
                label="Data de admissão do vínculo antes da alteração"
                type={InputType.DATE}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroupInline
                id="observacao"
                label="Observação"
                formProps={{ ...formProps, validation: { maxLength: 255 } }}
                type={InputType.TEXT_AREA}
              />
            </Col>
          </Row>
          <CardSimNao
            label="Houve reconhecimento de categoria do trabalhador diferente da informada (no eSocial ou na GFIP) pelo declarante?"
            id="indicativoCategoria"
            formProps={formProps}
            required={true}
          />
          <CardSimNao
            label="Houve reconhecimento de motivo de desligamento diferente do informado pelo declarante?"
            id="indicativoDesligamento"
            formProps={formProps}
            required={true}
          />
          <CardSimNao
            label="Houve reconhecimento de natureza da atividade diferente da cadastrada pelo declarante?"
            id="indicativoNatureza"
            formProps={formProps}
            required={true}
          />
          <CardSimNao label="Houve decisão para pagamento da indenização substitutiva do seguro-desemprego?" id="indenSD" formProps={formProps} />
          <CardSimNao label="Houve decisão para pagamento da indenização substitutiva de abono salarial?" id="indenAbono" formProps={formProps} />
        </Tab>
        <Tab title="Informações do vínculo">
          <Row>
            <Col>
              <DetailManagerForm {...props} {...mudancaCategoriaDetailProps(formProps)} />
            </Col>
          </Row>
          <Row>
            <Col>
              <DetailManagerForm {...props} {...unicidadeContratualDetailProps(formProps)} />
            </Col>
          </Row>
        </Tab>
        <Tab title="Informações dos períodos e valores do processo">
          <AutoCompleteOff>
            <Row>
              <Col>
                <InputGroupInline
                  formProps={{ ...formProps, validation: { required: true } }}
                  id="referenciaInicial"
                  label="Referência inicial"
                  type={InputType.REFERENCE}
                />
              </Col>
              <Col>
                <InputGroupInline
                  formProps={{ ...formProps, validation: { required: true } }}
                  id="referenciaFinal"
                  label="Referência final"
                  type={InputType.REFERENCE}
                />
              </Col>
              <Col>
                <InputGroupInline
                  id="repercussaoProcesso"
                  label="Indicativo de repercussão do processo"
                  formProps={{ ...formProps, validation: { required: true } }}
                  inputDropDownProps={{ optionsProvider: REPERCUSSAO_PROCESSO_ENUM_VALUES }}
                  type={InputType.DROP_DOWN}
                  tooltipProps={{ message: 'Repercussão do processo trabalhista ou de demanda submetida à CCP ou ao NINTER' }}
                  onChange={changeRepercussaoProcesso}
                />
              </Col>
            </Row>
          </AutoCompleteOff>
          <Row>
            <Col>
              <DetailManagerForm {...props} {...baseCalculoDetailProps(formProps)} />
            </Col>
          </Row>
          <Row>
            <Col>
              <DetailManagerForm {...props} {...abonosDetailProps(formProps)} />
            </Col>
          </Row>
        </Tab>
      </TabGroup>
    </DetailEditForm>
  );
};

export default ProcessoTrabalhistaContratoDetailView;
