export const SEM_BLOQUEIOS = { label: 'Sem bloqueios', value: 'SEM_BLOQUEIOS' };
export const BLOQUEAR_COM_SENHA = { label: 'Bloquear com senha', value: 'BLOQUEAR_COM_SENHA' };
export const BLOQUEAR_TOTALMENTE = { label: 'Bloquear totalmente', value: 'BLOQUEAR_TOTALMENTE' };

export const TIPO_BLOQUEIO_LANCAMENTO_ENUM_VALUES = [SEM_BLOQUEIOS, BLOQUEAR_COM_SENHA, BLOQUEAR_TOTALMENTE];

export const FORA_PERIODO_FISCAL = { label: 'Fora do período fiscal', value: 'FORA_PERIODO_FISCAL' };
export const FORA_MES_ATUAL = { label: 'Fora da referência atual', value: 'FORA_MES_ATUAL' };
export const PERIODO_ANTERIOR = { label: 'Anterior à Referência atual', value: 'PERIODO_ANTERIOR' };

export const PERIODO_BLOQUEIO_LANCAMENTO_ENUM_VALUES = [FORA_PERIODO_FISCAL, FORA_MES_ATUAL, PERIODO_ANTERIOR];
