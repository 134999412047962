import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  alertManager,
  AlertType,
  cepFormatter,
  ColumnsProps,
  DefaultSortProps,
  enumColumn,
  isNotEmpty,
  OrderType,
  phoneMaskFormatter,
  phoneWithDDDFormatter,
  progressActions,
  referenceColumn,
  withTooltip,
} from 'summer';
import { Empresa } from '../models/dtos/ger/empresa';
import { Endereco } from '../models/dtos/ger/endereco';
import { Telefone } from '../models/dtos/ger/telefone';
import {
  LUCRO_PRESUMIDO,
  SIMPLES_NACIONAL,
  TIPO_TRIBUTACAO_ENUM_VALUES,
  TRIBUTACAO_LUCRO_PRESUMIDO_ENUM_VALUES,
} from '../models/enumerated/ger/tipoTributacaoEnum';
import EnumUtil from './enum.util';
import { tipoEnderecoFormatter } from './tipoEnderecoFormatter';
import { tipoTelefoneFormatter } from './tipoTelefoneFormatter';

export const showLoading = () =>
  progressActions.showProgress({
    animated: true,
    className: 'progress-bar-rounded progress-animated-alt',
    color: 'first',
    label: 'Aguarde processando...',
    striped: true,
    value: 100,
  });

export const closeLoading = () => progressActions.hideProgress();

export const toolTip = (messagem: string, name: any) => {
  return withTooltip({ message: messagem, placement: 'top-start' }, name);
};

export const promisedAlert = (message, type = AlertType.WARNING_YES_NO) =>
  new Promise(resolve => {
    promisedAlertWithCatch(message, type)
      .then(resolve)
      .catch(() => null);
  });

export const promisedAlertWithCatch = (message, type = AlertType.WARNING_YES_NO) =>
  new Promise((resolve, reject) => {
    alertManager.emit({
      message,
      onCancelClick: () => reject(),
      onNoClick: () => reject(),
      onOkClick: () => resolve(),
      type,
    });
  });

export const enderecoColumns: ColumnsProps[] = [
  {
    dataField: 'logradouro',
    sort: true,
    text: 'Logradouro',
  },
  {
    dataField: 'numero',
    sort: true,
    text: 'Número',
  },
  {
    dataField: 'cep',
    formatter: cepFormatter,
    sort: true,
    text: 'CEP',
  },
  {
    dataField: 'bairro.bairro',
    sort: true,
    text: 'Bairro',
  },
  {
    dataField: 'localidade.nome',
    text: 'Cidade',
  },
  {
    dataField: 'tipos',
    formatter: tipoEnderecoFormatter,
    text: 'Tipo(s) de endereço',
  },
];

export const telefoneColumns: ColumnsProps[] = [
  {
    dataField: 'contato',
    sort: true,
    text: 'Contato',
  },
  {
    dataField: 'numero',
    formatter: phoneWithDDDFormatter,
    text: 'Telefone',
  },
  {
    dataField: 'tipos',
    formatter: tipoTelefoneFormatter,
    text: 'Tipos',
  },
];

export const emailColumns: ColumnsProps[] = [
  {
    dataField: 'contato',
    sort: true,
    text: 'Contato',
  },
  {
    dataField: 'email',
    text: 'E-mail',
  },
];

export const tributacoesDefaultSortProps: DefaultSortProps[] = [
  {
    dataField: 'refInicioVigencia',
    order: OrderType.DESC,
  },
];

export const tributacoesFormColumns: ColumnsProps[] = [
  enumColumn(
    {
      dataField: 'tipoTributacao',
      sort: true,
      text: 'Tipo de tributação',
    },
    TIPO_TRIBUTACAO_ENUM_VALUES
  ),
  referenceColumn({
    dataField: 'refInicioVigencia',
    sort: true,
    text: 'Referência no início',
  }),
  referenceColumn({
    dataField: 'refFimVigencia',
    sort: true,
    text: 'Referência no fim',
  }),
  {
    dataField: '',
    formatter: (cell, row) => {
      let status = null;
      if (row.refFimVigencia == null) {
        status = (
          <strong>
            Tributação vigente
            <FontAwesomeIcon icon="check" className="ml-1 text-primary" />
          </strong>
        );
      }
      return status;
    },
    text: 'Status',
  },
];

export const MSG_CENTRALIZAR = `Ao marcar Centralizar a Matriz irá possuir Filiais que não possuem o mesmo Grupo de Plano de Conta.\n
                               Dessa forma não será possível fazer a integração do Fiscal com o contábil até ocorrer a mudança do plano de contas\n
                               das filiais. Se desejar fazer essa correção vá nas empresas filiais em: Módulo Principal > Manutenção > Configurações
                                > Grupo de Empresas > Grupo de Plano de Contas.`;

export const MSG_MESMO_PLANO_CONTA = `A Empresa Atual precisa ter o mesmo Grupo de Plano de Contas da Empresa Matriz, \n
                                      pois a matriz possui configuração de Centralização Contábil.
                                      Configure corretamente em Módulo Principal > Manutenção > Configurações > Grupo de Empresas
                                     > Grupo de Plano de Contas.`;

export const isLucroPresumido = (tipoTributacao: string): boolean => EnumUtil.contains(TRIBUTACAO_LUCRO_PRESUMIDO_ENUM_VALUES, tipoTributacao);

export const isSimplesNacional = (tipoTributacao: string): boolean => tipoTributacao === SIMPLES_NACIONAL.value;

export const getEnderecoPrincipal = (enderecos: Endereco[]): Endereco => enderecos.find(endereco => endereco.tipoEnderecoPrincipal);

export const getTelefonePrincipal = (telefones: Telefone[]): Telefone => telefones.find(telefone => telefone.tipoTelefonePrincipal);

export const logradouroFmt = (endereco: Endereco): string => {
  let result = '';
  if (endereco.tipoLogradouro) {
    result = `${endereco.tipoLogradouro.nome} ${endereco.logradouro}`;
  } else {
    if (endereco.logradouro) {
      result = endereco.logradouro;
    }
  }
  return result;
};

export const localidadeFmt = (endereco: Endereco): string => {
  let result = '';
  if (endereco.localidade) {
    result = endereco.localidade.nome;
    if (endereco.localidade.uf) {
      result += ' / ' + endereco.localidade.uf.sigla;
    }
  }
  return result;
};

export const enderecoFormatado = (enderecos: Endereco[]): string => {
  let enderecoFmt = '';
  const endereco = getEnderecoPrincipal(enderecos);
  if (endereco) {
    enderecoFmt = `${logradouroFmt(endereco)} - ${endereco.numero} - ${localidadeFmt(endereco)}`;
  }
  return enderecoFmt;
};

export const telefoneFormatado = (telefones: Telefone[]): string => {
  let telefoneFmt = '';
  const telefone = getTelefonePrincipal(telefones);
  if (telefone) {
    telefoneFmt = phoneMaskFormatter(telefone.numero);
  }
  return telefoneFmt;
};

export const podeUtilizarAliqPresuncao = (empresa: Empresa, tipoTributacao: string) =>
  isNotEmpty(empresa) && empresa.tipoEmpresaConstrutora && tipoTributacao === LUCRO_PRESUMIDO.value;
