import { MenuItem } from '../../../models/dtos/ger/menuItem';

const mprMenu: MenuItem[] = [
  {
    icon: 'pe-7s-home',
    label: 'Home',
    to: '/mpr/dashboard',
  },
  {
    content: [
      {
        label: 'Documento',
        to: '/mpr/documentoSearchView',
      },
      {
        label: 'Histórico',
        to: '/mpr/historicoMPRSearchView',
      },
    ],
    icon: 'pe-7s-edit',
    label: 'Manutenção',
  },
  {
    content: [
      {
        label: 'Lançamento',
        to: '/mpr/protocoloSearchView',
      },
      {
        label: 'Baixa',
        to: '/mpr/baixaProtocoloEditView',
      },
    ],
    icon: 'pe-7s-play',
    label: 'Processos',
  },
  {
    content: [
      {
        label: 'Relação de Protocolos Emitidos',
        to: '/mpr/ProtocoloEmitidosReportView',
      },
      {
        label: 'Relação de Guia de Protocolos',
        to: '/mpr/ProtocoloDocumentosReportView',
      },
    ],
    icon: 'pe-7s-print',
    isReportList: true,
    label: 'Relatórios',
  },
];

export default mprMenu;
