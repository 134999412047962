import { createLoadable } from 'summer';

const gerRoutes = [
  {
    component: createLoadable(() => import('./dashboard/Dashboard'), 'Dashboard'),
    path: '/ger/dashboard',
  },
  {
    component: createLoadable(() => import('./responsavel/ResponsavelEditView'), 'ResponsavelEditView'),
    path: '/ger/responsavelSearchView/edit',
  },
  {
    component: createLoadable(() => import('./responsavel/ResponsavelSearchView'), 'ResponsavelSearchView'),
    exact: true,
    path: '/ger/responsavelSearchView',
  },
  {
    component: createLoadable(() => import('./pais/CepPaisEditView'), 'CepPaisEditView'),
    path: '/ger/cepPaisSearchView/edit',
  },
  {
    component: createLoadable(() => import('./pais/CepPaisSearchView'), 'CepPaisSearchView'),
    exact: true,
    path: '/ger/cepPaisSearchView',
  },
  {
    component: createLoadable(() => import('./uf/CepUFEditView'), 'CepUFEditView'),
    path: '/ger/cepUFSearchView/edit',
  },
  {
    component: createLoadable(() => import('./uf/CepUFSearchView'), 'CepUFSearchView'),
    exact: true,
    path: '/ger/cepUFSearchView',
  },
  {
    component: createLoadable(() => import('./cidade/CepLocalidadeEditView'), 'CepLocalidadeEditView'),
    path: '/ger/cepLocalidadeSearchView/edit',
  },
  {
    component: createLoadable(() => import('./cidade/CepLocalidadeSearchView'), 'CepLocalidadeSearchView'),
    exact: true,
    path: '/ger/cepLocalidadeSearchView',
  },
  {
    component: createLoadable(() => import('./bairro/CepBairroEditView'), 'CepBairroEditView'),
    path: '/ger/cepBairroSearchView/edit',
  },
  {
    component: createLoadable(() => import('./bairro/CepBairroSearchView'), 'CepBairroSearchView'),
    exact: true,
    path: '/ger/cepBairroSearchView',
  },
  {
    component: createLoadable(() => import('./logradouro/CepLogradouroEditView'), 'CepLogradouroEditView'),
    path: '/ger/cepLogradouroSearchView/edit',
  },
  {
    component: createLoadable(() => import('./logradouro/CepLogradouroSearchView'), 'CepLogradouroSearchView'),
    exact: true,
    path: '/ger/cepLogradouroSearchView',
  },
  {
    component: createLoadable(() => import('./titulo/CepTituloSearchView'), 'CepTituloSearchView'),
    exact: true,
    path: '/ger/cepTituloSearchView',
  },
  {
    component: createLoadable(() => import('./usuario/UsuarioEditView'), 'UsuarioEditView'),
    path: '/ger/usuarioSearchView/edit',
  },
  {
    component: createLoadable(() => import('./usuario/UsuarioSearchView'), 'UsuarioSearchView'),
    exact: true,
    path: '/ger/usuarioSearchView',
  },
  {
    component: createLoadable(() => import('./configEmail/ConfigEmailEditView'), 'ConfigEmailEditView'),
    path: '/ger/configEmailEditView',
  },
  {
    component: createLoadable(() => import('./log/LogSearchView'), 'LogSearchView'),
    path: '/ger/logSearchView',
  },
  {
    component: createLoadable(() => import('./report/atividadesDiaTrabalho/AtividadesDiaTrabalhoReportView'), 'AtividadesDiaTrabalhoReportView'),
    path: '/ger/atividadesDiaTrabalhoReportView',
  },
  {
    component: createLoadable(() => import('./report/relacaoEmpresa/RelacaoEmpresaReportView'), 'RelacaoEmpresaReportView'),
    path: '/ger/relacaoEmpresaReportView',
  },
  {
    component: createLoadable(() => import('./report/relacaoGrupoEmpresa/RelacaoGrupoEmpresasReportView'), 'RelacaoGrupoEmpresasReportView'),
    path: '/ger/relacaoGrupoEmpresasReportView',
  },
  {
    component: createLoadable(() => import('./empresa/EmpresaEditView'), 'EmpresaEditView'),
    path: '/ger/empresaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./empresa/EmpresaSearchView'), 'EmpresaSearchView'),
    exact: true,
    path: '/ger/empresaSearchView',
  },
  {
    component: createLoadable(() => import('./administradora/AdministradoraEditView'), 'AdministradoraEditView'),
    path: '/ger/administradoraEditView',
  },
  {
    component: createLoadable(() => import('./grupoEmpresa/GrupoEmpresaEditView'), 'GrupoEmpresaEditView'),
    path: '/ger/grupoEmpresaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./grupoEmpresa/GrupoEmpresaSearchView'), 'GrupoEmpresaSearchView'),
    exact: true,
    path: '/ger/grupoEmpresaSearchView',
  },
  {
    component: createLoadable(() => import('./grupoEmpresaConfig/GrupoEmpresaConfigEditView'), 'GrupoEmpresaConfigEditView'),
    exact: true,
    path: '/ger/grupoEmpresaConfigEditView',
  },
  {
    component: createLoadable(() => import('./certificadoDigital/CertificadoDigitalView'), 'CertificadoDigitalView'),
    exact: true,
    path: '/ger/certificadoDigitalView',
  },
  {
    component: createLoadable(
      () => import('./report/controleAlvaraLocalizacao/ControleAlvaraLocalizacaoReportView'),
      'ControleAlvaraLocalizacaoReportView'
    ),
    path: '/ger/controleAlvaraLocalizacaoReportView',
  },
  {
    component: createLoadable(() => import('./ticket/TicketSearchView'), 'TicketSearchView'),
    exact: true,
    path: '/ger/ticketSearchView',
  },
  {
    component: createLoadable(() => import('./ticket/TicketSearchView'), 'TicketSearchView'),
    exact: true,
    path: '/ger/ticketSearchView/:numero',
  },
];

export default gerRoutes;
