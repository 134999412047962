const tiposTelefone = {
  tipoTelefoneCelular: 'Celular',
  tipoTelefoneComercial: 'Comercial',
  tipoTelefoneFax: 'Fax',
  tipoTelefonePrincipal: 'Principal',
  tipoTelefoneResidencial: 'Residencial',
};

export function tipoTelefoneSelection(tipoTelefone: any): string {
  const resultado = [];
  // tslint:disable-next-line:forin
  for (const campo in tiposTelefone) {
    // tslint:disable-next-line:no-unused-expression
    tipoTelefone[campo] && resultado.push(tiposTelefone[campo]);
  }
  return resultado.join(', ');
}

export function tipoTelefoneFormatter(cell, row) {
  return tipoTelefoneSelection(row);
}
