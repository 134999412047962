import { Basic } from 'summer';
import { Empresa } from '../ger/empresa';
import { Responsavel } from '../ger/responsavel';
import { TributacaoEmpresa } from '../ger/tributacaoEmpresa';
import { ConfigDipj } from './configDipj';
import { ConfigLancamentoConhecimentoTransporte } from './configLancamentoConhecimentoTransporte';
import { ConfigLancamentoEntradaSaida } from './configLancamentoEntradaSaida';
import { ConfigLancamentoReducaoZ } from './configLancamentoReducaoZ';
import { ContribuinteSubstituto } from './contribuinteSubstituto';
import { NumeroUltimaNotaEmitida } from './numeroUltimaNotaEmitida';
import { TipoProduto } from './tipoProduto';

export interface EmpresaMLF extends Basic {
  atividade?: number;
  ciapCoeficienteCreditamentoDecimais?: number;
  configDIPJ?: ConfigDipj;
  configLancamentoConhecimentoTransporte?: ConfigLancamentoConhecimentoTransporte;
  configLancamentoEntradaSaida?: ConfigLancamentoEntradaSaida;
  configLancamentoReducaoZ?: ConfigLancamentoReducaoZ;
  contribuinteSubstituto?: ContribuinteSubstituto[];
  empresa?: Empresa;
  gerarBlocoPSped?: boolean;
  gerarLancamentoPorTipoProduto?: boolean;
  idGerEmpresa?: number;
  inserirDataEmissao?: boolean;
  label?: string;
  listaNumeroUltimaNotaEmitida?: NumeroUltimaNotaEmitida[];
  /** Este campo utiliza o indNaturezaPessoaJuridicaSpedPisCofinsEnum */
  naturezaPessoaJuridicaSped?: string;
  referenciaAtual?: string;
  responsavel?: Responsavel;
  retencaoFontePS?: boolean;
  tipoCooperativa?: string;
  /** Este campo utiliza o tipoInstituicaoFinanceiraEnum */
  tipoInstituicaoFinanceira?: string;
  tipoOperacaoConcessionaria?: number;
  validaFechamentoValoresEntradaSaida?: boolean;
  tipoProduto?: TipoProduto;
  tributacaoAtualMLF?: TributacaoEmpresa;
}

export const CLASS_NAME = 'br.com.projetusinformatica.calima.mlf.model.dto.empresamlf';
