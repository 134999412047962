import { compare, formatDate, parseLastDay, parseRefToDate } from 'summer';
import { GlobalParameter } from '../models/dtos/ger/globalParameter';
import { FORA_MES_ATUAL, FORA_PERIODO_FISCAL, PERIODO_ANTERIOR } from '../models/enumerated/ger/permissoesLancamentosUsuarioEnum';

export const atualizarEmpresaGlobalParameter = (globalParameter, dadosEmpresaGlobalParameter): GlobalParameter => ({
  ...globalParameter,
  empresa: dadosEmpresaGlobalParameter.empresa,
  empresaESocialConfig: dadosEmpresaGlobalParameter.empresaEsocialConfig,
  empresaMCG: dadosEmpresaGlobalParameter.empresa.empresaMCG,
  empresaMCP: dadosEmpresaGlobalParameter.empresa.empresaMCP,
  empresaMFP: dadosEmpresaGlobalParameter.empresa.empresaMFP,
  empresaMLF: dadosEmpresaGlobalParameter.empresa.empresaMLF,
  grupoEmpresaConfig: dadosEmpresaGlobalParameter.empresa.grupoEmpresaRaiz.grupoEmpresaConfig,
  grupoForcli: dadosEmpresaGlobalParameter.empresa.grupoEmpresa.grupoEmpresaConfig.grupoForcli,
  grupoHistorico: dadosEmpresaGlobalParameter.empresa.grupoEmpresa.grupoEmpresaConfig.grupoHistorico,
  grupoPlanoConta: dadosEmpresaGlobalParameter.empresa.grupoEmpresa.grupoEmpresaConfig.grupoPlanoConta,
  lancamentoMCGConfig: dadosEmpresaGlobalParameter.empresa.empresaMCG.configLancamento,
});

export const validatePeriod = (dataLancamento, periodoBloqueio, modulo, empresa) => {
  let result = false;
  let referenciaInicial = null;
  let referenciaInicialUltimoDia = null;
  let referenciaFinal = null;
  const date = normalizeDate(dataLancamento);

  switch (modulo.toUpperCase()) {
    case 'MCG':
      const empresaMcg = empresa.empresaMCG;
      referenciaInicial = formatDate(parseRefToDate(empresaMcg.refAtual, 1));
      referenciaInicialUltimoDia = formatDate(parseLastDay(empresaMcg.refAtual));
      referenciaFinal = formatDate(parseLastDay(empresaMcg.refFinal));
      break;
    case 'MFP':
      const refAtualMFP = empresa.empresaMFP.referencia;
      const anoReferenciaInicialMFP = parseRefToDate(refAtualMFP, 1).getFullYear();

      referenciaInicial = formatDate(parseRefToDate(refAtualMFP, 1));
      referenciaInicialUltimoDia = formatDate(parseLastDay(refAtualMFP));
      referenciaFinal = formatDate(new Date(anoReferenciaInicialMFP, 11, 31));
      break;
    case 'MLF':
      const refAtualMLF = empresa.empresaMLF.referenciaAtual;
      const anoReferenciaInicialMLF = parseRefToDate(refAtualMLF, 1).getFullYear();

      referenciaInicial = formatDate(parseRefToDate(refAtualMLF, 1));
      referenciaInicialUltimoDia = formatDate(parseLastDay(refAtualMLF));
      referenciaFinal = formatDate(new Date(anoReferenciaInicialMLF, 11, 31));
      break;
  }

  if (periodoBloqueio) {
    if (periodoBloqueio === FORA_PERIODO_FISCAL.value && (compare(date, referenciaInicial) < 0 || compare(date, referenciaFinal) > 0)) {
      result = true;
    } else if (periodoBloqueio === FORA_MES_ATUAL.value && (compare(date, referenciaInicial) < 0 || compare(date, referenciaInicialUltimoDia) > 0)) {
      result = true;
    } else if (periodoBloqueio === PERIODO_ANTERIOR.value && compare(date, referenciaInicial) < 0) {
      result = true;
    }
  }

  return result;
};

const normalizeDate = (date: string): string => {
  let normalizedDate = date;
  const match = date.match(/^(\d{4})(\d{2})$/);

  if (match) {
    const [_, year, month] = match;
    normalizedDate = `01/${month}/${year}`;
  }

  return normalizedDate;
};
