import { Cancelable } from 'lodash';
import debounce from 'lodash.debounce';
import { useEffect, useRef } from 'react';

const useModalFix = () => {
  const debounceRef = useRef<(() => void) & Cancelable>();

  useEffect(
    () => {
      debounceRef.current = debounce(checkModalFrame, 500);
      document.documentElement.addEventListener('DOMSubtreeModified', debounceRef.current, false);
      return () => {
        document.documentElement.removeEventListener('DOMSubtreeModified', debounceRef.current);
        debounceRef.current.flush();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getBody = () => document.querySelector('body');

  const checkModalFrame = () => {
    if (getBody().classList.contains('modal-open')) {
      if (document.querySelectorAll('.modal.show').length === 0) {
        getBody().classList.remove('modal-open');
      }
    } else if (document.querySelectorAll('.modal.show').length) {
      getBody().classList.add('modal-open');
    }
  };
};

export default useModalFix;
