export const findParentUl = element => {
  let count = 0;
  let el = element;

  while (count < 10 && (!el || el.tagName !== 'UL')) {
    el = el.parentElement;
    count++;
  }

  return el && el.tagName === 'UL' ? el : null;
};

export const findNextUl = ul => {
  let count = 0;
  let el = null;

  while (count < 10 && (!el || el.tagName !== 'UL')) {
    el = el ? el.nextElementSibling : ul.nextElementSibling;
    count++;
  }

  return el && el.tagName === 'UL' ? el : null;
};

export const findPrevUl = ul => {
  let count = 0;
  let el = null;

  while (count < 10 && (!el || el.tagName !== 'UL')) {
    el = el ? el.previousElementSibling : ul.previousElementSibling;
    count++;
  }

  return el && el.tagName === 'UL' ? el : null;
};

export const findLastUl = ul => ul.parentElement.querySelector(':scope > ul:last-child');
