import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import {
  alertManager,
  AlertProps,
  AlertType,
  DetailManagerForm,
  ErroList,
  ERROR,
  InputGroupInline,
  InputType,
  isEmpty,
  OrderType,
  persist,
  ProcessBar,
  showToast,
  SUCCESS,
  userActions,
} from 'summer';
import { CLASS_NAME, Empresa } from '../../../../models/dtos/ger/empresa';
import { TributacaoEmpresa } from '../../../../models/dtos/ger/tributacaoEmpresa';
import { atualizarGlobalParameter } from '../../../../services/ger.service';
import { Reducers } from '../../../../store/ducks';
import { GlobalState, setGlobalParameter } from '../../../../store/ducks/global.duck';
import { tributacoesDefaultSortProps, tributacoesFormColumns } from '../../../../utilities/empresa.util';
import { redirectToLogin } from '../../../../utilities/login.util';
import { validateTributacao } from '../../../../utilities/tributacaoEmpresa.util';
import TributacaoEmpresaDetailEditView from '../empresa/TributacaoEmpresaDetailEditView';

const ValidarTributacaoEmpresa: FC = () => {
  const { globalParameter } = useSelector<Reducers, GlobalState>(state => state.globalReducer);

  const formProps = useForm<Empresa>({ mode: 'onBlur' });

  const [isOpenTributacoes, setIsOpenTributacoes] = useState(false);

  const dispatch = useDispatch();

  useEffect(
    () => {
      formProps.reset(globalParameter.empresa);
      formProps.register('tipoEmpresaPessoaFisica');
      if (validarCamposTributacao(globalParameter.empresa.tributacoes)) {
        setIsOpenTributacoes(true);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [globalParameter.empresa]
  );

  const validarCamposTributacao = (tributacoes: TributacaoEmpresa[]) => {
    let condTributacao = false;
    let condCamposTributacao = false;
    if (isEmpty(tributacoes) || tributacoes.length === 0) {
      condTributacao = true;
    } else {
      tributacoes.forEach(tributacao => {
        if (!condCamposTributacao) {
          condCamposTributacao = isEmpty(tributacao.refInicioVigencia);
        }
      });
    }
    return condTributacao || condCamposTributacao;
  };

  const hideScreen = () => {
    const emp: Empresa = { ...globalParameter.empresa, ...formProps.getValues() };
    const erros = validateTributacao(formProps.getValues());

    if (erros.length > 0) {
      const params: AlertProps = {
        message: <ErroList erros={erros} />,
        type: AlertType.INFORMATION,
      };
      alertManager.emit(params);
    } else {
      persist(emp, CLASS_NAME, {
        errorFunction: mensagem => {
          showToast(mensagem, ERROR);
        },
        thenFunction: () => {
          afterPersist();
          setIsOpenTributacoes(false);
          const mensagemToast = { mensagem: 'Vigência(s) da empresa cadastrada(s) com sucesso!', toastProps: SUCCESS };
          showToast(mensagemToast.mensagem, mensagemToast.toastProps);
        },
      });
    }
  };

  const afterPersist = () => {
    atualizarGlobalParameter(globalParameter.usuario, {
      errorFunction: mensagem => {
        showToast(mensagem, ERROR);
      },
      thenFunction: result => {
        dispatch(setGlobalParameter(result));
      },
    });
  };

  const close = () => {
    dispatch(userActions.logout());
    redirectToLogin();
  };

  return (
    <Modal size="xl" contentClassName="modal-content-full" centered={true} backdrop="static" isOpen={isOpenTributacoes}>
      <ModalBody>
        <div className="alert d-flex align-items-center pl-2 align-content-center alert-warning alert-dismissible fade show mb-0" role="alert">
          <span className="font-size-lg d-block d-40 mr-2 text-center">
            <FontAwesomeIcon icon="exclamation-triangle" />
          </span>
          <span>
            <strong className="d-block">Atenção!</strong>A empresa abaixo não possui uma vigência de tributação ou a mesma está faltando campos
            obrigatórios. Para o correto funcionamento do sistema é necessário que a vigência esteja completa. Ajuste os dados da vigência abaixo.
          </span>
        </div>
        <Row className="mt-3">
          <Col>
            <InputGroupInline disabled={true} formProps={formProps} id="razaoSocial" label="Razão social" />
          </Col>
          <Col>
            <InputGroupInline disabled={true} formProps={formProps} id="nomeFantasia" label="Nome fantasia" />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputGroupInline disabled={true} formProps={formProps} id="cpf" label="CPF" type={InputType.CPF} />
          </Col>
          <Col>
            <InputGroupInline disabled={true} formProps={formProps} id="cnpj" label="CNPJ" type={InputType.CNPJ} />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <DetailManagerForm
              columns={tributacoesFormColumns}
              defaultSortDirection={OrderType.DESC}
              detailViewSize="large"
              defaultSorted={tributacoesDefaultSortProps}
              detailView={TributacaoEmpresaDetailEditView}
              formProps={formProps}
              propName="tributacoes"
              keyField="rowIndex"
              title="Manutenção de empresa - Vigências de tributações"
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className="justify-content-end">
        <ProcessBar handleClose={close} handleSubmit={hideScreen} btnSubmitLabel="Salvar" btnCloseLabel="Fechar" />
      </ModalFooter>
    </Modal>
  );
};

export default ValidarTributacaoEmpresa;
