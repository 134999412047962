import { EmpresaMCG } from '../mcg/empresaMCG';
import { EmpresaMCP } from '../mcp/empresaMCP';
import { EmpresaMFP } from '../mfp/empresaMFP';
import { EmpresaMLF } from '../mlf/empresaMLF';
import { EmpresaEmail } from './empresaEmail';
import { EmpresaTelefone } from './empresaTelefone';
import { Endereco } from './endereco';
import { GrupoEmpresa } from './grupoEmpresa';
import { Pessoa } from './pessoa';
import { Responsavel } from './responsavel';
import { TributacaoEmpresa } from './tributacaoEmpresa';

export interface TributacaoEmpresaLabelValue {
  value?: string;
  label?: string;
}

export type TributacaoEmpresaValue = TributacaoEmpresa & TributacaoEmpresaLabelValue;

export interface Empresa extends Pessoa {
  acordoIsencaoMulta?: boolean;
  /** Este campo utiliza o apropriacaoCreditoEnum */
  apropriacaoCredito?: string;
  caepf?: string;
  centralizar?: boolean;
  /** Este campo utiliza o classificacaoTributariaEnum */
  classificacaoTributaria?: string;
  cnaef?: string;
  cnaefDesmembramento?: string;
  codigo?: number;
  codigoAcessoSimplesNacional?: string;
  /** Este campo utiliza o contribuicaoPrevidenciariaProdutorRuralEnum */
  contribPrevidenciariaProdutorRural?: string;
  criarGrupoEmpPersistir?: boolean;
  dataEmissaoCertificado?: string;
  dataEncerramentoAtividade?: string;
  dataInicioAtividade?: string;
  dataProtocoloRenovacao?: string;
  dataPublicacaoDiarioOficial?: string;
  dataRegistro?: string;
  dataVencimentoCertificado?: string;
  dtInicioPrestServCont?: string;
  dtInicioServAdm?: string;
  dtSimplesEmpresarial?: string;
  dtVencimentoAlvaraLocalizacao?: string;
  /** Este campo utiliza o enquadramentoSimplesNacionalEnum */
  enquadramentoSimplesNacional?: string;
  emails?: EmpresaEmail[];
  empresaFilial?: string;
  empresaMCG?: EmpresaMCG;
  empresaMCP?: EmpresaMCP;
  empresaMFP?: EmpresaMFP;
  empresaMLF?: EmpresaMLF;
  enderecos?: Endereco[];
  enderecoPrincipal?: Endereco;
  fator13?: number;
  fatorMes?: number;
  /** Este campo utiliza o tipoTributacaoRealFCONTEnum */
  forTribFCONT?: string;
  grupoEmpresa?: GrupoEmpresa;
  grupoEmpresaRaiz?: GrupoEmpresa;
  idEmpresaApi?: string;
  idGrupoEmpresaRaiz?: number;
  inativa?: boolean;
  /** Este campo utiliza o incidenciaTributariaEnum */
  incidenciaTributaria?: string;
  /** Este campo utiliza o indicativoSubstituicaoContribuicaoEnum */
  indicadorContribuicaoPatronal?: string;
  /** Este campo utiliza o indicativoContribuicaoEnum */
  indicadorContribuicaoSubstituida?: string;
  indicadorEmpresaGrandePorte?: boolean;
  indicadorNire?: boolean;
  matriz?: Empresa;
  mei?: boolean;
  ministerioLeiCertificado?: string;
  /** Este campo utiliza o naturezaJuridicaEnum */
  naturezaJuridica?: string;
  nroLivro?: string;
  numeroCertificado?: string;
  numeroPaginaDiarioOficial?: number;
  numeroProtocoloRenovacao?: string;
  numIdentOrgao?: string;
  observacoesGerais?: string;
  orgaoRegistro?: string;
  percentualReducaoContribuicao?: number;
  /** Este campo utiliza o qualificacaoPJEnum */
  qualificacaoPJ?: string;
  responsavel?: Responsavel;
  responsavelContador?: Responsavel;
  suframa?: string;
  telefones?: EmpresaTelefone[];
  /** Este campo utiliza o tipoCAEPFEnum */
  tipoCaepf?: string;
  /** Este campo utiliza o tipoContribuicaoEnum */
  tipoContribuicao?: string;
  tipoEmpresaAtividadeImobiliaria?: boolean;
  tipoEmpresaComercio?: boolean;
  tipoEmpresaCondominio?: boolean;
  tipoEmpresaConstrutora?: boolean;
  tipoEmpresaIndustria?: boolean;
  tipoEmpresaPessoaFisica?: boolean;
  tipoEmpresaPrestacaoServico?: boolean;
  tipoEmpresaProdutorRural?: boolean;
  tipoEmpresaTerceiroSetor?: boolean;
  dtTransfLei11096?: string;
  percTransf?: number;
  paralisada?: boolean;
  situacaoEmpresa?: string;
  origem?: string;
  cnpjEfr?: string;
  tributacoes?: TributacaoEmpresaValue[];
  logoEmpresaKeyStorage?: string;
}

export const CLASS_NAME = 'br.com.projetusinformatica.calima.ger.model.dto.empresa';
