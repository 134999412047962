import { MenuItem } from '../../../models/dtos/ger/menuItem';

const micMenu: MenuItem[] = [
  {
    icon: 'pe-7s-home',
    label: 'Home',
    to: '/mic/dashboard',
  },
  {
    content: [
      {
        label: 'Empresa',
        to: '/mic/empresasSearchView',
      },
      {
        label: 'Configuração',
        to: '/mic/configuracaoView',
      },
    ],
    icon: 'pe-7s-edit',
    label: 'Manutenção',
  },
  {
    content: [
      {
        label: 'Geração',
        to: '/mic/geracaoSearchView',
      },
      {
        label: 'Caixa Postal',
        to: '/mic/caixaPostalSearchView',
      },
    ],
    icon: 'pe-7s-play',
    label: 'Processos',
  },
  {
    content: [
      {
        label: 'Detalhamento de cobrança',
        to: '/mic/detalhamentoCobrancaReportView',
      },
    ],
    icon: 'pe-7s-print',
    isReportList: true,
    label: 'Relatórios',
  },
];

export default micMenu;
