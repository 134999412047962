import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ButtonHTMLAttributes, FC } from 'react';
import { Button } from 'reactstrap';
import { navegarWikiController } from 'summer';

import './AjudaButton.css';

interface AjudaButtonProps {
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
  label?: string;
  wikiId: string;
}

const AjudaButton: FC<AjudaButtonProps> = ({ buttonProps, label, wikiId }) => {
  const openAjudaMIC = async () => await navegarWikiController(wikiId);

  return (
    <Button color="link" className="p-0 text-blue btn-ajuda-mic" onClick={openAjudaMIC} {...buttonProps}>
      <u>{label || 'Como configurar o Integra Contador'}</u>
      <FontAwesomeIcon icon={['fas', 'question']} className="icon-ajuda-mic" color="#FFC100" />
    </Button>
  );
};

export default AjudaButton;
