import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Col } from 'reactstrap';
import illustration1 from '../../../assets/404.svg';
import block from '../../../assets/block.png';
import { Reducers } from '../../../store/ducks';
import { GlobalState } from '../../../store/ducks/global.duck';
import { validarTelasSemAcesso } from '../../../utilities/menu.util';

import './NotFound.css';

const NotFound: FC = () => {
  const { globalParameter } = useSelector<Reducers, GlobalState>(state => state.globalReducer);
  const [isTelaSemAcesso, setIsTelaSemAcesso] = useState(false);
  const history = useHistory();

  useEffect(
    () => {
      if (history.location) {
        const modulo = history.location.pathname.split('/')[1];
        setIsTelaSemAcesso(!globalParameter.usuario.acessoModulos[modulo] || !validarTelasSemAcesso(globalParameter, history.location.pathname));
        history.push(`/${modulo}/404`);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="notfound-container">
      <Col lg="6" md="9" className="px-4 px-lg-0 mx-auto text-center text-black">
        {isTelaSemAcesso ? (
          <img src={block} className="w-50 mx-auto d-block mb-5 img-fluid" alt="..." />
        ) : (
          <img src={illustration1} className="w-50 mx-auto d-block mb-5 img-fluid" alt="..." />
        )}
        <h3 className="font-size-xxl line-height-sm font-weight-light d-block px-3 mb-3 text-black-50">
          {isTelaSemAcesso ? 'Você não tem acesso a página que você está procurando.' : 'A página que você está procurando não existe.'}
        </h3>
        <span className="mb-0">
          {isTelaSemAcesso ? (
            <>
              Em caso de dúvida, entre em contato com o <b>administrador responsável pelo seu acesso ao sistema.</b>
            </>
          ) : (
            'Em caso de dúvida, entre em contato com o nosso suporte!'
          )}
        </span>
      </Col>
    </div>
  );
};

export default NotFound;
