import { UsuarioAcessoModulos } from '../../dtos/ger/usuarioAcessoModulos';
import { BOX, CONNECT, GER, MCG, MCP, MFP, MHO, MIC, MLF, MPR, SITE } from './ModuloEnum';

export const ESOCIAL = { value: 'ESOCIAL', label: 'eSocial' };

export const MODULO_TICKET_ENUM = [GER, MFP, ESOCIAL, MCG, MLF, MCP, MPR, MHO, CONNECT, BOX, SITE, MIC];

export const getModulosComAcessoTicket = (acessoModulos: UsuarioAcessoModulos) => {
  return MODULO_TICKET_ENUM.filter(it => {
    let result;
    if (it === ESOCIAL) {
      result = acessoModulos.mfp;
    } else {
      result = acessoModulos[it.value.toLowerCase()];
    }
    return result;
  });
};
