import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { Calendar } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { AGUARDANDO, EXECUTANDO } from '../../../models/enumerated/ger/jobStatusEnum';
import { Reducers } from '../../../store/ducks';
import { abrirJanelaProcessos } from '../../../store/ducks/global.duck';
import ClipboardManager from '../clipboardManager/ClipboardManager';

interface SidebarFooterProps {
  currentState?: string;
}

const SidebarFooter: FC<SidebarFooterProps> = props => {
  const {
    jobReducer: { data: jobs },
  } = useSelector<Reducers, Reducers>(state => state);

  const [runningJobs, setRunningJobs] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const filterJobs = jobs.filter(it => it.status === EXECUTANDO.value || it.status === AGUARDANDO.value);
    setRunningJobs(filterJobs.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs]);

  const openProcessos = () => {
    dispatch(abrirJanelaProcessos());
  };

  return (
    <div className="app-sidebar--footer">
      <ul>
        <li>
          <Link
            to={`/${props.currentState.toLowerCase()}/agendaProcessView`}
            className="btn btn-sm btn-transition-none px-2 mx-2"
            id="AgendaPageTooltip"
          >
            <Calendar />
          </Link>
          <Link
            to={`/${props.currentState.toLowerCase()}/grupoRelatorioSearchView`}
            className="btn btn-sm btn-transition-none px-2 mx-2"
            id="GrupoRelPageTooltip"
            style={{
              fontSize: 19,
              verticalAlign: 'top',
            }}
          >
            <FontAwesomeIcon icon="layer-group" />
          </Link>
          <ClipboardManager />
          <Button
            color="link"
            className={cx('sidebar-toggle-processos', { 'sidebar-toggle-processos-active rounded-circle': runningJobs })}
            onClick={openProcessos}
            id="toggle-processos"
          >
            <FontAwesomeIcon icon={['fas', 'redo-alt']} spin={runningJobs} />
          </Button>
          <UncontrolledTooltip target="AgendaPageTooltip" container=".app-sidebar--footer">
            Agenda
          </UncontrolledTooltip>
          <UncontrolledTooltip target="GrupoRelPageTooltip" container=".app-sidebar--footer">
            Grupos de Relatórios
          </UncontrolledTooltip>
          <UncontrolledTooltip target="toggle-processos" container=".app-sidebar--footer">
            Processos em Execução
          </UncontrolledTooltip>
        </li>
      </ul>
    </div>
  );
};

export default SidebarFooter;
