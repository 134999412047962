import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Alert, Col, Row } from 'reactstrap';
import { compare, DetailEditForm, DetailEditViewFormProps, InputGroupInline, InputType, isEmpty, isNotEmpty, REF_PATTERN } from 'summer';
import { TributacaoEmpresa } from '../../../../models/dtos/ger/tributacaoEmpresa';
import { CLASSIFICACAO_01, CLASSIFICACAO_99 } from '../../../../models/enumerated/ger/classificacaoTributariaEnum';
import { SIMPLES_NACIONAL, TIPO_TRIBUTACAO_ENUM_VALUES, TRIBUTACAO_LUCRO_ENUM_VALUES } from '../../../../models/enumerated/ger/tipoTributacaoEnum';
import EnumUtil from '../../../../utilities/enum.util';

const TributacaoEmpresaDetailEditView: FC<DetailEditViewFormProps<TributacaoEmpresa>> = props => {
  const formProps = useForm<TributacaoEmpresa>({
    defaultValues: {
      ...props.selectedItem,
      empresa: props.parentFormProps.getValues(),
    },
    mode: 'onBlur',
  });

  const [isPessoaFisica, setIsPessoaFisica] = useState(false);
  const [isEdicao, setIsEdicao] = useState(false);

  const tributacoes = formProps.getValues().empresa && formProps.getValues().empresa.tributacoes;
  const validarTributacoesSemFimVigencia = tributacoes ? tributacoes.some(tributacao => isEmpty(tributacao.refFimVigencia)) : false;
  const verificarEdicao =
    isNotEmpty(props.selectedItem) &&
    (props.selectedItem.tipoTributacao !== formProps.getValues().tipoTributacao ||
      props.selectedItem.refInicioVigencia !== formProps.getValues().refInicioVigencia);

  useEffect(
    () => {
      formProps.register('empresa');
      formProps.register('impostoConfiguracao.id');
      setIsPessoaFisica(formProps.getValues().empresa.tipoEmpresaPessoaFisica);
      setIsEdicao(isNotEmpty(props.selectedItem));
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleSubmit = (formData: TributacaoEmpresa, done: any) => {
    // Verifica se a referência final é uma string vazia. Se for, seta o valor nulo.
    if (isEmpty(formData.refFimVigencia)) {
      formData.refFimVigencia = null;
      onChangeTipoTributacao();
    }
    props.handleSubmit(formData, done);
  };

  const validateExtras = () => {
    const erros = [];

    if (
      isNotEmpty(formProps.getValues().refFimVigencia) &&
      compare(formProps.getValues().refInicioVigencia, formProps.getValues().refFimVigencia, REF_PATTERN) > 0
    ) {
      erros.push('A referência no fim da vigência deve ser superior a referência no início da vigência.');
    }
    if (!isEdicao && validarTributacoesSemFimVigencia) {
      erros.push('Para cadastrar uma nova vigência de tributação, vigências já cadastradas devem possuir uma Referência no fim');
    }

    return erros;
  };

  const onChangeTipoTributacao = () => {
    if (formProps.getValues().tipoTributacao === SIMPLES_NACIONAL.value && isEmpty(formProps.getValues().refFimVigencia)) {
      props.parentFormProps.setValue('classificacaoTributaria', CLASSIFICACAO_01.value);
    } else if (EnumUtil.contains(TRIBUTACAO_LUCRO_ENUM_VALUES, formProps.getValues().tipoTributacao)) {
      props.parentFormProps.setValue('classificacaoTributaria', CLASSIFICACAO_99.value);
    }
  };

  return (
    <DetailEditForm handleSubmit={handleSubmit} handleCancel={props.handleCancel} formProps={formProps} validateExtras={validateExtras}>
      <div className={cx({ 'd-none': !isPessoaFisica })}>
        <Alert color="primary" className="d-flex align-items-sm-center justify-content-sm-center">
          <span className="font-size-sm mr-2">
            <FontAwesomeIcon icon="exclamation-triangle" size="1x" className="text-primary" />
          </span>
          <div>
            O campo 'Tipo de tributação' deve ser deixado em branco quando a opção 'Pessoa física' estiver marcada nas configurações da empresa.
          </div>
        </Alert>
      </div>
      <Row>
        <Col>
          <InputGroupInline
            formProps={{ ...formProps, validation: { required: !isPessoaFisica } }}
            id="tipoTributacao"
            inputDropDownProps={{
              optionsProvider: TIPO_TRIBUTACAO_ENUM_VALUES,
              selectProps: { isClearable: true },
            }}
            label="Tipo de tributação"
            type={InputType.DROP_DOWN}
            disabled={isPessoaFisica}
          />
        </Col>
        <Col>
          <InputGroupInline
            formProps={{ ...formProps, validation: { required: true } }}
            id="refInicioVigencia"
            label="Referência no início da vigência"
            type={InputType.REFERENCE}
          />
        </Col>
        <Col>
          <InputGroupInline
            formProps={formProps}
            id="refFimVigencia"
            label="Referência no fim da vigência"
            type={InputType.REFERENCE}
            tooltipProps={{ message: 'NÃO preencher o campo caso esta seja a tributação atual da empresa.' }}
          />
        </Col>
      </Row>
      {verificarEdicao && (
        <Row>
          <Col>
            <span className="opacity-7">
              * Alterar a tributação pode gerar problemas nos arquivos digitais e processos já excutados.
              <br />
            </span>
            <span className={cx('opacity-7', { 'd-none': isNotEmpty(props.selectedItem.refFimVigencia) })}>
              * Para alterar a tributação vigente basta informar o fim da vigência atual e incluir uma nova tributação.
            </span>
          </Col>
        </Row>
      )}
    </DetailEditForm>
  );
};

export default TributacaoEmpresaDetailEditView;
