import cx from 'clsx';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonGroup } from 'reactstrap';
import { HeaderMenu } from 'summer';
import { FavoritosRecentesProvider } from '../../../hooks/useFavoritosRecentes';
import { QuickAccessType } from '../../../hooks/useQuickAccess';
import { histAcessoActions, Reducers } from '../../../store/ducks';
import { toggleMobileSidebar } from '../../../store/ducks/global.duck';
import ButtonCrisp from '../ger/buttonCrisp/ButtonCrisp';
import QuickAccess from '../quickAccess/QuickAccess';
import GrupoRelControl from './GrupoRelControl';
import HeaderAppsMenu from './HeaderAppsMenu';
import HeaderCompany from './HeaderCompany';
import HeaderDrawer from './HeaderDrawer';
import HeaderModulos from './HeaderModulos';
import HeaderNotifications from './HeaderNotifications';
import HeaderSearch from './HeaderSearch';
import HeaderUserbox from './HeaderUserBox';
import HeaderVideos from './HeaderVideos';

import './Header.css';

export interface HeaderProps {
  sidebarToggleMobile?: boolean;
}

const Header: FC<HeaderProps> = ({ sidebarToggleMobile }) => {
  const {
    histAcessoReducer: { favs, recents, isFavOpen, isRecOpen },
    globalReducer: { globalParameter, currentState, headerSearchHover },
  } = useSelector<Reducers, Reducers>(state => state);

  const dispatch = useDispatch();

  const onClick = () => dispatch(toggleMobileSidebar());

  const isRecPorModulo = () => {
    let result = false;
    if (globalParameter.usuario && globalParameter.usuario.configuracao) {
      result = globalParameter.usuario.configuracao.recentesPorModulo;
    }
    return result;
  };

  const isFavPorModulo = () => {
    let result = false;
    if (globalParameter.usuario && globalParameter.usuario.configuracao) {
      result = globalParameter.usuario.configuracao.favoritosPorModulo;
    }
    return result;
  };

  const filtrarPorAcesso = lista => {
    return lista.filter(
      it =>
        globalParameter.usuario.acessoModulos[it.modulo.toLowerCase()] &&
        !globalParameter.usuario.telasSemAcesso.find(semAcesso => semAcesso.linkTela === `/${it.modulo.toLowerCase()}/${it.itemMenu}`)
    );
  };

  const toggleFav = () => dispatch(histAcessoActions.toggleFav());
  const toggleRec = () => dispatch(histAcessoActions.toggleRec());

  return (
    <>
      <GrupoRelControl />
      <div className={`app-header app-header--shadow app-header--opacity-bg ${currentState.toLowerCase()}-app-header-mobile`}>
        <div className="app-header--pane">
          <button
            onClick={onClick}
            className={cx('navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn mr-3', { 'is-active': sidebarToggleMobile })}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
          <HeaderSearch />
          <HeaderMenu>
            <ButtonGroup className={cx('btn-group-modulos-rec-fav', { 'd-none': headerSearchHover })} size="sm">
              <HeaderModulos />
              <FavoritosRecentesProvider>
                <QuickAccess
                  type={QuickAccessType.FAV}
                  items={filtrarPorAcesso(favs)}
                  single={isFavPorModulo()}
                  isOpen={isFavOpen}
                  toggle={toggleFav}
                />
                <QuickAccess
                  type={QuickAccessType.REC}
                  items={filtrarPorAcesso(recents)}
                  single={isRecPorModulo()}
                  isOpen={isRecOpen}
                  toggle={toggleRec}
                />
              </FavoritosRecentesProvider>
            </ButtonGroup>
          </HeaderMenu>
        </div>
        <div className="emp-mobile">
          <HeaderCompany />
        </div>
        <div className="app-header--pane">
          {globalParameter.listarVideos && globalParameter.listarVideos[currentState] && (
            <span className="d-inline-block pr-2 hide-btn">
              <HeaderVideos />
            </span>
          )}
          <span className="d-inline-block pr-2">
            <HeaderNotifications />
          </span>
          <span className="d-inline-block pr-2">
            <HeaderAppsMenu />
          </span>
          {globalParameter.usuario.acessoSuporte && (
            <span className="d-inline-block pr-2 hide-btn">
              <ButtonCrisp />
            </span>
          )}
          <HeaderUserbox />
          <HeaderDrawer />
        </div>
      </div>
      <span className={`label-empresa-mobile-${currentState.toLowerCase()} app-header--shadow`}>
        <HeaderCompany />
      </span>
    </>
  );
};

export default Header;
