import { createLoadable } from 'summer';

const mcpRoutes = [
  {
    component: createLoadable(() => import('./dashboard/DashboardMCP'), 'DashboardMCP'),
    path: '/mcp/dashboard',
  },
  {
    component: createLoadable(() => import('./report/bensAdquiridosReferencia/BensAdquiridosReportView'), 'BensAdquiridosReportView'),
    path: '/mcp/bensAdquiridosReportView',
  },
  {
    component: createLoadable(() => import('./report/bensDepreciados/BemDepreciadoReportView'), 'BemDepreciadoReportView'),
    path: '/mcp/bemDepreciadoReportView',
  },
  {
    component: createLoadable(() => import('./report/bensDepreciadosExercicio/BemDepreciadoExercicioReportView'), 'BemDepreciadoExercicioReportView'),
    path: '/mcp/bemDepreciadoExercicioReportView',
  },
  {
    component: createLoadable(() => import('./report/bensFichaAnalitica/BemFichaAnaliticaReportView'), 'BemFichaAnaliticaReportView'),
    path: '/mcp/bemFichaAnaliticaReportView',
  },
  {
    component: createLoadable(() => import('./report/bensEtiqueta/BensEtiquetaReportView'), 'BensEtiquetaReportView'),
    path: '/mcp/bensEtiquetaReportView',
  },
  {
    component: createLoadable(() => import('./report/bemResumoContabil/BemResumoContabilReportView'), 'BemResumoContabilReportView'),
    path: '/mcp/bemResumoContabilReportView',
  },
  {
    component: createLoadable(() => import('./report/relacaoConta/RelacaoContaReportView'), 'RelacaoContaReportView'),
    path: '/mcp/relacaoContaReportView',
  },
  {
    component: createLoadable(() => import('./report/relacaoBens/RelacaoBensContaReportView'), 'RelacaoBensContaReportView'),
    path: '/mcp/relacaoBensContaReportView',
  },
  {
    component: createLoadable(() => import('./report/relacaoBaixa/RelacaoBaixaReportView'), 'RelacaoBaixaReportView'),
    path: '/mcp/relacaoBaixaReportView',
  },
  {
    component: createLoadable(() => import('./bem/BensSearchView'), 'BensSearchView'),
    exact: true,
    path: '/mcp/bensSearchView',
  },
  {
    component: createLoadable(() => import('./bem/BensEditView'), 'BensEditView'),
    path: '/mcp/bensSearchView/edit',
  },
  {
    component: createLoadable(() => import('./transferenciaCentroCusto/TransferenciaCentroCustoSearchView'), 'TransferenciaCentroCustoSearchView'),
    exact: true,
    path: '/mcp/transferenciaCentroCustoSearchView',
  },
  {
    component: createLoadable(() => import('./transferenciaCentroCusto/TransferenciaCentroCustosEditView'), 'TransferenciaCentroCustosEditView'),
    path: '/mcp/transferenciaCentroCustoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./fechamentoRestauracao/FechamentoRestauracaoMCPProcessView'), 'FechamentoRestauracaoMCPProcessView'),
    path: '/mcp/fechamentoRestauracaoMCPProcessView',
  },
  {
    component: createLoadable(() => import('./contas/ContasSearchView'), 'ContasSearchView'),
    exact: true,
    path: '/mcp/contasSearchView',
  },
  {
    component: createLoadable(() => import('./contas/ContasEditView'), 'ContasEditView'),
    path: '/mcp/contasSearchView/edit',
  },
  {
    component: createLoadable(() => import('./configEmpresaMCP/ConfigEmpresaMCPEditView'), 'ConfigEmpresaMCPEditView'),
    exact: true,
    path: '/mcp/configEmpresaMCPEditView',
  },
  {
    component: createLoadable(() => import('./baixa/BaixaSearchView'), 'BaixaSearchView'),
    exact: true,
    path: '/mcp/baixaSearchView',
  },
  {
    component: createLoadable(() => import('./baixa/BaixaEditView'), 'BaixaEditView'),
    exact: true,
    path: '/mcp/baixaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./importacaoBemCSV/ImportacaoBemCSVProcessView'), 'ImportacaoBemCSVProcessView'),
    exact: true,
    path: '/mcp/importacaoBemCSVProcessView',
  },
];

export default mcpRoutes;
