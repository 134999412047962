import React, { FC, useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import {
  alertManager,
  AlertType,
  ColumnsProps,
  ERROR,
  FilterType,
  INFORMATION,
  referenceColumn,
  SearchDataGrid,
  SelectModeEnum,
  showToast,
} from 'summer';
import useLoading from '../../../hooks/useLoading';
import { EventosPendentesEnvioAutomaticoESocialParam } from '../../../models/dtos/mfp/eventosPendentesEnvioAutomaticoESocialParam';
import { enviarEventosPeriodicosESocial } from '../../../services/mfp.service';

interface EventosPendentesESocialModalViewProps {
  onClose: () => void;
  eventos: EventosPendentesEnvioAutomaticoESocialParam[];
}

const EventosPendentesESocialModalView: FC<EventosPendentesESocialModalViewProps> = ({ onClose, eventos }) => {
  const [openModalGridEventos, setOpenModalGridEventos] = useState(false);
  const [eventosSelecionados, setEventosSelecionados] = useState<EventosPendentesEnvioAutomaticoESocialParam[]>([]);

  const numTotalEventos = eventos.map(it => it.quantidadeEventos).reduce((prev, current) => prev + current, 0);
  const loading = useLoading();

  const columns: ColumnsProps[] = [
    {
      dataField: 'empresa',
      filter: {
        type: FilterType.TEXT,
      },
      sort: true,
      text: 'Empresa',
    },
    referenceColumn({
      dataField: 'referencia',
      sort: true,
      text: 'Referência',
    }),
    {
      dataField: 'eventos',
      filter: {
        type: FilterType.TEXT,
      },
      sort: true,
      text: 'Eventos',
    },
  ];

  const addChar = char => (numTotalEventos === 1 ? '' : char);

  const showModalGridEventos = () => setOpenModalGridEventos(true);

  const onSelect = rows => setEventosSelecionados(rows);

  const enviarTodos = () => enviarEventos(eventos);

  const enviarSelecionados = () => {
    if (eventosSelecionados.length === 0) {
      alertManager.emit({
        message: 'Pelo menos um item deve ser selecionado.',
        type: AlertType.INFORMATION,
      });
    } else {
      enviarEventos(eventosSelecionados);
    }
  };

  const enviarEventos = eventosPeriodicos => {
    loading.show();
    enviarEventosPeriodicosESocial(eventosPeriodicos, {
      errorFunction: mensagem => {
        loading.hide();
        showToast(mensagem, ERROR);
      },
      thenFunction: () => {
        loading.hide();
        onClose();
        showToast('Os eventos periódicos estão sendo enviados para o eSocial!', INFORMATION);
      },
    });
  };

  return (
    <Modal isOpen={true} centered={true} size={openModalGridEventos ? 'xl' : 'lg'} backdrop="static">
      <ModalBody>
        {openModalGridEventos ? (
          <>
            <div>
              <b className="fa-2x">eSocial</b>
            </div>
            <div className="mb-3">
              <b className="fa-lg">{numTotalEventos} eventos pendentes</b>
            </div>
            <SearchDataGrid
              data={eventos}
              columns={columns}
              keyField="idEmpresaMFP"
              disableDoubleClick={true}
              extraData={{ hideActionColumn: true }}
              addButtonProps={{ hideAddButton: true }}
              cancelButtonProps={{ handleCancelClick: onClose }}
              selectRow={{ onSelect, mode: SelectModeEnum.CHECKBOX }}
              actionButtons={[{ label: 'Enviar selecionados', onClick: enviarSelecionados }]}
            />
          </>
        ) : (
          <div className="text-center m-5">
            <b className="fa-lg">
              Identificamos que existe{addChar('m')} {numTotalEventos} evento{addChar('s')} periódico{addChar('s')} pendente{addChar('s')} para envio
              ao eSocial. Gostaria de enviá-lo{addChar('s')} agora?
            </b>
            <div className="mt-3 mb-1">
              <Button onClick={enviarTodos} color="primary" size="sm">
                Sim, desejo enviar todos agora
              </Button>
              <Button onClick={showModalGridEventos} color="secondary" size="sm" className="ml-3">
                Sim, desejo selecionar as empresas
              </Button>
            </div>
            <div>
              <Button onClick={onClose} color="link" size="sm">
                <small>Não, irei enviar manualmente mais tarde</small>
              </Button>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default EventosPendentesESocialModalView;
