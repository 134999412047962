import { Client } from '@stomp/stompjs';
import { environment, getRandomUUID } from 'summer';
import { SocketFile } from '../../../models/dtos/ger/socketFile';

type FileViaSocketCallback = (index: number, total: number, currentProgress: number, globalProgress: number) => void;

const CEM = 100;
const KBYTE = 1024;
const KBYTE_PIECE = KBYTE * 10;

let client: Client;

export function connect(codigoCliente) {
  const baseURL = environment.siteURL.replace('http', 'ws');

  if (client && client.active) {
    client.deactivate();
  }
  client = new Client({ brokerURL: `${baseURL}spring/calima-ws`, connectHeaders: { token: codigoCliente } });
  return client;
}

export function disconnect() {
  if (client && client.active && client.connected) {
    client.deactivate();
  }
}

export const uploadFilesViaSocket = (files: File[], onProgress?: FileViaSocketCallback): Promise<SocketFile[]> =>
  new Promise(resolve => {
    if (client) {
      const uploadedFileList: SocketFile[] = [];

      let fileIndex;

      let stompCreation;
      let stompPart;

      let currentFile: ArrayBuffer;
      let currentFileId;
      let currentFileName;
      let currentFilePiece;
      let currentFileCycles;

      let currentProgress;

      const uploadFile = (file: File) => {
        const reader = new FileReader();
        reader.onload = () => {
          currentFilePiece = -1;
          currentFile = reader.result as ArrayBuffer;
          currentFileId = getRandomUUID();
          currentFileName = file.name;
          currentFileCycles = Math.ceil(currentFile.byteLength / KBYTE_PIECE);
          currentProgress = 0;

          client.publish({
            body: JSON.stringify({ name: file.name, type: file.type, id: currentFileId }),
            destination: '/app/socketUploadStart',
          });
        };
        reader.readAsArrayBuffer(file);
      };

      const uploadNextFilePart = () => {
        currentFilePiece++;

        const perItemProgress = CEM / files.length;

        if (currentFilePiece < currentFileCycles) {
          const progress = Math.floor((currentFilePiece * CEM) / currentFileCycles);

          if (onProgress && progress !== currentProgress) {
            currentProgress = progress;
            onProgress(fileIndex + 1, files.length, progress, perItemProgress * fileIndex + (perItemProgress * progress) / CEM);
          }

          const begin = KBYTE_PIECE * currentFilePiece;
          const slice = new Uint8Array(currentFile.slice(begin, begin + KBYTE_PIECE) as ArrayBuffer);

          client.publish({
            body: JSON.stringify({
              body: btoa(String.fromCharCode.apply(null, slice)),
              id: currentFileId,
              name: currentFileName,
            }),
            destination: '/app/socketUpload',
          });
        } else {
          if (onProgress) {
            onProgress(fileIndex + 1, files.length, CEM, perItemProgress * (fileIndex + 1));
          }

          uploadedFileList.push({
            fileName: currentFileName,
            id: currentFileId,
          });

          fileIndex++;

          if (fileIndex < files.length) {
            uploadFile(files[fileIndex]);
          } else {
            client.unsubscribe(stompCreation.id);
            client.unsubscribe(stompPart.id);

            resolve(uploadedFileList);
          }
        }
      };

      stompCreation = client.subscribe('/topic/socketUploadFileCreationDone', message => {
        if (message.body === currentFileName) {
          uploadNextFilePart();
        }
      });

      stompPart = client.subscribe('/topic/socketUploadFilePartDone', message => {
        if (message.body === currentFileName) {
          uploadNextFilePart();
        }
      });

      if (files.length) {
        setTimeout(() => {
          fileIndex = 0;
          uploadFile(files[fileIndex]);
        }, 250);
      }
    }
  });

export const getClient = () => {
  return client;
};
