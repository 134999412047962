import { createActions, handleActions } from 'redux-actions';
import { MenuItem } from '../../models/dtos/ger/menuItem';

export interface GrupoRelatorioState {
  grupo?: string;
  list?: MenuItem[];
}

const initialState: GrupoRelatorioState = {
  grupo: null,
  list: [],
};

const shiftArray = ([_, ...l]: MenuItem[]) => [...l];

export const actions = createActions({
  CLEAR: () => null,
  SET: list => list,
  SHIFT: () => null,
});

export const reducer = handleActions<GrupoRelatorioState>(
  {
    [actions.set.toString()]: (state: GrupoRelatorioState, action: any) => ({ grupo: action.payload.grupo, list: [...action.payload.list] }),
    [actions.shift.toString()]: (state: GrupoRelatorioState) => {
      const list = shiftArray(state.list);
      return { grupo: list.length ? state.grupo : null, list };
    },
    [actions.clear.toString()]: () => ({ grupo: null, list: [] }),
  },
  initialState
);
