import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button } from 'reactstrap';
import { ERROR, LIGHT, showToast } from 'summer';
import { Empresa } from '../../../models/dtos/ger/empresa';
import { checkUsuarioVisualizouTour, salvarUsuarioVisualizouTour } from '../../../services/ger.service';
import { Reducers } from '../../../store/ducks';
import { toggleMobileSidebar } from '../../../store/ducks/global.duck';
import './IntegracaoContabilTour.css';

const STEPS = [
  {
    content: (
      <>
        <FontAwesomeIcon color={'#3C44B1'} icon="exclamation-triangle" size="lg" />
        <h4 className="font-weight-bold mt-3">A integração contábil está descentralizada</h4>
        <div className="text-dark">
          Caso queira integrar os lançamentos da filial na matriz deverá ser marcado o campo "Gerar contabilização desta filial na matriz".
        </div>
      </>
    ),
    position: 'top',
    selector: () => document.querySelector('#box-check-gerarContabilizacaoFilialNaMatriz'),
  },
];

const IntegracaoContabilTour: FC = () => {
  const {
    globalReducer: { globalParameter },
  } = useSelector<Reducers, Reducers>(state => state);

  const currentStep = STEPS[0];
  const dispatch = useDispatch();
  const history = useHistory();
  const [exibir, setExibir] = useState(false);

  const getViewAtual = () => {
    const url = window.location.href;
    const urlParts = url.split('/');
    return urlParts[urlParts.length - 1];
  };

  const checkIsFilial = (empresa: Empresa) => {
    return empresa && empresa.empresaFilial ? empresa.empresaFilial.toString() === 'true' : false;
  };

  useEffect(() => {
    if (globalParameter && globalParameter.usuario && !globalParameter.empresaMCG.gerarContabilizacaoFilialNaMatriz) {
      if (getViewAtual() === 'configEmpresaMCGEditView' && checkIsFilial(globalParameter.empresa)) {
        setTimeout(() => {
          checkUsuarioVisualizouTour(globalParameter.usuario.id, 'INTEGRACAO_CONTABIL', {
            errorFunction: message => showToast(message, ERROR),
            thenFunction: res => {
              setExibir(!res);
            },
          });
        }, 1000);
      }
    }
  }, [globalParameter, history.location.pathname]);

  useEffect(() => {
    if (exibir) {
      const element = currentStep.selector && currentStep.selector();

      if (element) {
        const destaqueEl: any = document.querySelector('.destaque');
        destaqueEl.innerHTML = element.outerHTML;
        destaqueEl.style.background = LIGHT.value ? '#FFF' : '#39405C';
      } else {
        document.querySelector('.destaque').innerHTML = '';
      }

      if (isMobile()) {
        dispatch(toggleMobileSidebar());
      }
      window.scrollTo({ top: 0, behavior: 'smooth' });
      document.querySelector('html').style.overflow = 'hidden';
    } else {
      document.querySelector('html').style.overflow = 'auto';
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exibir]);

  const fechar = () => {
    setExibir(false);
    salvarUsuarioVisualizouTour(globalParameter.usuario.id, 'INTEGRACAO_CONTABIL', {
      errorFunction: message => showToast(message, ERROR),
      thenFunction: () => null,
    });
  };

  const getTopPx = (alignVerticalCenter, extraPx) => {
    let topPx = 0;
    const element = currentStep.selector();
    if (element) {
      topPx = element.getBoundingClientRect().top + element.getBoundingClientRect().height / (alignVerticalCenter ? 2 : 1) + extraPx;
    }
    return topPx;
  };

  const getLeftRightPx = (dimension, extraPx) => {
    const element = currentStep.selector();
    return element ? element.getBoundingClientRect()[dimension] + extraPx : 0;
  };

  const getRightPx = () => {
    const element = currentStep.selector();
    return element ? element.getBoundingClientRect().right - element.getBoundingClientRect().left : 0;
  };

  const getStyle = () => {
    const style: CSSProperties = {};

    if (isMobile()) {
      style.top = '50%';
      style.left = '50%';
      style.transform = 'translate(-50%, -50%)';
    } else {
      switch (currentStep.position) {
        case 'top-end':
          style.top = getTopPx(false, 30);
          style.right = getRightPx();
          break;
        case 'top':
          style.top = getTopPx(false, 30);
          style.left = getLeftRightPx('left', 20);
          break;
        default:
          style.top = getTopPx(true, -150);
          style.left = getLeftRightPx('width', 20);
          break;
      }
    }
    return style;
  };

  const getDestaqueStyle = () => {
    const element: any = currentStep.selector && currentStep.selector();
    let style: any = {};
    if (element) {
      style = element.getBoundingClientRect().toJSON();
    }
    return style;
  };

  const isMobile = () => window.innerWidth < 1100;

  return (
    exibir && (
      <div className="tour-container">
        <div className="destaque" style={getDestaqueStyle()} />
        <div className={`tour-content arrow-${currentStep.position}`} style={getStyle()}>
          {currentStep.content}
          <Button size="sm" onClick={fechar} color="primary" className="mt-3">
            Ok, entendi!
          </Button>
        </div>
      </div>
    )
  );
};

export default IntegracaoContabilTour;
