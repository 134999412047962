import { Basic } from 'summer';
import { CepUF } from './cepUF';
import { CodigoMunicipioDeclan } from './codigoMunicipioDeclan';

export interface CepLocalidade extends Basic {
  nome?: string;
  cep?: string;
  tipo?: string;
  codigoMunicipio?: string;
  uf?: CepUF;
  codigoMunicipioDeclan?: CodigoMunicipioDeclan;
}

export const CLASS_NAME = 'br.com.projetusinformatica.calima.ger.model.dto.ceplocalidade';
