import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Popover } from 'reactstrap';
import { DATE_MONTH_YEAR_PATTERN, ERROR, eventManager, EventType, showToast } from 'summer';
import { getUltimaVisualizacaoTour, salvarUsuarioVisualizouTour } from '../../../services/ger.service';
import { histAcessoActions, Reducers } from '../../../store/ducks';
import { GlobalState } from '../../../store/ducks/global.duck';
import { HistAcessoState } from '../../../store/ducks/histAcesso.duck';

import './TourFavoritos.css';

const ID_TOUR = 'TOUR_FAVORITOS';
const TRINTA_DIAS_MS = 2592000000;

const TourFavoritos: FC = () => {
  const [{ isFavOpen, favs }, { globalParameter }] = useSelector<Reducers, [HistAcessoState, GlobalState]>(state => [
    state.histAcessoReducer,
    state.globalReducer,
  ]);

  const [isOpenButtonTour, setIsOpenButtonTour] = useState(false);
  const [isOpenFavTour, setIsOpenFavTour] = useState(false);
  const [isTourRunning, setIsTourRunning] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(
    () => {
      eventManager.on(EventType.FAV_RESULT, onFavResult);
      return () => eventManager.off(EventType.FAV_RESULT, onFavResult);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isTourRunning]
  );

  useEffect(
    () => {
      setIsOpenButtonTour(false);
      setTimeout(() => {
        if (document.querySelector('#fav-button') && favs.length === 0) {
          init();
        }
      }, 1000);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  );

  useEffect(
    () => {
      if (isFavOpen) {
        if (document.querySelector('#popover-list-favoritos')) {
          setTimeout(() => {
            if (isTourRunning) {
              setIsOpenFavTour(true);
            }
          }, 250);
        }
      } else {
        toggleFavTour();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFavOpen]
  );

  const init = () => {
    getUltimaVisualizacaoTour(globalParameter.usuario.id, ID_TOUR, {
      errorFunction: mensagem => showToast(mensagem, ERROR),
      thenFunction: res => {
        if (!res || moment().diff(moment(res.dataVisualizacao, DATE_MONTH_YEAR_PATTERN)) > TRINTA_DIAS_MS) {
          show();
        }
      },
    });
  };

  const show = () => {
    setIsTourRunning(true);
    setIsOpenButtonTour(true);

    salvarUsuarioVisualizouTour(globalParameter.usuario.id, ID_TOUR, {
      errorFunction: mensagem => showToast(mensagem, ERROR),
      thenFunction: () => null,
    });
  };

  const onFavResult = (_1, _2, active) => {
    if (active && isTourRunning) {
      setIsOpenButtonTour(false);
      dispatch(histAcessoActions.toggleFav());
    }
  };

  const toggleButtonTour = () => {
    setIsOpenButtonTour(false);
    setIsTourRunning(false);
  };

  const toggleFavTour = () => {
    setIsOpenFavTour(false);
    setIsTourRunning(false);
  };

  return (
    <>
      {isOpenButtonTour && (
        <Popover className="tour-favoritos" target="fav-button" isOpen={true} placement="bottom-end">
          <button onClick={toggleButtonTour}>
            <FontAwesomeIcon icon="times" />
          </button>
          <h5>
            Favoritos <FontAwesomeIcon icon={['fas', 'star']} />
          </h5>
          <p>
            Você sabia que pode adicionar páginas aos favoritos? Isso pode facilitar seu dia a dia deixando a navegação pelo Calima muito mais rápida!
          </p>
          <p>Clique na estrela e veja!</p>
        </Popover>
      )}
      {isOpenFavTour && (
        <Popover
          container="#popover-list-favoritos .quick-access-wrapper"
          className="tour-favoritos"
          target="popover-list-favoritos .quick-access-wrapper"
          isOpen={true}
          placement="right"
        >
          <button onClick={toggleFavTour}>
            <FontAwesomeIcon icon="times" />
          </button>
          <h5>
            Favoritos <FontAwesomeIcon icon={['fas', 'star']} />
          </h5>
          <p>Após adicionar a tela aos favoritos, você pode acessá-las a partir deste menu!</p>
        </Popover>
      )}
    </>
  );
};

export default TourFavoritos;
