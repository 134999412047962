import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ERROR, showToast } from 'summer';
import { checkUsuarioVisualizouTour, salvarUsuarioVisualizouTour } from '../../../services/ger.service';
import { Reducers } from '../../../store/ducks';
import { GlobalState } from '../../../store/ducks/global.duck';
import HeaderVideosModal from './HeaderVideosModal';

const HeaderVideosTour: FC = () => {
  const { globalParameter, currentState } = useSelector<Reducers, GlobalState>(state => state.globalReducer);
  const [openModal, setOpenModal] = useState(null);
  const history = useHistory();

  const getModuloAtual = () => {
    const url = history.location.pathname.substr(1);
    return url.substr(0, url.indexOf('/')).toUpperCase();
  };

  useEffect(
    () => {
      const modulo = getModuloAtual();
      const temUsuario = globalParameter && globalParameter.usuario;
      if (temUsuario && globalParameter.listarVideos && globalParameter.listarVideos[modulo] && modulo !== currentState) {
        checkUsuarioVisualizouTour(globalParameter.usuario.id, `VISUALIZOU_VIDEOS_${modulo}`, {
          errorFunction: message => showToast(message, ERROR),
          thenFunction: res => {
            setOpenModal(!res);
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [globalParameter, history.location.pathname]
  );

  const fechar = () => {
    setOpenModal(false);
    const modulo = getModuloAtual();
    salvarUsuarioVisualizouTour(globalParameter.usuario.id, `VISUALIZOU_VIDEOS_${modulo}`, {
      errorFunction: message => showToast(message, ERROR),
      thenFunction: () => null,
    });
  };

  return <HeaderVideosModal isOpen={openModal} onClose={fechar} />;
};

export default HeaderVideosTour;
