import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { DetailEditForm, DetailEditViewFormProps, InputGroupInline, InputType, isEmpty, isNotEmpty } from 'summer';
import { ContratoUnicidadeContratual } from '../../../../models/dtos/mfp/contratoUnicidadeContratual';
import { CATEGORIA_ENUM_VALUES } from '../../../../models/enumerated/mfp/categoriaEnum';
import EnumUtil from '../../../../utilities/enum.util';
import { ERRO_CAMPO_MSG } from './processoTrabalhista.util';

const ProcessoTrabalhistaUnicidadeContratualDetailView: FC<DetailEditViewFormProps<ContratoUnicidadeContratual>> = props => {
  const formProps = useForm<ContratoUnicidadeContratual>({ defaultValues: props.selectedItem || {}, mode: 'onBlur' });

  const validateExtras = (formData: ContratoUnicidadeContratual, _, parentObject) => {
    const erros = [];

    const { matriculaIncorporada, categoria, dataInicioTSVE } = formData;
    const { categoria: categoriaVinculo } = parentObject.vinculo;
    if (isEmpty(matriculaIncorporada)) {
      if (isEmpty(categoria)) {
        erros.push(`Categoria ${ERRO_CAMPO_MSG}`);
      } else if (categoriaVinculo === categoria) {
        erros.push(
          `Categoria informada deve ser diferente da cadastrada no vínculo (${EnumUtil.find(CATEGORIA_ENUM_VALUES, categoriaVinculo).label}).`
        );
      }

      if (isEmpty(dataInicioTSVE)) {
        erros.push(`Data de início de TSVE ${ERRO_CAMPO_MSG}`);
      }
    }

    return erros;
  };

  const blurMatricula = () => {
    if (isNotEmpty(formProps.getValues().matriculaIncorporada)) {
      formProps.setValue('categoria', null);
      formProps.setValue('dataInicioTSVE', null);
      formProps.control.reRender();
    }
  };

  const changeCategoriaData = value => {
    if (isNotEmpty(value)) {
      formProps.setValue('matriculaIncorporada', null);
    }
  };

  return (
    <DetailEditForm handleSubmit={props.handleSubmit} handleCancel={props.handleCancel} formProps={formProps} validateExtras={validateExtras}>
      <Row>
        <Col>
          <InputGroupInline
            id="matriculaIncorporada"
            label="Matrícula incorporada"
            formProps={{ ...formProps, validation: { maxLength: 30 } }}
            onBlur={blurMatricula}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGroupInline
            id="categoria"
            label="Categoria"
            formProps={formProps}
            inputDropDownProps={{ optionsProvider: CATEGORIA_ENUM_VALUES }}
            type={InputType.DROP_DOWN}
            onChange={changeCategoriaData}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGroupInline
            formProps={formProps}
            id="dataInicioTSVE"
            label="Data de início de TSVE"
            type={InputType.DATE}
            onChange={changeCategoriaData}
          />
        </Col>
      </Row>
    </DetailEditForm>
  );
};

export default ProcessoTrabalhistaUnicidadeContratualDetailView;
