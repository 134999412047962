import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { createContext, FC, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { alertManager, AlertType, ERROR, isNotEmpty, showToast } from 'summer';
import { BLOQUEAR_COM_SENHA, BLOQUEAR_TOTALMENTE } from '../models/enumerated/ger/permissoesLancamentosUsuarioEnum';
import { findCompleteEmpresaById } from '../services/ger.service';
import { Reducers } from '../store/ducks';
import { GlobalState } from '../store/ducks/global.duck';
import { validatePeriod } from '../utilities/ger.util';
import ModalSenhaAdministrador from '../views/components/modalSenhaAdministrador/ModalSenhaAdministrador';

interface LockAdminProviderHook {
  validate: (validateDate?: string | string[], blockedModule?: string, message?: string, idEmpresa?: any | any[]) => Promise<void>;
}

const LockAdminContext = createContext<LockAdminProviderHook>(null);

export const useLockAdmin = () => useContext(LockAdminContext);

export const LockAdminProvider: FC = ({ children }) => {
  const { globalParameter, currentState } = useSelector<Reducers, GlobalState>(state => state.globalReducer);

  const [modalSenha, setModalSenha] = useState(null);

  const close = () => {
    setModalSenha(null);
  };

  const existValidateDate = (validateDate, usedModule, lockUsed, idEmpresa, resolve) => {
    const datesToValidate = Array.isArray(validateDate) ? validateDate : [validateDate];

    const handleValidation = validateEmpresa => {
      const periodoBloqueio = globalParameter.usuario[`permissoes${usedModule}`].periodoBloqueioLancamento;
      const allDatesValid = datesToValidate.every(date => !validatePeriod(date, periodoBloqueio, usedModule, validateEmpresa));

      if (allDatesValid) {
        resolve();
      } else {
        lockUsed();
      }
    };

    if (idEmpresa) {
      findCompleteEmpresaById(idEmpresa, {
        errorFunction: mensagem => {
          showToast(mensagem, ERROR);
        },
        thenFunction: handleValidation,
      });
    } else {
      handleValidation(globalParameter.empresa);
    }
  };

  const existValidateDateMultEmp = (validateDate, usedModule, lockUsed, empresas, resolve) => {
    const datesToValidate = Array.isArray(validateDate) ? validateDate : [validateDate];
    const empresasInvalidas = [];

    const handleValidation = empresa => {
      const periodoBloqueio = globalParameter.usuario[`permissoes${usedModule}`].periodoBloqueioLancamento;
      const allDatesValid = datesToValidate.every(date => !validatePeriod(date, periodoBloqueio, usedModule, empresa));

      if (!allDatesValid) {
        empresasInvalidas.push(empresa);
      }
    };

    const promises = empresas.map(
      empresaId =>
        new Promise<void>((resolveEmpresa, rejectEmpresa) => {
          findCompleteEmpresaById(empresaId, {
            errorFunction: mensagem => {
              showToast(mensagem, ERROR);
              rejectEmpresa();
            },
            thenFunction: empresa => {
              handleValidation(empresa);
              resolveEmpresa();
            },
          });
        })
    );

    Promise.all(promises)
      .then(() => {
        if (empresasInvalidas.length > 0) {
          lockUsed(empresasInvalidas);
        } else {
          resolve();
        }
      })
      .catch(() => {
        lockUsed();
      });
  };

  const validate = (validateDate, blockedModule, message, idEmpresa) => {
    return new Promise<void>((resolve, reject) => {
      const usedModule = isNotEmpty(blockedModule) ? blockedModule.toUpperCase() : currentState;
      const modulePermissions = globalParameter.usuario[`permissoes${usedModule}`];

      const cancel = () => {
        reject();
        close();
      };

      const passwordLock = (empresasInvalidas?: any[]) =>
        setModalSenha(
          <ModalSenhaAdministrador onClose={close} onCancel={cancel} submit={resolve} message={message} invalidEmp={empresasInvalidas} />
        );

      const lockAlert = (empresasInvalidas?: any[]) => {
        alertManager.emit({
          message: (
            <div>
              <div className="mb-1 d-flex">
                <FontAwesomeIcon className="mr-1 mt-1" color={'#F4762D'} icon="exclamation-triangle" size="lg" />
                <span className="font-weight-bold font-size-lg">Bloqueado pelo Administrador.</span>
              </div>
              {message ? <span>{message}</span> : <span>Este processo está bloqueado para o usuário no período atual.</span>}
              {empresasInvalidas && (
                <>
                  <br />
                  <span>
                    As seguintes empresas estão fora do período de bloqueio deste usuário:{' '}
                    <strong>{empresasInvalidas.map(emp => emp.razaoSocial).join(', ')}</strong>
                  </span>
                </>
              )}
            </div>
          ),
          onOkClick: () => {
            cancel();
          },
          type: AlertType.INFORMATION,
        });
      };

      if (modulePermissions && modulePermissions.tipoBloqueioLancamento === BLOQUEAR_COM_SENHA.value) {
        if (validateDate) {
          if (idEmpresa && idEmpresa.length > 1) {
            existValidateDateMultEmp(validateDate, usedModule, passwordLock, idEmpresa, resolve);
          } else {
            existValidateDate(validateDate, usedModule, passwordLock, idEmpresa, resolve);
          }
        } else {
          passwordLock();
        }
      } else if (modulePermissions && modulePermissions.tipoBloqueioLancamento === BLOQUEAR_TOTALMENTE.value) {
        if (validateDate) {
          if (idEmpresa && idEmpresa.length > 1) {
            existValidateDateMultEmp(validateDate, usedModule, lockAlert, idEmpresa, resolve);
          } else {
            existValidateDate(validateDate, usedModule, lockAlert, idEmpresa, resolve);
          }
        } else {
          lockAlert();
        }
      } else {
        resolve();
      }
    });
  };

  return (
    <LockAdminContext.Provider value={{ validate }}>
      {modalSenha}
      {children}
    </LockAdminContext.Provider>
  );
};
