import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Dropdown, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem, Nav, NavItem, NavLink, UncontrolledTooltip } from 'reactstrap';
import { userActions } from 'summer';
import { useCrispAction } from '../../../hooks/useCrispAction';
import { Reducers } from '../../../store/ducks';
import { abrirAlteracaoSenha } from '../../../store/ducks/global.duck';
import { redirectToLogin } from '../../../utilities/login.util';
import { abrirMinhaConta } from '../../../utilities/menu.util';
import ButtonCalimaPro from '../buttonCalimaPro/ButtonCalimaPro';
import HeaderVideosModal from './HeaderVideosModal';

export default function HeaderUserbox() {
  const {
    globalReducer: { currentState, globalParameter },
    proReducer: { isPro },
  } = useSelector<Reducers, Reducers>(state => state);

  const crispAction = useCrispAction();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [openModalVideo, setOpenModalVideo] = useState(false);

  const openModalVideos = () => {
    setOpenModalVideo(true);
  };

  const onCloseModalVideos = () => {
    setOpenModalVideo(false);
  };

  const logout = () => {
    toggle();
    dispatch(userActions.logout());
    redirectToLogin();
  };

  const openMyAccount = () => {
    toggle();
    abrirMinhaConta(globalParameter);
  };

  const alterarSenha = () => {
    toggle();
    dispatch(abrirAlteracaoSenha());
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {crispAction.modal}
      <HeaderVideosModal isOpen={openModalVideo} onClose={onCloseModalVideos} />
      <Dropdown isOpen={isOpen} toggle={toggle} className="user-box position-relative">
        <DropdownToggle className="p-0 text-left align-items-center dropdown-toggle-user">
          <div id="user-dropdown-config" className="btn btn-pill btn-outline-dark d-40 p-0 settings-mobile">
            <FontAwesomeIcon icon={['fas', 'user']} size="lg" />
          </div>
        </DropdownToggle>
        <DropdownMenu right={true} className="dropdown-menu-lg overflow-hidden p-0">
          <ListGroup flush={true} className="text-left bg-transparent">
            <ListGroupItem className="rounded-top">
              <Nav pills={true} className="nav-neutral-primary flex-column">
                <NavItem>
                  <span style={{ fontWeight: 'bold' }}>{globalParameter.usuario.nomeCompleto}</span>
                  <br />
                  <span style={{ color: '#7a7b97' }}>{globalParameter.usuario.email}</span>
                </NavItem>
              </Nav>
            </ListGroupItem>
            <ListGroupItem>
              <Nav pills={true} className="nav-neutral-primary flex-column">
                {globalParameter.listarVideos && globalParameter.listarVideos[currentState] && (
                  <NavItem className="user-apps-mobile">
                    <NavLink onClick={openModalVideos}>
                      Vídeos
                      <FontAwesomeIcon icon={['fas', 'play']} />
                    </NavLink>
                  </NavItem>
                )}
                <NavItem className="user-apps-mobile">
                  <NavLink onClick={crispAction.onClick}>
                    Suporte
                    <FontAwesomeIcon icon={['fas', 'comment-alt']} />
                  </NavLink>
                </NavItem>
                {!isPro && (
                  <NavItem>
                    <ButtonCalimaPro label="Conheça o" />
                  </NavItem>
                )}
                <NavItem>
                  <NavLink tag={Link} onClick={toggle} to={`/${currentState.toLowerCase()}/configUsuarioEditView`}>
                    Preferências
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink onClick={alterarSenha}>Alterar Senha</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink onClick={openMyAccount}>Minha Conta</NavLink>
                </NavItem>
              </Nav>
            </ListGroupItem>
            <ListGroupItem className="rounded-bottom p-3 text-center">
              <Button onClick={logout} color="dark" size="sm" id="btnLogoutTooltip">
                Sair <FontAwesomeIcon icon="sign-out-alt" className="ml-2" />
              </Button>
              <UncontrolledTooltip target="btnLogoutTooltip" placement="bottom">
                Sair
              </UncontrolledTooltip>
            </ListGroupItem>
          </ListGroup>
        </DropdownMenu>
      </Dropdown>
    </>
  );
}
