import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Carousel, CarouselControl, CarouselIndicators, CarouselItem, Modal, ModalBody } from 'reactstrap';
import { Reducers } from '../../../store/ducks';
import { GlobalState } from '../../../store/ducks/global.duck';
import { MODULOS } from '../../../utilities/menu.util';

interface HeaderVideosModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const HeaderVideosModal: FC<HeaderVideosModalProps> = ({ isOpen, onClose }) => {
  const { currentState, globalParameter } = useSelector<Reducers, GlobalState>(state => state.globalReducer);

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const pauseVideo = () => {
    const videoFrame: any = document.getElementById(`video-${activeIndex}`);
    videoFrame.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
  };

  const onChangeVideo = isNext => () => {
    if (!animating) {
      pauseVideo();
      const items = globalParameter.listarVideos[currentState] || [];
      let nextIndex;
      if (isNext) {
        nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
      } else {
        nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
      }
      setActiveIndex(nextIndex);
    }
  };

  const goToIndex = newIndex => {
    if (!animating) {
      pauseVideo();
      setActiveIndex(newIndex);
    }
  };

  const onTransition = transition => () => {
    setAnimating(transition);
  };

  const getModuloName = () => MODULOS.find(it => it.state === currentState).name;

  return (
    globalParameter &&
    currentState && (
      <Modal isOpen={isOpen} backdrop="static" className="modal-videos" centered={true} toggle={onClose} size="xl">
        <ModalBody>
          <div className="d-flex align-items-start justify-content-between mb-2">
            <div />
            <div className="text-center">
              <h5>
                <b>
                  Bem-vindo ao <span className={`cor-modulo-${currentState.toLowerCase()}`}>{getModuloName()}</span>!
                </b>
              </h5>
              <div>Recomendamos que assista aos vídeos abaixo para que entenda melhor todas as funcionalidades deste módulo.</div>
            </div>
            <button className="close close-modal-videos" aria-label="Close" onClick={onClose}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          {globalParameter.listarVideos && globalParameter.listarVideos[currentState] && (
            <Carousel activeIndex={activeIndex} next={onChangeVideo(true)} previous={onChangeVideo(false)} interval={false}>
              <CarouselIndicators items={globalParameter.listarVideos[currentState]} activeIndex={activeIndex} onClickHandler={goToIndex} />
              {globalParameter.listarVideos[currentState].map((item, index) => (
                <CarouselItem className="video-frame" tag="div" key={item.video} onExiting={onTransition(true)} onExited={onTransition(false)}>
                  <div className="container">
                    <iframe
                      className="video"
                      id={`video-${index}`}
                      width="100%"
                      height="100%"
                      src={`${item.video.replace('watch?v=', 'embed/')}?version=3&enablejsapi=1`}
                      title={item.title}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen={true}
                    />
                  </div>
                </CarouselItem>
              ))}
              <CarouselControl direction="prev" directionText="Previous" onClickHandler={onChangeVideo(false)} />
              <CarouselControl direction="next" directionText="Next" onClickHandler={onChangeVideo(true)} />
            </Carousel>
          )}
        </ModalBody>
      </Modal>
    )
  );
};

export default HeaderVideosModal;
