import { MenuItem } from '../../../models/dtos/ger/menuItem';
import { abrirJanelaProcessos } from '../../../store/ducks/global.duck';

const gerMenu: MenuItem[] = [
  {
    icon: 'pe-7s-home',
    label: 'Home',
    to: '/ger/dashboard',
  },
  {
    content: [
      {
        label: 'Administradora',
        to: '/ger/administradoraEditView',
      },
      {
        label: 'Empresa',
        to: '/ger/empresaSearchView',
      },
      {
        label: 'Grupo de empresas',
        to: '/ger/grupoEmpresaSearchView',
      },
      {
        label: 'Responsável',
        to: '/ger/responsavelSearchView',
      },
      {
        content: [
          {
            label: 'País',
            to: '/ger/cepPaisSearchView',
          },
          {
            label: 'UF',
            to: '/ger/cepUFSearchView',
          },
          {
            label: 'Cidade',
            to: '/ger/cepLocalidadeSearchView',
          },
          {
            label: 'Bairro',
            to: '/ger/cepBairroSearchView',
          },
          {
            label: 'Logradouro',
            to: '/ger/cepLogradouroSearchView',
          },
        ],
        label: 'CEP',
      },
      {
        isVisible: parameter => parameter.usuario.administrador,
        label: 'Controle de usuário',
        to: '/ger/usuarioSearchView',
      },
      {
        content: [
          {
            label: 'E-mail',
            to: '/ger/configEmailEditView',
          },
          {
            label: 'Grupo de empresas',
            to: '/ger/grupoEmpresaConfigEditView',
          },
          {
            calimaPro: true,
            label: 'Preferências de usuário',
            to: '/ger/configUsuarioEditView',
          },
          {
            label: 'Certificado digital',
            to: '/ger/certificadoDigitalView',
          },
        ],
        label: 'Configurações',
      },
    ],
    icon: 'pe-7s-edit',
    label: 'Manutenção',
  },
  {
    content: [
      {
        label: 'Atividades do período de trabalho',
        to: '/ger/atividadesDiaTrabalhoReportView',
      },
      {
        label: 'Relação de empresas',
        to: '/ger/relacaoEmpresaReportView',
      },
      {
        label: 'Relação de grupos de empresas',
        to: '/ger/relacaoGrupoEmpresasReportView',
      },
      {
        label: 'Controle alvará localização',
        to: '/ger/controleAlvaraLocalizacaoReportView',
      },
    ],
    icon: 'pe-7s-print',
    isReportList: true,
    label: 'Relatórios',
  },
  {
    content: [
      {
        label: 'Log',
        to: '/ger/logSearchView',
      },
      {
        action: abrirJanelaProcessos,
        label: 'Processo',
      },
      {
        label: 'Ticket',
        to: '/ger/ticketSearchView',
      },
    ],
    icon: 'pe-7s-tools',
    label: 'Utilitários',
  },
];

export default gerMenu;
