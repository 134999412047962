import { saveAs } from 'file-saver';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ERROR, INFORMATION, isNotEmpty, showToast, SUCCESS, WARNING } from 'summer';
import { GlobalParameter } from '../models/dtos/ger/globalParameter';
import { Notificacao } from '../models/dtos/ger/notificacao';
import { UsuarioConfig } from '../models/dtos/ger/usuarioConfig';
import { fileDownload, fileOpen } from '../services/report.service';
import { Reducers } from '../store/ducks';
import { ARQUIVO_DIGITAL_SUCCESS } from '../utilities/constantes';

const USUARIO_CONFIG_INITIAL_STATE: Partial<UsuarioConfig> = {
  downloadRelatorios: true,
  novaAbaRelatorios: true,
};

const useNotification = () => {
  const globalParameter = useSelector<Reducers, GlobalParameter>(state => state.globalReducer.globalParameter);
  const userConfigRef = useRef<UsuarioConfig>(USUARIO_CONFIG_INITIAL_STATE);

  useEffect(() => {
    if (globalParameter.usuario && globalParameter.usuario.configuracao) {
      userConfigRef.current = globalParameter.usuario.configuracao;
    } else {
      userConfigRef.current = USUARIO_CONFIG_INITIAL_STATE;
    }
  }, [globalParameter]);

  const download = urlArquivo => {
    const isPdf = urlArquivo.substr(urlArquivo.length - 4) === '.pdf';

    if (userConfigRef.current.novaAbaRelatorios && isPdf) {
      fileOpen(urlArquivo);
    }

    if (userConfigRef.current.downloadRelatorios || !isPdf) {
      fileDownload(urlArquivo, {
        errorFunction: (mensagem: string) => {
          showToast(mensagem, ERROR);
        },
        thenFunction: (response: any) => {
          const filename = response.headers['content-disposition'].split('filename=')[1];
          saveAs(response.data, filename);
        },
      });
    }
  };

  const dispatchNotificationAction = (notification: Notificacao): void => {
    switch (notification.tipo) {
      case 'reportDownload':
        if (notification.paramMap && notification.paramMap.reportFile) {
          const { reportName, reportFile } = notification.paramMap;
          if (
            reportName !== 'Email enviado com sucesso.' &&
            reportName !== 'Relatório enviado com sucesso para o Connect.' &&
            reportName !== 'Relatório enviado com sucesso para o Connect e para o Email.'
          ) {
            const mensagemToast = { mensagem: 'O download do seu relatório acontecerá em instantes...', toastProps: SUCCESS };
            if (reportName.includes('Pendências')) {
              mensagemToast.mensagem = 'Um relatório de pendências será gerado em instantes...';
              mensagemToast.toastProps = WARNING;
            }
            showToast(mensagemToast.mensagem, mensagemToast.toastProps);
            download(reportFile);
          } else {
            showToast(reportName, SUCCESS);
          }
        } else {
          if (isNotEmpty(notification.mensagem)) {
            showToast(notification.mensagem, INFORMATION);
          }
        }
        break;
      case 'arquivoDigitalDownload':
        if (notification.paramMap && notification.paramMap.nomePadraoArquivo) {
          if (notification.paramMap.urlArquivo) {
            download(notification.paramMap.urlArquivo);
            showToast(ARQUIVO_DIGITAL_SUCCESS, SUCCESS);
          }
          if (notification.paramMap.urlArquivoAdvertencia) {
            download(notification.paramMap.urlArquivoAdvertencia);
          }
        }
        break;
      // Outros eventos disparados
      case 'mensagensNotification':
      case 'empresaFiscalPendente':
      case 'feriasPendente':
      case 'convocacaoIntermitentePendente':
      case 'avisoPrevioTrabalhado':
      case 'examesPendentes':
      case 'contratoExperienciaPendente':
      case 'solicitacao':
      case 'solicitacaoBox':
      case 'relatorioPendencias':
      case 'relatorioPendenciasReinf':
        break;
      default:
        break;
    }
  };

  return { dispatchNotificationAction, download };
};

export default useNotification;
