import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { CALIMA_WARNING, cnpjMaskFormatter, cpfMaskFormatter, isEmpty } from 'summer';
import { Reducers } from '../../../store/ducks';
import ButtonCalimaPro from '../buttonCalimaPro/ButtonCalimaPro';
import SidebarVersionModal from './SidebarVersionModal';

export default function SidebarVersionBox() {
  const {
    globalReducer: { systemVersion, isCanary, globalParameter },
    proReducer: { isPro },
  } = useSelector<Reducers, Reducers>(state => state);

  const [modal, setModal] = useState(null);
  const [emBreveDisponivel, setEmBreveDisponivel] = useState(null);

  useEffect(
    () => {
      setTimeout(() => {
        setEmBreveDisponivel(globalParameter.urlEmBreveVersao);
      }, 1000);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const registroRazaoSocialFmt = useMemo(() => {
    let result = '';
    if (globalParameter && globalParameter.acesso) {
      const { registroRazaoSocial } = globalParameter.acesso;
      result = registroRazaoSocial;
    }

    return result;
  }, [globalParameter]);

  const registroCpfCnpjFmt = useMemo(() => {
    let result = '';
    if (globalParameter && globalParameter.acesso && globalParameter.acesso.registroCpfCnpj) {
      const { registroCpfCnpj } = globalParameter.acesso;
      result = registroCpfCnpj.length === 11 ? cpfMaskFormatter(registroCpfCnpj) : cnpjMaskFormatter(registroCpfCnpj);
    }

    return result;
  }, [globalParameter]);

  const closeModal = () => {
    setModal(null);
  };

  const openModalVersao = type => () => {
    setModal(<SidebarVersionModal type={type} onClose={closeModal} />);
  };

  return (
    <div className="app-sidebar--userbox p-0">
      {isCanary && (
        <div className="avatar-icon-wrapper avatar-icon-xs">
          <div className="avatar-icon rounded-circle">
            <FontAwesomeIcon icon="crow" size="lg" color={CALIMA_WARNING} />
          </div>
        </div>
      )}
      <div className={cx('userbox-details pt-2', { 'mt-3': isCanary })} style={{ width: '90%' }}>
        {!isPro && (
          <div className="w-100">
            <ButtonCalimaPro label="Conheça o" />
          </div>
        )}
        <small className="d-block text-white mt-2">Registrado para:</small>
        <small className="d-block text-white mt-2">{registroRazaoSocialFmt}</small>
        <small className="d-block text-white mt-1">{registroCpfCnpjFmt}</small>
        <small className="d-block text-white mt-1">Código: {globalParameter.acesso.codigoCliente}</small>
        <small className="d-block text-white-50 mt-3">{systemVersion}</small>
        <button
          onClick={openModalVersao('HISTORICO')}
          className={'btn btn-link'}
          style={{
            color: '#fff',
            display: 'inline-block',
            fontSize: 12,
            marginTop: 6,
            textDecoration: 'underline',
          }}
        >
          Histórico de versões <FontAwesomeIcon icon="bullhorn" className="ml-1" color="#2C87F2" />
        </button>
        {globalParameter.urlEmBreveVersao && (
          <button onClick={openModalVersao('EM_BREVE')} className={cx('btn em-breve mb-3', { hidden: isEmpty(emBreveDisponivel) })}>
            Em breve! <FontAwesomeIcon icon="bullhorn" className="ml-1" />
          </button>
        )}
        {modal}
      </div>
    </div>
  );
}
