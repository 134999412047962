import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useState } from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import HeaderVideosModal from './HeaderVideosModal';

const HeaderVideos: FC = () => {
  const [openModal, setOpenModal] = useState(false);

  const openModalVideos = () => {
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Button id="open-videos" className="btn-pill d-40 p-0 d-flex justify-content-center align-items-center" onClick={openModalVideos}>
        <FontAwesomeIcon icon={['fas', 'play']} />
        <UncontrolledTooltip placement="left" target="open-videos">
          Vídeos
        </UncontrolledTooltip>
      </Button>
      <HeaderVideosModal isOpen={openModal} onClose={onCloseModal} />
    </>
  );
};

export default HeaderVideos;
