export const REMOCAO_PROPAGANDAS = { value: '4', label: 'Remoção de Propagandas' };
export const PERSONALIZACAO_RELATORIOS = { value: '5', label: 'Personalização da Logo nos Relatórios' };
export const SUPORTE_CHAT = { value: '6', label: 'Suporte Via Chat' };
export const SUPORTE_TELEFONICO = { value: '6', label: 'Suporte Telefônico' };
export const SUPORTE_TICKET = { value: '8', label: 'Suporte Via Ticket' };
export const GERACAO_SPED_FOLHA = { value: '9', label: 'Geração Sped Folha' };
export const GERACAO_SPED_FISCAL = { value: '10', label: 'Geração Sped Fiscal' };
export const GERACAO_SPED_CONTABIL = { value: '11', label: 'Geração Sped Contábil' };
export const GERACAO_SPED_ECF = { value: '12', label: 'Geração Sped ECF' };
export const CALIMA_SITE = { value: '13', label: 'Calima Site' };
export const CALIMA_CONNECT = { value: '14', label: 'Calima Connect' };
export const CALIMA_BOX = { value: '15', label: 'Calima Box' };
export const CALIMA_ARMAZENAMENTO_EXTRA = { value: '16', label: 'Calima Armazenamento Extra 2GB' };
export const GERACAO_DIRF = { value: '17', label: 'Geração DIRF' };
export const GERACAO_RAIS = { value: '18', label: 'Geração RAIS' };
export const GERACAO_SEFIP = { value: '19', label: 'Geração SEFIP' };
export const SMS_50_CREDITOS = { value: '20', label: 'Notificações SMS' };
export const LIMITE_USUARIOS = { value: '21', label: 'Limite usuários' };
export const CALIMA_PRO = { value: '22', label: 'Calima Pro' };

export const PRINCIPAL = { value: 'ger', label: 'Principal' };
export const CONTABIL = { value: 'mcg', label: 'Contábil' };
export const FISCAL = { value: 'mlf', label: 'Fiscal' };
export const CONTROLE_PATRIMONIAL = { value: 'mcp', label: 'Controle Patrimonial' };
export const PROTOCOLO = { value: 'mpr', label: 'Protocolo' };
export const LOGIN = { value: 'login', label: 'Login' };
export const HONORARIO = { value: 'mho', label: 'Honorários' };
export const FOLHA = { value: 'mfp', label: 'Folha de Pagamento' };

export const APPLICATION_STATE_ENUM_VALUES = [
  REMOCAO_PROPAGANDAS,
  PERSONALIZACAO_RELATORIOS,
  SUPORTE_CHAT,
  SUPORTE_TELEFONICO,
  SUPORTE_TICKET,
  GERACAO_SPED_FOLHA,
  GERACAO_SPED_FISCAL,
  GERACAO_SPED_CONTABIL,
  GERACAO_SPED_ECF,
  CALIMA_SITE,
  CALIMA_CONNECT,
  CALIMA_BOX,
  CALIMA_ARMAZENAMENTO_EXTRA,
  GERACAO_DIRF,
  GERACAO_RAIS,
  GERACAO_SEFIP,
  SMS_50_CREDITOS,
  LIMITE_USUARIOS,
  CALIMA_PRO,
];

export const APPLICATION_STATE_MODULOS_ENUM_VALUES = [PRINCIPAL, CONTABIL, FISCAL, CONTROLE_PATRIMONIAL, PROTOCOLO, LOGIN, HONORARIO, FOLHA];
