import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { AutoCompleteOff, Box, DetailEditForm, DetailEditViewFormProps, InputGroupInline, InputType, isEmpty, refToFormat } from 'summer';
import { ContratoBaseCalculo } from '../../../../models/dtos/mfp/contratoBaseCalculo';
import { APOSENTADORIA_ESPECIAL_ENUM_VALUES } from '../../../../models/enumerated/mfp/aposentadoriaEspecialEnum';
import {
  CATEGORIA_ENUM_VALUES,
  CI_COOPERADO_COOPERATIVA_PRODUCAO,
  COOPERADO,
  TRANSPORTADOR_COOPERADO,
} from '../../../../models/enumerated/mfp/categoriaEnum';
import { SIM } from '../../../../models/enumerated/mfp/simNaoEnum';
import EnumUtil from '../../../../utilities/enum.util';
import { ERRO_CAMPO_MSG, MAX_MONEY_VALUE, verificarItensEmBranco } from './processoTrabalhista.util';

const ProcessoTrabalhistaBaseCalculoDetailView: FC<DetailEditViewFormProps<ContratoBaseCalculo>> = props => {
  const formProps = useForm<ContratoBaseCalculo>({ defaultValues: props.selectedItem || {}, mode: 'onBlur' });

  const validateExtras = (formData: ContratoBaseCalculo, _, parentObject) => {
    const erros = [];
    const { referencia, categoria, vrPrevidencia, vrFGTSNaoDeclaradoSefipEsocial, vrFGTSDeclaradoSefip, grauExposicao } = formData;
    const { referenciaInicial, referenciaFinal, indicativoCategoria, vinculo } = parentObject;

    if (referencia > referenciaFinal || referencia < referenciaInicial) {
      erros.push(`Referência deve estar dentro do período (${refToFormat(referenciaInicial)} até ${refToFormat(referenciaFinal)}).`);
    }

    const codigoESocialCategoria = EnumUtil.find(CATEGORIA_ENUM_VALUES, vinculo.categoria).extraFields.codigoEsocial;
    const startsWith = codigoESocialCategoria.startsWith('1') || codigoESocialCategoria.startsWith('2') || codigoESocialCategoria.startsWith('3');
    const categoriasObrigatorias = startsWith || [COOPERADO, TRANSPORTADOR_COOPERADO, CI_COOPERADO_COOPERATIVA_PRODUCAO].includes(vinculo.categoria);
    if (isEmpty(grauExposicao) && codigoESocialCategoria.length === 3 && categoriasObrigatorias) {
      erros.push(`Grau de exposição a agentes nocivos ${ERRO_CAMPO_MSG}`);
    }

    if (indicativoCategoria === SIM.value) {
      const mudancaCategoria = [
        { label: 'Categoria', value: categoria },
        { label: 'Remuneração a ser considerada para fins previdenciários', value: vrPrevidencia },
      ];
      verificarItensEmBranco(erros, mudancaCategoria);
    }

    const infoFGTS = [
      { label: 'Valor do FGTS não declarado em SEFIP e eSocial', value: vrFGTSNaoDeclaradoSefipEsocial },
      { label: 'Valor do FGTS declarado apenas em SEFIP', value: vrFGTSDeclaradoSefip },
    ];
    verificarItensEmBranco(erros, infoFGTS, true, 'Informações para geração de guia:');

    return erros;
  };

  return (
    <DetailEditForm handleSubmit={props.handleSubmit} handleCancel={props.handleCancel} formProps={formProps} validateExtras={validateExtras}>
      <Row>
        <Col xs={12} md={3}>
          <AutoCompleteOff>
            <InputGroupInline
              formProps={{ ...formProps, validation: { required: true } }}
              id="referencia"
              label="Referência"
              type={InputType.REFERENCE}
            />
          </AutoCompleteOff>
        </Col>
        <Col>
          <InputGroupInline
            id="grauExposicao"
            label="Grau de exposição a agentes nocivos"
            formProps={formProps}
            inputDropDownProps={{ optionsProvider: APOSENTADORIA_ESPECIAL_ENUM_VALUES }}
            type={InputType.DROP_DOWN}
          />
        </Col>
      </Row>
      <Box title="Contribuição previdenciária e FGTS" className="mt-0 mb-2">
        <Row>
          <Col>
            <InputGroupInline
              formProps={{ ...formProps, validation: { required: true, max: MAX_MONEY_VALUE } }}
              id="vrContribuicaoMensal"
              label="Contribuição previdenciária sobre a remuneração mensal"
              type={InputType.MONEY}
            />
          </Col>
          <Col>
            <InputGroupInline
              formProps={{ ...formProps, validation: { required: true, max: MAX_MONEY_VALUE } }}
              id="vrContribuicao13"
              label="Contribuição previdenciária sobre a remuneração 13º"
              type={InputType.MONEY}
            />
          </Col>
        </Row>
      </Box>
      <Box title="Informações referentes a bases de cálculo de FGTS" className="mt-0 mb-2">
        <Row>
          <Col>
            <InputGroupInline
              formProps={{ ...formProps, validation: { max: MAX_MONEY_VALUE } }}
              id="vrFGTSNaoDeclaradoSefipEsocial"
              label="Valor do FGTS não declarado em SEFIP e eSocial"
              type={InputType.MONEY}
            />
          </Col>
          <Col>
            <InputGroupInline
              formProps={{ ...formProps, validation: { max: MAX_MONEY_VALUE, required: true } }}
              id="vrFGTSDeclaradoSefip"
              label="Valor do FGTS declarado apenas em SEFIP"
              type={InputType.MONEY}
            />
          </Col>
          <Col>
            <InputGroupInline
              formProps={{ ...formProps, validation: { max: MAX_MONEY_VALUE } }}
              id="vrBcFGTSDecAnt"
              label="Valor do FGTS declarado anterior ao eSocial"
              type={InputType.MONEY}
            />
          </Col>
        </Row>
      </Box>
      <Box title="Reconhecimento de mudança de código de categoria do trabalhador" className="mt-0 mb-3">
        <Row>
          <Col>
            <InputGroupInline
              id="categoria"
              label="Categoria"
              formProps={formProps}
              inputDropDownProps={{ optionsProvider: CATEGORIA_ENUM_VALUES }}
              type={InputType.DROP_DOWN}
            />
          </Col>
          <Col>
            <InputGroupInline
              formProps={{ ...formProps, validation: { max: MAX_MONEY_VALUE } }}
              id="vrPrevidencia"
              label="Remuneração a ser considerada para fins previdenciários"
              type={InputType.MONEY}
            />
          </Col>
        </Row>
      </Box>
    </DetailEditForm>
  );
};

export default ProcessoTrabalhistaBaseCalculoDetailView;
