import { UseFormProps } from 'summer';
import { CepLogradouro } from '../models/dtos/ger/cepLogradouro';

export interface CepManagerOptions {
  bairro?: boolean | string;
  cep?: boolean | string;
  localidade?: boolean | string;
  nome?: boolean | string;
  titulo?: boolean | string;
  uf?: boolean | string;
}

export interface CepManagerProps {
  manage: (logradouro: CepLogradouro) => void;
  reset: () => void;
}

const useCepManager = (formProps: UseFormProps, props: CepManagerOptions): CepManagerProps => {
  const getProp = (name: string): string => {
    let result = null;

    if (props[name] !== undefined) {
      result = typeof props[name] === 'boolean' ? name : props[name];
    }

    return result;
  };

  const fillForm = (logradouro: CepLogradouro) => {
    const keyList = Object.keys(props);
    keyList.forEach(key => {
      const name = getProp(key);
      if (name) {
        formProps.setValue(name, logradouro[key]);
      }
    });
  };

  const clearForm = () => {
    fillForm({
      bairro: null,
      cep: null,
      localidade: null,
      nome: null,
      titulo: null,
      uf: null,
    });
  };

  return {
    manage: (logradouro: CepLogradouro) => {
      if (logradouro) {
        fillForm(logradouro);
      } else {
        clearForm();
      }
    },
    reset: () => clearForm(),
  };
};

export default useCepManager;
