import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import {
  alertManager,
  AlertType,
  cepFormatter,
  ColumnsProps,
  DefaultSortProps,
  DetailManagerForm,
  DetailManagerFormProps,
  ERROR,
  InputGroupInline,
  InputType,
  OrderType,
  persist,
  showToast,
  SUCCESS,
  userActions,
} from 'summer';
import { Administradora, CLASS_NAME } from '../../../../models/dtos/ger/administradora';
import { AdministradoraMHO } from '../../../../models/dtos/mho/administradoraMHO';
import { atualizarGlobalParameter } from '../../../../services/ger.service';
import { Reducers } from '../../../../store/ducks';
import { GlobalState, setGlobalParameter } from '../../../../store/ducks/global.duck';
import { redirectToLogin } from '../../../../utilities/login.util';
import { tipoEnderecoFormatter } from '../../../../utilities/tipoEnderecoFormatter';
import AdministradoraEnderecoEditView from '../administradora/AdministradoraEnderecoEditView';

const ValidarEnderecoAdm: FC = () => {
  const { globalParameter } = useSelector<Reducers, GlobalState>(state => state.globalReducer);
  const { administradora } = globalParameter;

  const formProps = useForm<Administradora>({ defaultValues: { ...administradora, enderecos: [] }, mode: 'onBlur' });

  const [isOpenEnderecos, setIsOpenEnderecos] = useState(false);

  const dispatch = useDispatch();

  useEffect(
    () => {
      if (administradora && administradora.enderecos.length <= 0 && formProps.getValues().enderecos.length <= 0) {
        setIsOpenEnderecos(true);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpenEnderecos]
  );

  const validarEndereco = () => {
    let existeEndereco;

    if (formProps.getValues().enderecos.length <= 0) {
      alertManager.emit({
        message: 'Cadastre um endereço para continuar!',
        type: AlertType.ERROR,
      });
      existeEndereco = false;
    } else {
      existeEndereco = true;
    }

    return existeEndereco;
  };

  const hideScreen = () => {
    let adm: Administradora;

    if (!!administradora.administradoraMHO) {
      adm = { ...administradora, ...formProps.getValues() };
    } else {
      const admMHO: AdministradoraMHO = { administradora, referencia: globalParameter.empresaMLF.referenciaAtual, aliquotaIss: 0 };
      adm = { ...administradora, ...formProps.getValues(), administradoraMHO: admMHO };
    }

    if (validarEndereco()) {
      persist(adm, CLASS_NAME, {
        errorFunction: mensagem => {
          showToast(mensagem, ERROR);
        },
        thenFunction: () => {
          afterPersist();
          setIsOpenEnderecos(false);
          const mensagemToast = { mensagem: 'O endereço da administradora foi salvo com sucesso!', toastProps: SUCCESS };
          showToast(mensagemToast.mensagem, mensagemToast.toastProps);
        },
      });
    }
  };

  const afterPersist = () => {
    atualizarGlobalParameter(globalParameter.usuario, {
      errorFunction: mensagem => {
        showToast(mensagem, ERROR);
      },
      thenFunction: result => {
        dispatch(setGlobalParameter(result));
      },
    });
  };

  const close = () => {
    dispatch(userActions.logout());
    redirectToLogin();
  };

  const defaultSortEnderecoProps: DefaultSortProps[] = [
    {
      dataField: 'logradouro',
      order: OrderType.ASC,
    },
  ];

  const columnsAdministradoraEndereco: ColumnsProps[] = [
    {
      dataField: 'logradouro',
      text: 'Logradouro',
    },
    {
      dataField: 'numero',
      text: 'Número',
    },
    {
      dataField: 'cep',
      formatter: cepFormatter,
      text: 'CEP',
    },
    {
      dataField: 'bairro.bairro',
      text: 'Bairro',
    },
    {
      dataField: 'localidade.nome',
      text: 'Cidade',
    },
    {
      dataField: 'tipoEnderecoPrincipal',
      formatter: tipoEnderecoFormatter,
      text: 'Tipo de Endereço',
    },
  ];

  const administradoraEnderecoFormProps: DetailManagerFormProps = {
    columns: columnsAdministradoraEndereco,
    detailView: AdministradoraEnderecoEditView,
    formProps,
    propName: 'enderecos',
    title: 'Endereços',
  };

  return (
    <Modal size="xl" contentClassName="modal-content-full" centered={true} backdrop="static" isOpen={isOpenEnderecos}>
      <ModalBody>
        <div className="alert d-flex align-items-center pl-2 align-content-center alert-warning alert-dismissible fade show mb-0" role="alert">
          <span className="font-size-lg d-block d-40 mr-2 text-center">
            <FontAwesomeIcon icon="exclamation-triangle" />
          </span>
          <span>
            <strong className="d-block">Atenção!</strong>Administradora abaixo não possui um endereço cadastrado. Para o funcionamento correto do
            sistema é necessário que um endereço seja cadastrado. Para continuar acessando o sistema adicione um endereço logo abaixo.
          </span>
        </div>
        <Row className="mt-3">
          <Col>
            <InputGroupInline
              disabled={true}
              formProps={{ ...formProps, validation: { required: true, maxLength: 100 } }}
              id="razaoSocial"
              label="Razão Social"
            />
          </Col>
          <Col>
            <InputGroupInline disabled={true} formProps={{ ...formProps, validation: { maxLength: 100 } }} id="nomeFantasia" label="Nome Fantasia" />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputGroupInline disabled={true} formProps={formProps} id="cpf" label="CPF" type={InputType.CPF} />
          </Col>
          <Col>
            <InputGroupInline disabled={true} formProps={formProps} id="cnpj" label="CNPJ" type={InputType.CNPJ} />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <DetailManagerForm
              defaultSortDirection={OrderType.DESC}
              defaultSorted={defaultSortEnderecoProps}
              detailViewSize="large"
              {...administradoraEnderecoFormProps}
              keyField="rowIndex"
              title={['Manutação', 'Administradora', 'Endereço']}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className="justify-content-end">
        <Button onClick={close} color="danger" size="sm">
          <FontAwesomeIcon icon={['fas', 'times']} /> Fechar
        </Button>
        <Button onClick={hideScreen} color="success" size="sm">
          <FontAwesomeIcon icon={['fas', 'cog']} /> Salvar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ValidarEnderecoAdm;
