import { createActions, handleActions } from 'redux-actions';

export interface MultiPageState {
  page?: any;
  showMessage?: boolean;
}

const initialState: MultiPageState = {
  page: null,
  showMessage: true,
};

export const actions = createActions({
  CLEAR_PAGE: () => null,
  SHOW_PAGE: (page, showMessage = true) => ({ page, showMessage }),
});

export const reducer = handleActions<MultiPageState>(
  {
    [actions.clearPage.toString()]: () => ({ page: null, showMessage: true }),
    [actions.showPage.toString()]: (state: MultiPageState, action: any) => ({
      ...state,
      ...action.payload,
    }),
  },
  initialState
);
