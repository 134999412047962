import { Client } from '@stomp/stompjs';
import axios from 'axios';
import { alertManager, AlertType, dec, deleteFromLocalStorage, enc, environment, getRandomUUID, userActions } from 'summer';
import { GlobalParameter } from '../models/dtos/ger/globalParameter';
import { MODULO_PREFERENCIAL, ULTIMA_TELA_ACESSADA, ULTIMO_MODULO_ACESSADO } from '../models/enumerated/ger/UltimaPaginaAcessadaEnum';
import { LOCAL_STORAGE_UNIQUE_USER_HASH } from './constantes';

const PING_TIMEOUT = 2000;
let currentWebsocketTimeout = null;

const buscarVersao = async url => {
  const result = await axios.get(`${url}/build.json`).then(res => res.data);
  return result.build;
};

export const validarVersaoFront = async () => {
  let result = true;
  if (environment.production) {
    try {
      const versaoFront = await buscarVersao(window.location.origin);
      const versaoBack = await buscarVersao(environment.siteURL);
      result = versaoFront === versaoBack;
    } catch (e) {
      result = false;
    }
  }
  return result;
};

export const redirectToLogin = (messageType?: string) => {
  deleteFromLocalStorage('u');
  const m = p => (messageType ? `${p}msg=${messageType}` : '');
  window.location.href = environment.production ? `https://calima.app/?reset=1${m('&')}` : `http://localhost:3000/${m('?')}`;
};

const writeNewUserHash = (usuario: string) => {
  const hash = getRandomUUID();
  window.localStorage.setItem(LOCAL_STORAGE_UNIQUE_USER_HASH, enc(JSON.stringify({ usuario, hash })));
  return hash;
};

export const genUniqueUserHash = (usuario: string) => {
  const currentHash = window.localStorage.getItem(LOCAL_STORAGE_UNIQUE_USER_HASH);
  if (currentHash) {
    const decCurrentHash = JSON.parse(dec(currentHash));
    if (decCurrentHash.usuario !== usuario) {
      writeNewUserHash(usuario);
    }
  } else {
    writeNewUserHash(usuario);
  }
};

export const getUniqueUserHash = () => {
  let result = null;
  const currentHash = window.localStorage.getItem(LOCAL_STORAGE_UNIQUE_USER_HASH);

  if (currentHash) {
    const decCurrentHash = JSON.parse(dec(currentHash));
    result = decCurrentHash.hash;
  }

  return result;
};

export const checkUniqueUserSession = message => {
  let result = true;

  const server = JSON.parse(message);
  const currentHash = window.localStorage.getItem(LOCAL_STORAGE_UNIQUE_USER_HASH);

  if (currentHash) {
    const decCurrentHash = JSON.parse(dec(currentHash));
    result =
      decCurrentHash.usuario !== server.usuario ||
      decCurrentHash.hash === server.hash ||
      server.ignore.split(',').indexOf(decCurrentHash.hash) !== -1;
  }

  return result;
};

export const efetuarLogoutWithMsg = (msg, dispatch) => {
  if (environment.production) {
    alertManager.emit({
      message: msg,
      onOkClick: () => {
        dispatch(userActions.logout());
        redirectToLogin();
      },
      type: AlertType.INFORMATION,
    });
  }
};

export const pingSocketBeforeLogout = (client: Client, dispatch) => {
  if (!currentWebsocketTimeout) {
    let pingCount = 0;
    const ping = () => {
      if (!client.connected) {
        if (pingCount < 3) {
          currentWebsocketTimeout = setTimeout(ping, PING_TIMEOUT);
          pingCount++;
        } else {
          currentWebsocketTimeout = null;
          efetuarLogoutWithMsg('Você foi desconectado por inatividade. Para a sua segurança, por favor execute o login novamente.', dispatch);
        }
      }
    };
    currentWebsocketTimeout = setTimeout(ping, PING_TIMEOUT);
  }
};

export const urlAcessoLogin = (globalParameter: GlobalParameter) => {
  let urlAcesso = '/ger/dashboard';
  if (globalParameter.ultimaPaginaAcessada) {
    switch (globalParameter.usuario.configuracao.paginaInicial) {
      case MODULO_PREFERENCIAL.value:
        urlAcesso = `/${globalParameter.usuario.configuracao.moduloPreferencial.toLowerCase()}/dashboard`;
        break;
      case ULTIMO_MODULO_ACESSADO.value:
        urlAcesso = `/${globalParameter.ultimaPaginaAcessada.modulo.toLowerCase()}/dashboard`;
        break;
      case ULTIMA_TELA_ACESSADA.value:
        urlAcesso = `/${globalParameter.ultimaPaginaAcessada.modulo.toLowerCase()}/${globalParameter.ultimaPaginaAcessada.itemMenu}`;
        break;
      default:
        break;
    }
  }
  return urlAcesso;
};
