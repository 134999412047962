import Bowser from 'bowser';
import cx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalHeader } from 'reactstrap';
import { alertManager, AlertType, dec, ERROR, InputGroupInline, InputType, showToast } from 'summer';
import { deveExibirPesquisaNPS, salvarPesquisaNPS } from '../../../../services/ger.service';
import { Reducers } from '../../../../store/ducks';
import { getClientIP } from '../../../../utilities/navigator.util';

import './ModalPesquisaNPS.css';

const CORES = ['#B72325', '#D7212A', '#EB5327', '#EA7023', '#F6A725', '#FDC62B', '#EBDB0B', '#E4E044', '#C2D234', '#AEC93C', '#66B44F'];

const ModalPesquisaNPS: FC = () => {
  const [selected, setSelected] = useState<number | null>(null);
  const [exibir, setExibir] = useState(false);
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    globalReducer: {
      globalParameter: { usuario, acesso },
    },
    proReducer: { isPro },
  } = useSelector<Reducers, Reducers>(state => state);

  const formProps = useForm({ mode: 'onBlur' });

  useEffect(
    () => {
      deveExibirPesquisaNPS(dec(usuario.login), {
        errorFunction: mensagem => showToast(mensagem, ERROR),
        thenFunction: result => {
          if (!!result) {
            setExibir(true);
          }
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const select = (index, e) => {
    e.preventDefault();
    if (index > 6 && selected <= 6) {
      formProps.setValue('comentario', '');
    }
    setSelected(index);
  };

  const close = () => setExibir(false);

  const getColor = (index, cor) => (selected === null || index === selected ? cor : '#C0C3CA');

  const submit = formData => {
    if (selected === null) {
      alertManager.emit({
        message: 'Você precisa informar a nota desejada',
        type: AlertType.WARNING,
      });
    } else if (selected <= 6 && !formData.comentario) {
      alertManager.emit({
        message: 'Nos conte o motivo pelo qual deu esta nota',
        type: AlertType.WARNING,
      });
    } else {
      setLoading(true);
      const browserData = Bowser.parse(window.navigator.userAgent);
      getClientIP().then(ip => {
        salvarPesquisaNPS(
          {
            clienteFree: acesso.gratuito,
            clientePro: isPro,
            comentario: formData.comentario,
            ip,
            navegador: `${browserData.browser.name} v${browserData.browser.version}`,
            nota: selected,
            resolucaoJanela: `${window.innerWidth}x${window.innerHeight}`,
            resolucaoMonitor: `${window.screen.width}x${window.screen.height}`,
            sistemaOperacional: browserData.os.name,
            usuario: dec(usuario.login),
          },
          {
            errorFunction: mensagem => {
              showToast(mensagem, ERROR);
              setLoading(false);
              setExibir(false);
            },
            thenFunction: () => {
              setLoading(false);
              setDone(true);
              setTimeout(() => setExibir(false), 2000);
            },
          }
        );
      });
    }
  };

  const getButtonLabel = () =>
    loading ? (
      <>
        <span className="btn-wrapper--label">Aguarde...</span>
        <span className="btn-wrapper--icon spinner-border spinner-border-sm" role="status" aria-hidden="true" />
      </>
    ) : (
      'Enviar'
    );

  return (
    <Modal backdrop="static" isOpen={exibir} centered={true} size="lg" className={cx('modal-pesquisa-nps', { 'modal-done': done })}>
      {done ? (
        <h4 className="done">Muito obrigado!</h4>
      ) : (
        <>
          <ModalHeader toggle={close} />
          <div className="pesquisa-nps">
            <h2>Queremos te ouvir</h2>
            <h4>Como você avalia o nosso sistema em uma escala de 0 a 10?</h4>
            <div className="escala">
              {CORES.map((cor, index) => (
                <button key={index} style={{ background: getColor(index, cor) }} onClick={select.bind(null, index)}>
                  {index}
                </button>
              ))}
            </div>
            {selected !== null && selected <= 6 && (
              <div className="box-comentario">
                <InputGroupInline
                  id="comentario"
                  formProps={formProps}
                  label="Nos conte o motivo pelo qual deu esta nota"
                  type={InputType.TEXT_AREA}
                  inputProps={{ placeholder: 'Escreva aqui', autoFocus: true }}
                />
              </div>
            )}
            <Button className={cx('submit', { 'btn-spinner': loading })} color="primary" disabled={loading} onClick={formProps.handleSubmit(submit)}>
              {getButtonLabel()}
            </Button>
            <button onClick={close} className="responder-depois">
              Responder mais tarde
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default ModalPesquisaNPS;
