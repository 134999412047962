import React, { createContext, FC, useContext, useState } from 'react';

export enum QuickAccessType {
  FAV = 'FAV',
  REC = 'REC',
}

interface QuickAccessProviderCommon {
  toggle: () => void;
  isSingle: boolean;
}

interface QuickAccessProviderProps extends QuickAccessProviderCommon {
  type: QuickAccessType;
}

interface QuickAccessProviderHook extends QuickAccessProviderCommon {
  toggleFav: (to: string) => void;
  isPendingRemoval: (to: string) => boolean;
  isFav: boolean;
  removeFavList: string[];
  clear: () => void;
}

const QuickAccessContext = createContext<QuickAccessProviderHook>(null);

export const useQuickAccess = () => useContext(QuickAccessContext);

export const QuickAccessProvider: FC<QuickAccessProviderProps> = ({ children, toggle, type, isSingle }) => {
  const [removeFavList, setRemoveFavList] = useState<string[]>([]);

  const toggleFav = (to: string) =>
    setRemoveFavList(p => {
      if (p.includes(to)) {
        p.splice(p.indexOf(to), 1);
      } else {
        p.push(to);
      }

      return [...p];
    });

  const isPendingRemoval = to => removeFavList.includes(to);
  const clear = () => setRemoveFavList([]);

  return (
    <QuickAccessContext.Provider value={{ toggle, toggleFav, clear, isPendingRemoval, removeFavList, isFav: type === QuickAccessType.FAV, isSingle }}>
      {children}
    </QuickAccessContext.Provider>
  );
};
