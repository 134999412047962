import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ProcessBar } from 'summer';
import GrupoRelatorioIS from '../../../components/ger/grupoRelatorioIS/GrupoRelatorioIS';

interface GrupoRelatorioModalViewProps {
  toggle: (grupo: any) => void;
}

const GrupoRelatorioModalView: FC<GrupoRelatorioModalViewProps> = props => {
  const formProps = useForm({ mode: 'onBlur' });

  const toggle = () => props.toggle(null);
  const onSubmit = formData => props.toggle(formData.grupo);

  return (
    <Modal centered={true} backdrop="static" toggle={toggle} isOpen={true}>
      <ModalHeader toggle={toggle}>Adicionar Relatório ao Grupo</ModalHeader>
      <ModalBody>
        <GrupoRelatorioIS formProps={{ ...formProps, validation: { required: true } }} id="grupo" />
      </ModalBody>
      <ModalFooter>
        <ProcessBar handleSubmit={formProps.handleSubmit(onSubmit)} handleClose={toggle} />
      </ModalFooter>
    </Modal>
  );
};

export default GrupoRelatorioModalView;
