import React, { FC } from 'react';
import { InputGroupInline, InputProps, InputType } from 'summer';
import { CepManagerProps } from '../../../../hooks/useCepManager';
import { SearchFilterProps } from '../../../../hooks/useSearchFilter';
import { CLASS_NAME as CLASS_NAME_BAIRRO } from '../../../../models/dtos/ger/cepBairro';
import { CLASS_NAME as CLASS_NAME_LOCALIDADE } from '../../../../models/dtos/ger/cepLocalidade';
import { CepLogradouro, CLASS_NAME } from '../../../../models/dtos/ger/cepLogradouro';
import { CLASS_NAME as CLASS_NAME_UF } from '../../../../models/dtos/ger/cepUF';
import { buscarLogradouroPorCep } from '../../../../services/ger.service';

export interface CepLogradouroProps {
  cepManager?: CepManagerProps;
  searchFilter?: SearchFilterProps;
}

const CepLogradouroIS: FC<InputProps & CepLogradouroProps> = props => {
  const searchExpressionsFull = defaultExpressions => {
    let expressions = [];

    if (props.searchFilter) {
      const bairro = props.searchFilter.getFilter(CLASS_NAME_BAIRRO);
      const localidade = props.searchFilter.getFilter(CLASS_NAME_LOCALIDADE);
      const uf = props.searchFilter.getFilter(CLASS_NAME_UF);
      if (bairro) {
        expressions = [
          {
            fieldName: 'bairro.id',
            value: bairro.id,
          },
        ];
      } else if (localidade) {
        expressions = [
          {
            fieldName: 'localidade.id',
            value: localidade.id,
          },
        ];
      } else if (uf) {
        expressions = [
          {
            fieldName: 'uf.id',
            value: uf.id,
          },
        ];
      }
    }

    if (expressions.length > 0) {
      expressions[0].expressions = defaultExpressions;
    } else {
      expressions.push(...defaultExpressions);
    }

    return expressions;
  };

  const onChangeHandle = (result: CepLogradouro) => {
    if (props.cepManager) {
      props.cepManager.manage(result);
    }
    if (props.searchFilter) {
      props.searchFilter.setFilter(CLASS_NAME, result);
    }
    if (props.onChange) {
      props.onChange(result);
    }
  };

  return (
    <InputGroupInline
      {...props}
      onChange={onChangeHandle}
      inputSearchProps={{
        ...props.inputSearchProps,
        columns: [
          {
            dataField: 'nome',
            text: 'Nome',
          },
          {
            dataField: 'bairro.bairro',
            text: 'Bairro',
          },
          {
            dataField: 'cep',
            text: 'CEP',
          },
        ],
        customSearch: buscarLogradouroPorCep,
        dtoClassName: CLASS_NAME,
        searchExpressionsFull,
        searchViewId: 'CepLogradouroSearchView',
      }}
      type={InputType.INPUT_SEARCH}
    />
  );
};

export default CepLogradouroIS;
