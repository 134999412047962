export const NAO_ENSEJADOR = { label: 'Não ensejador de aposentadoria especial', value: 'NAO_ENSEJADOR' };
export const ENSEJADOR_12 = {
  label: 'Ensejador de aposentadoria especial - FAE15_12% (15 anos de contribuição e alíquota de 12%)',
  value: 'ENSEJADOR_12',
};
export const ENSEJADOR_9 = {
  label: 'Ensejador de aposentadoria especial - FAE20_09% (20 anos de contribuição e alíquota de 9%)',
  value: 'ENSEJADOR_9',
};
export const ENSEJADOR_6 = {
  label: 'Ensejador de aposentadoria especial - FAE25_06% (25 anos de contribuição e alíquota de 6%)',
  value: 'ENSEJADOR_6',
};

export const APOSENTADORIA_ESPECIAL_ENUM_VALUES = [NAO_ENSEJADOR, ENSEJADOR_12, ENSEJADOR_9, ENSEJADOR_6];
