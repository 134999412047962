import { DependencyList, useEffect, useRef } from 'react';
import { Key } from 'summer';
import { findLastUl, findNextUl, findParentUl, findPrevUl } from '../utilities/dom.util';

const CLASS_SELECTED = 'selected';
const HEIGHT_SCROLL = -1000;

interface UseKeyboardNavigationProps {
  enableLeftRight: boolean;
  selector: string;
}

interface UseKeyboardNavigationActions {
  setFirstItemSelected: () => void;
}

export const useKeyboardNavigation = (
  { selector, enableLeftRight }: UseKeyboardNavigationProps,
  deps: DependencyList
): UseKeyboardNavigationActions => {
  const currentItem = useRef(0);

  useEffect(
    () => {
      if (deps.every(it => it)) {
        setFirstItemSelected();
        document.querySelector('body').style.overflow = 'hidden';
        document.addEventListener('keydown', navegateOptionsMenu);
      }

      return () => {
        document.querySelector('body').style.overflow = 'auto';
        document.removeEventListener('keydown', navegateOptionsMenu);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  );

  const getItems = () => Array.from(document.querySelectorAll(`${selector} li a`).values()) as HTMLLinkElement[];

  const setFirstItemSelected = () => {
    const listItems = getItems();
    if (listItems.length > 0) {
      select(listItems, 0);
      scroll(true);
    }
  };

  const getSelected = () => document.querySelector(`${selector} li a.${CLASS_SELECTED}`);

  const select = (listItems, newIndex) => {
    currentItem.current = newIndex;

    const selected = getSelected();
    if (selected) {
      selected.classList.remove(CLASS_SELECTED);
    }

    if (listItems[currentItem.current]) {
      listItems[currentItem.current].classList.add(CLASS_SELECTED);
    }
  };

  const scroll = (toTop: boolean = false) => {
    const itemSelected: any = getSelected();
    const classFmt = selector.substring(1).replace('popover-', '');

    let scrollDiv = document.querySelector(`.popover-${classFmt} > .popover`);
    if (
      itemSelected.parentNode &&
      itemSelected.parentNode.parentNode &&
      itemSelected.parentNode.parentNode.classList.contains('scrollbar-container')
    ) {
      scrollDiv = itemSelected.parentNode.parentNode;
    }

    scrollDiv.scrollTop = toTop ? HEIGHT_SCROLL : itemSelected.offsetTop - 150;
  };

  const handleDown = listItems => {
    select(listItems, currentItem.current < listItems.length - 1 ? currentItem.current + 1 : 0);
    scroll(currentItem.current === 0);
  };

  const handleUp = listItems => {
    select(listItems, currentItem.current > 0 ? currentItem.current - 1 : listItems.length - 1);
    scroll();
  };

  const findAndSelect = (listItems, ul) => {
    const firstItem = ul.querySelector('li a');
    if (firstItem) {
      const index = listItems.findIndex(it => it === firstItem);
      if (index > -1) {
        select(listItems, index);
      }
    }
  };

  const validateNotSingle = ul => ul.parentElement.querySelectorAll(':scope > ul').length > 1;

  const handleLeft = listItems => {
    if (enableLeftRight) {
      const ul = findParentUl(listItems[currentItem.current]);
      if (ul) {
        const sibiling = findPrevUl(ul);
        if (sibiling) {
          findAndSelect(listItems, sibiling);
        } else {
          if (validateNotSingle(ul)) {
            findAndSelect(listItems, findLastUl(ul));
          }
        }
        scroll(true);
      }
    }
  };

  const handleRight = listItems => {
    if (enableLeftRight) {
      const ul = findParentUl(listItems[currentItem.current]);
      if (ul) {
        const sibiling = findNextUl(ul);
        if (sibiling) {
          findAndSelect(listItems, sibiling);
        } else {
          if (validateNotSingle(ul)) {
            select(listItems, 0);
          }
        }
        scroll(true);
      }
    }
  };

  const navegateOptionsMenu = event => {
    const listItems = getItems();
    if (listItems.length > 0) {
      switch (event.keyCode) {
        case Key.ENTER:
          listItems[currentItem.current].click();
          break;
        case Key.ARROW_DOWN:
          handleDown(listItems);
          break;
        case Key.ARROW_RIGHT:
          handleRight(listItems);
          break;
        case Key.ARROW_UP:
          handleUp(listItems);
          break;
        case Key.ARROW_LEFT:
          handleLeft(listItems);
          break;
        default:
          break;
      }
    }
  };

  return {
    setFirstItemSelected,
  };
};
