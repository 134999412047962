import { MenuItem } from '../../../models/dtos/ger/menuItem';

const connectMenu: MenuItem[] = [
  {
    icon: 'pe-7s-home',
    label: 'Home',
    to: '/connect/dashboard',
  },
  {
    content: [
      {
        label: 'Solicitações',
        to: '/connect/solicitacaoProcessView',
      },
      {
        label: 'Criar Solicitação',
        to: '/connect/solicitacaoEditProcessView',
      },
    ],
    icon: 'pe-7s-print',
    label: 'Solicitação',
  },
];

export default connectMenu;
