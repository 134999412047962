import { createLoadable } from 'summer';

const mprRoutes = [
  {
    component: createLoadable(() => import('./dashboard/DashboardMPR'), 'DashboardMPR'),
    path: '/mpr/dashboard',
  },
  {
    component: createLoadable(() => import('./documento/DocumentoSearchView'), 'DocumentoSearchView'),
    exact: true,
    path: '/mpr/documentoSearchView',
  },
  {
    component: createLoadable(() => import('./documento/DocumentoEditView'), 'DocumentoEditView'),
    path: '/mpr/documentoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./historico/HistoricoMPRSearchView'), 'HistoricoMPRSearchView'),
    exact: true,
    path: '/mpr/historicoMPRSearchView',
  },
  {
    component: createLoadable(() => import('./historico/HistoricoMPREditView'), 'HistoricoMPREditView'),
    path: '/mpr/historicoMPRSearchView/edit',
  },
  {
    component: createLoadable(() => import('./protocolo/ProtocoloSearchView'), 'ProtocoloSearchView'),
    exact: true,
    path: '/mpr/protocoloSearchView',
  },
  {
    component: createLoadable(() => import('./protocolo/ProtocoloEditView'), 'ProtocoloEditView'),
    path: '/mpr/protocoloSearchView/edit',
  },
  {
    component: createLoadable(() => import('./baixaProtocolo/BaixaProtocoloEditView'), 'BaixaProtocoloEditView'),
    path: '/mpr/baixaProtocoloEditView',
  },
  {
    component: createLoadable(() => import('./report/ProtocoloDocumentosReportView'), 'ProtocoloDocumentosReportView'),
    path: '/mpr/ProtocoloDocumentosReportView',
  },
  {
    component: createLoadable(() => import('./report/ProtocoloEmitidosReportView'), 'ProtocoloEmitidosReportView'),
    path: '/mpr/ProtocoloEmitidosReportView',
  },
];

export default mprRoutes;
