import { createLoadable } from 'summer';

const pages = [
  {
    component: createLoadable(() => import('./agenda/AgendaProcessView'), 'AgendaProcessView'),
    exact: true,
    path: '/:modulo/agendaProcessView',
  },
  {
    component: createLoadable(() => import('./agenda/AgendaEditView'), 'AgendaEditView'),
    exact: true,
    path: '/:modulo/agendaEditView',
  },
  {
    component: createLoadable(() => import('./ger/relatorio/RelatorioSearchView'), 'RelatorioSearchView'),
    exact: true,
    path: '/:modulo/relatorioSearchView',
  },
  {
    component: createLoadable(() => import('./ger/grupoRelatorio/GrupoRelatorioSearchView'), 'GrupoRelatorioSearchView'),
    exact: true,
    path: '/:modulo/grupoRelatorioSearchView',
  },
  {
    component: createLoadable(() => import('./ger/grupoRelatorio/GrupoRelatorioEditView'), 'GrupoRelatorioEditView'),
    path: '/:modulo/grupoRelatorioSearchView/edit',
  },
  {
    component: createLoadable(() => import('./ger/grupoRelatorio/GrupoRelatorioToggle'), 'GrupoRelatorioToggle'),
    exact: true,
    path: '/:modulo/grupoRel/:idGrupo',
  },
  {
    component: createLoadable(() => import('./ger/configUsuario/ConfigUsuarioEditView'), 'ConfigUsuarioEditView'),
    path: '/:modulo/configUsuarioEditView',
  },
];

export default pages;
