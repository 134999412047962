import cx from 'clsx';
import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { generateTestId, ProState } from 'summer';
import badgePro from '../../../assets/badgeProAzul.png';
import calimaProBranco from '../../../assets/calimaProBranco.png';
import { Reducers } from '../../../store/ducks';

interface ButtonModuloProps {
  mobile: boolean;
  enabled: boolean;
  label: string;
  to: string;
  testId: string;
  className: string;
  imgSrc: string | ReactNode;
  calimaPro?: boolean;
}

const ButtonModulo: FC<ButtonModuloProps> = props => {
  const { isPro } = useSelector<Reducers, ProState>(state => state.proReducer);
  const disabledPro = props.calimaPro && !isPro;

  return (
    <div className={cx('button-menu-modulo', { 'button-menu-mobile': props.mobile, 'modulo-desabilitado': !props.enabled })}>
      <div>
        <Link
          to={props.to}
          className={cx('btn card card-box d-flex align-items-center flex-column px-3 py-2 w-100 border-0', props.className, {
            'disabled-pro': disabledPro,
          })}
          {...generateTestId(props.testId)}
        >
          <div>
            {typeof props.imgSrc === 'string' ? (
              <img src={props.imgSrc} alt={`Módulo ${props.label}`} className="h1 my-2" style={{ width: 30, height: 30 }} />
            ) : (
              props.imgSrc
            )}
            <div className="font-size-md mb-1 menu-descktop-name">{props.label}</div>
          </div>
          {disabledPro &&
            (props.mobile ? (
              <img src={badgePro} alt="Calima Pro" className="badge-pro" />
            ) : (
              <div className="calima-pro">
                <span>Acessível apenas com</span>
                <img src={calimaProBranco} alt="Calima Pro" />
              </div>
            ))}
        </Link>
      </div>
      <div className="font-size-md mb-1 mt-2 menu-mobile-name">{props.label}</div>
    </div>
  );
};

export default ButtonModulo;
