import {
  AlertType,
  compare,
  currentDate,
  ERROR,
  getMonthsBetween,
  isEmpty,
  isNotEmpty,
  parseDateToRef,
  parseRefToDate,
  REF_PATTERN,
  showToast,
} from 'summer';
import { Empresa } from '../models/dtos/ger/empresa';
import { TributacaoEmpresa } from '../models/dtos/ger/tributacaoEmpresa';
import { findTributacaoNoPeriodo } from '../services/ger.service';
import { promisedAlert } from './empresa.util';

export const TRIBUTACOES_DIFERENTES_ENCONTRADAS =
  'Existe mais de uma vigência de tributação cadastrada no período selecionado. Cada vigência deve ser apurada separadamente. ' +
  'Para mais informações, acesse Módulo Principal > Manutenção > Empresa > Dados Complementares.';

/**
 * Busca pelas vigências cadastradas no início e no fim do período informado. Se houver somente uma vigência no período,
 * ela é retornada. Caso contrário, retorna nulo, indicando que o período é inválido para operações
 * que devem apurar vigências diferentes separadamente.
 * @param refInicio referência no início do período
 * @param refFim referência no fim do período
 * @param idEmpresa id da empresa
 */
export const validarTributacoesNoPeriodo = async (refInicio, refFim, idEmpresa) => {
  let tributacaoNoInicio: TributacaoEmpresa = {};
  let tributacaoNoFim: TributacaoEmpresa = {};
  await new Promise(resolve => {
    findTributacaoNoPeriodo(refInicio, idEmpresa, {
      thenFunction: result => {
        tributacaoNoInicio = result;
        resolve();
      },
    });
  });

  await new Promise(resolve => {
    findTributacaoNoPeriodo(refFim, idEmpresa, {
      thenFunction: result => {
        tributacaoNoFim = result;
        resolve();
      },
    });
  });

  return tributacaoNoInicio.id === tributacaoNoFim.id ? tributacaoNoInicio : null;
};

export const promisedAlertTributacoesDiferentes = () => promisedAlert(TRIBUTACOES_DIFERENTES_ENCONTRADAS, AlertType.INFORMATION);

export const buscarTributacaoNoPeriodoCallback = (referencia, idEmpresa, callBack) => {
  const referenciaParam = referencia || parseDateToRef(currentDate());
  findTributacaoNoPeriodo(referenciaParam, idEmpresa, {
    errorFunction: mensagem => showToast(mensagem, ERROR),
    thenFunction: result => {
      callBack(result);
    },
  });
};

export const validateTributacao = (formData: Empresa) => {
  const erros = [];

  if (isEmpty(formData.tributacoes) || formData.tributacoes.length === 0) {
    erros.push('Pelo menos uma vigência de tributação deve ser cadastrada.');
  } else {
    if (formData.tributacoes.filter(it => isEmpty(it.refFimVigencia)).length !== 1) {
      erros.push(
        'Vigências de tributações - É obrigatório que haja precisamente 1 (uma) vigência com o campo "Referência no fim da vigência" em branco.'
      );
    }

    if (formData.tipoEmpresaPessoaFisica && formData.tributacoes.some(it => isNotEmpty(it.tipoTributacao))) {
      erros.push('Vigências de tributações - O campo "Tipo de tributação" deve ficar vazio quando a opção "Pessoa física" estiver marcada.');
    }

    if (!formData.tipoEmpresaPessoaFisica && formData.tributacoes.some(it => isEmpty(it.tipoTributacao))) {
      erros.push('Vigências de tributações - O campo "Tipo de tributação" é obrigatório quando a opção "Pessoa física" estiver desmarcada.');
    }

    if (formData.tributacoes.length > 1) {
      let addMsgDiferencaMes = false;
      let addMsgMesmoPeriodo = false;
      let addMsgTipoTributacaoRepetido = false;

      formData.tributacoes
        .sort((a, b) => a.refInicioVigencia.localeCompare(b.refInicioVigencia))
        .forEach((tributacao, index, lista) => {
          if (!addMsgDiferencaMes) {
            addMsgDiferencaMes = lista.some((trib, indexLista) => {
              let periodoSemTributacao = false;
              if (indexLista - index === 1) {
                periodoSemTributacao = getMonthsBetween(parseRefToDate(tributacao.refFimVigencia, 0), parseRefToDate(trib.refInicioVigencia, 0)) > 1;
              }
              return periodoSemTributacao;
            });
          }

          if (!addMsgMesmoPeriodo) {
            addMsgMesmoPeriodo = lista.some((trib, indexLista) => {
              let periodoJaPreenchido = false;
              if (index !== indexLista) {
                const refComparacoesFuturas = parseDateToRef(new Date(3000, 0, 1));
                const refFimA = isNotEmpty(tributacao.refFimVigencia) ? tributacao.refFimVigencia : refComparacoesFuturas;
                const refFimB = isNotEmpty(trib.refFimVigencia) ? trib.refFimVigencia : refComparacoesFuturas;
                const compareA =
                  compare(tributacao.refInicioVigencia, trib.refInicioVigencia, REF_PATTERN) >= 0 &&
                  compare(tributacao.refInicioVigencia, refFimB, REF_PATTERN) <= 0;
                const compareB = compare(refFimA, trib.refInicioVigencia, REF_PATTERN) >= 0 && compare(refFimA, refFimB, REF_PATTERN) <= 0;

                periodoJaPreenchido = compareA || compareB;
              }
              return periodoJaPreenchido;
            });
          }

          if (!addMsgTipoTributacaoRepetido) {
            addMsgTipoTributacaoRepetido = lista.some((trib, indexLista) => {
              let tributacaoRepetida = false;
              if (indexLista - index === 1) {
                tributacaoRepetida = tributacao.tipoTributacao === trib.tipoTributacao;
              }
              return tributacaoRepetida;
            });
          }
        });

      if (addMsgDiferencaMes) {
        erros.push('Vigências de tributações - Não deve haver mais de um mês de diferença entre as tributações cadastradas.');
      }

      if (addMsgMesmoPeriodo) {
        erros.push('Vigências de tributações - Existem vigências de tributações cadastradas no mesmo período.');
      }

      if (addMsgTipoTributacaoRepetido) {
        erros.push('Vigências de tributações - Não devem haver vigências de tributações consecutivas com o mesmo tipo de tributação.');
      }
    }
  }

  return erros;
};
