import cx from 'clsx';
import React, { FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Nav, NavItem } from 'reactstrap';
import { useQuickAccess } from '../../../hooks/useQuickAccess';
import QuickAccessListItem from './QuickAccessListItem';

import './QuickAccessList.css';

interface QuickAcessListProps {
  item: any;
}

const QuickAccessList: FC<QuickAcessListProps> = ({ item }) => {
  const quickAccess = useQuickAccess();

  return (
    <Nav pills={true} className={cx('list-item flex-column p-3 d-inline-block align-top', `nav-neutral-${item.modulo}`)} style={{ width: 310 }}>
      {!quickAccess.isSingle && (
        <NavItem className={cx('nav-item pb-2 text-uppercase font-weight-bold font-size-sm', `text-${item.modulo}`)}>
          <span>{item.titulo}</span>
        </NavItem>
      )}
      <PerfectScrollbar>
        {item.itens.map((it, index) => (
          <QuickAccessListItem key={index} modulo={item.modulo} item={it} />
        ))}
      </PerfectScrollbar>
    </Nav>
  );
};

export default QuickAccessList;
