import React, { FC } from 'react';
import { InputGroupInline, InputProps, InputType } from 'summer';
import { CLASS_NAME } from '../../../../models/dtos/ger/cepTitulo';

const CepTituloIS: FC<InputProps> = props => {
  return (
    <InputGroupInline
      {...props}
      inputSearchProps={{
        dtoClassName: CLASS_NAME,
        ...props.inputSearchProps,
        columns: [
          {
            dataField: 'nome',
            text: 'Nome',
          },
        ],
        searchViewId: 'CepTituloSearchView',
      }}
      label={props.label ? props.label : 'Tipo'}
      type={InputType.INPUT_SEARCH}
      disabled={props.disabled}
    />
  );
};

export default CepTituloIS;
