import { AdministradoraMHO } from '../mho/administradoraMHO';
import { AdministradoraEmail } from './administradoraEmail';
import { AdministradoraEndereco } from './administradoraEndereco';
import { AdministradoraExample } from './administradoraExample';
import { AdministradoraTelefone } from './administradoraTelefone';

export interface Administradora extends AdministradoraExample {
  administradoraMHO?: AdministradoraMHO;
  emails?: AdministradoraEmail[];
  enderecos?: AdministradoraEndereco[];
  logoRelatorio?: File | string;
  telefones?: AdministradoraTelefone[];
}

export const CLASS_NAME = 'br.com.projetusinformatica.calima.ger.model.dto.administradora';
