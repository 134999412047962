import React, { FC } from 'react';
import { Col, Row } from 'reactstrap';
import { WrapperSimple } from 'summer';

import './LoadingView.css';

const LoadingView: FC = () => {
  const rows = (count, i) => (
    <Row key={i}>
      {Array.from(Array(count), (_, j) => (
        <Col key={j}>
          <div className="loading-animation" />
        </Col>
      ))}
    </Row>
  );

  return <WrapperSimple sectionHeading={<div className="loading-animation my-2 max-width" />}>{[3, 2, 1, 3].map(rows)}</WrapperSimple>;
};

export default LoadingView;
