import { useState } from 'react';

export interface SearchFilterItemProps {
  name: string;
  value: any;
}

export interface SearchFilterProps {
  exists: (name: string) => boolean;
  setFilter: (name: string, value: any) => void;
  getFilter: (name: string) => any;
  remFilter: (name: string) => void;
}

const useSearchFilter = (): SearchFilterProps => {
  const [filterList, setFilterList] = useState<SearchFilterItemProps[]>([]);

  const exists = (name: string) => filterList.find(it => it.name === name) !== undefined;

  const getFilter = (name: string): any => {
    const item = filterList.find(it => it.name === name);
    return item ? item.value : null;
  };

  const remFilter = (name: string) => setFilterList(items => items.filter(it => it.name !== name));

  const setFilter = (name: string, value: any) => {
    if (value) {
      setFilterList(items => [...items.filter(it => it.name !== name), { name, value }]);
    } else {
      remFilter(name);
    }
  };

  return {
    exists,
    getFilter,
    remFilter,
    setFilter,
  };
};

export default useSearchFilter;
