import React, { FC, ReactNode } from 'react';

import './Badge.css';

interface BadgeProps {
  backgroundColor: string;
  className?: string;
  label: string;
  icon?: ReactNode;
}

const Badge: FC<BadgeProps> = ({ backgroundColor, className, label, icon }) => (
  <span className={`badge-integra-contador ${className ? className : ''}`} style={{ backgroundColor }}>
    {label}
    {icon}
  </span>
);

export default Badge;
