import cx from 'clsx';
import React, { FC, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import certificadoVencidoPng from '../../../../assets/certificadoVencido.png';
import { Reducers } from '../../../../store/ducks';
import { GlobalState, setTaExibindoCertificadoVencido } from '../../../../store/ducks/global.duck';
import FullSizeModal, { FullSizeModalRef } from '../../../components/fullSizeModal/FullSizeModal';

import './CertificadoVencidoView.css';

const CertificadoVencidoView: FC = () => {
  const { taExibindoCertificadoVencido } = useSelector<Reducers, GlobalState>(state => state.globalReducer);

  const modal = useRef<FullSizeModalRef>();
  const dispatch = useDispatch();

  const onClose = () => {
    window.sessionStorage.setItem('certificadoVencido', 'true');
    dispatch(setTaExibindoCertificadoVencido(false));
  };

  const onContratar = e => {
    window.open('https://www.calimaerp.com/certificado_digital', '_blank');
  };

  return (
    taExibindoCertificadoVencido && (
      <FullSizeModal className="modal-certificado-vencido" onClose={onClose} ref={modal} aspectRatio={1.5399}>
        <div className="slide">
          <img src={certificadoVencidoPng} alt="Certificado vencido" className={cx({ visible: true })} />
        </div>
        <div className="ad-footer">
          <Button className="button-link" color="link" onClick={onClose}>
            Resolver depois
          </Button>
          <Button className="button-contratar" onClick={onContratar}>
            Contratar agora
          </Button>
        </div>
      </FullSizeModal>
    )
  );
};

export default CertificadoVencidoView;
