import { useEffect, useState } from 'react';

const useNetworkStatus = () => {
  const [online, setOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const changeStatus = () => {
      setOnline(window.navigator.onLine);
    };

    window.addEventListener('online', changeStatus);
    window.addEventListener('offline', changeStatus);

    return () => {
      window.removeEventListener('online', changeStatus);
      window.removeEventListener('offline', changeStatus);
    };
  }, []);

  return online;
};

export default useNetworkStatus;
