import { createAction, handleActions } from 'redux-actions';
import { JobInfo } from '../../models/dtos/ger/jobInfo';

export interface JobState {
  data: JobInfo[];
  existsActiveJobs: boolean;
  showing: boolean;
}

const initialState: JobState = {
  data: [],
  existsActiveJobs: false,
  showing: false,
};

export const existsActiveJobs = createAction('EXISTS_ACTIVE_JOBS');
export const setJobs = createAction('SET_JOBS');

export const reducer = handleActions<JobState>(
  {
    [existsActiveJobs.toString()]: (state: JobState, action: any) => ({
      ...state,
      existsActiveJobs: action.payload,
    }),
    [setJobs.toString()]: (state: JobState, action: any) => ({
      ...state,
      data: action.payload,
    }),
  },
  initialState
);
