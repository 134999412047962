import React, { MutableRefObject, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { MenuItem } from '../../../models/dtos/ger/menuItem';
import { Reducers } from '../../../store/ducks';
import { GlobalState } from '../../../store/ducks/global.duck';
import { filterVisibles, scrollTo } from '../../../utilities/menu.util';
import SidebarNavItem from './SidebarNavItem';

export interface SidebarNavProps {
  items: MenuItem[];
  scrollRef: MutableRefObject<any>;
}

const SidebarNav: React.FC<SidebarNavProps> = props => {
  const { currentState, globalParameter } = useSelector<Reducers, GlobalState>(state => state.globalReducer);
  const [lastItemClicked, setLastClickedItem] = useState('');
  const [breadcrumbsItems, setBreadcrumbsItems] = useState([]);
  const history = useHistory();

  const items = useMemo(() => {
    return (
      (globalParameter.usuario.acessoModulos[currentState.toLowerCase()] &&
        props.items.filter(it => filterVisibles(it, globalParameter, currentState))) ||
      []
    );
  }, [globalParameter, currentState, props.items]);

  useEffect(
    () => {
      if (items.length > 0) {
        const breadcrumbs = getBreadcrumbs(items, history.location.pathname);
        if (breadcrumbs && breadcrumbs.length > 0) {
          setLastClickedItem(breadcrumbs[0]);
          setBreadcrumbsItems(breadcrumbs.splice(1));
          const target: HTMLElement = document.querySelector(`li > a[href="${history.location.pathname}"]`);
          if (target) {
            setTimeout(() => target.focus(), 750);
          }
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, history.location.pathname]
  );

  const getBreadcrumbs = (menu, value, breadcrumbs = []) => {
    let result = null;
    menu.forEach(it => {
      if (!result) {
        if (it.content && it.content.length > 0) {
          result = getBreadcrumbs(it.content, value, [...breadcrumbs, it.label]);
        } else if (value.indexOf(it.to) !== -1) {
          result = [...breadcrumbs, it.label];
        }
      }
    });
    return result;
  };

  const toggleItem = event => {
    event.preventDefault();

    const target = event.currentTarget;
    const innerText = target.innerText.replace(/\n/g, '');

    setTimeout(() => {
      const nav = props.scrollRef.current._container.querySelector('.sidebar-navigation');

      const containerBounds = props.scrollRef.current._container.getBoundingClientRect();
      const navBounds = nav.getBoundingClientRect();
      const itemBounds = target.getBoundingClientRect();

      let scroll;

      if (navBounds.height > containerBounds.height) {
        if (navBounds.top < 0) {
          scroll = scrollTo(
            props.scrollRef.current._container,
            props.scrollRef.current._container.scrollTop + navBounds.top + (itemBounds.top + containerBounds.top) * -1
          );
        }
      } else if (navBounds.top < 0) {
        scroll = scrollTo(props.scrollRef.current._container, 0);
      }

      if (scroll) {
        scroll.then(newValue => {
          props.scrollRef.current.scrollTop = newValue;
          props.scrollRef.current.updateScroll();
        });
      }
    }, 750);

    setLastClickedItem(lastItemClicked === innerText ? '' : innerText);
  };

  return (
    <ul>
      {items.map((item, index) => (
        <SidebarNavItem key={index} item={item} onClick={toggleItem} isOpen={item.label === lastItemClicked} breadcrumbs={breadcrumbsItems} />
      ))}
    </ul>
  );
};

export default SidebarNav;
