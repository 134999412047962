import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { proActions, ProState } from 'summer';
import { useCrispAction } from '../../../../hooks/useCrispAction';
import { Reducers } from '../../../../store/ducks';
import FullSizeModal, { FullSizeModalRef } from '../../../components/fullSizeModal/FullSizeModal';

import './ModalAdCalimaPro.css';

import adCalimaPro1 from '../../../../assets/adCalimaPro1.png';
import adCalimaPro2 from '../../../../assets/adCalimaPro2.png';
import adCalimaPro3 from '../../../../assets/adCalimaPro3.png';
import adCalimaPro4 from '../../../../assets/adCalimaPro4.png';
import adCalimaPro5 from '../../../../assets/adCalimaPro5.png';

const IMAGES = [adCalimaPro1, adCalimaPro2, adCalimaPro3, adCalimaPro4, adCalimaPro5];

const ModalAdCalimaPro: FC = () => {
  const { showAdModal } = useSelector<Reducers, ProState>(state => state.proReducer);

  const modal = useRef<FullSizeModalRef>();
  const dispatch = useDispatch();
  const crispAction = useCrispAction();

  const [visible, setVisible] = useState(0);

  useEffect(() => {
    if (!showAdModal) {
      setVisible(0);
    }
  }, [showAdModal]);

  const onClose = () => dispatch(proActions.toggleAdModal(false));

  const prev = () => setVisible(p => (p === 0 ? IMAGES.length - 1 : p - 1));

  const next = () => setVisible(p => (p === IMAGES.length - 1 ? 0 : p + 1));

  const onContratar = e => {
    e.preventDefault();
    modal.current.close();
    crispAction.openComercial();
  };

  return (
    showAdModal && (
      <FullSizeModal className="modal-ad-calima-pro" onClose={onClose} ref={modal} aspectRatio={1.5399}>
        <div className="slide">
          {IMAGES.map((url, index) => (
            <img key={index} src={url} alt="" className={cx({ visible: visible === index })} />
          ))}
          <button className="area area-prev" onClick={prev}>
            <div>
              <FontAwesomeIcon icon="chevron-left" />
            </div>
          </button>
          <button className="area area-next" onClick={next}>
            <div>
              <FontAwesomeIcon icon="chevron-right" />
            </div>
          </button>
        </div>
        <div className="ad-footer">
          <a href="https://www.calimaerp.com/pro" target="_blank" rel="noopener noreferrer">
            Saiba mais sobre o Calima Pro
          </a>
          <button onClick={onContratar}>Contratar</button>
        </div>
      </FullSizeModal>
    )
  );
};

export default ModalAdCalimaPro;
