import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { EditFormState, ERROR, findById, showToast } from 'summer';
import { Reducers } from '../../../store/ducks';
import LoadingView from './LoadingView';

interface EditViewHandlerProps {
  routeProps: any;
  component: any;
}

const EditViewHandler: FC<EditViewHandlerProps> = props => {
  const { savedAt, ignoreResetScreen } = useSelector<Reducers, EditFormState>(state => state.editFormReducer);
  const [loaded, setLoaded] = useState(false);
  const history = useHistory();
  const key = savedAt && !ignoreResetScreen ? savedAt.milliseconds() : 0;

  useEffect(
    () => {
      load();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const find = (id, className) =>
    new Promise<any>(resolve => {
      findById({ id }, className, {
        errorFunction: mensagem => {
          showToast(mensagem, ERROR);
          resolve(null);
        },
        thenFunction: result => resolve(result),
      });
    });

  const load = async () => {
    const { CLASS_NAME: className } = await props.component._ctor();
    const { pathname } = props.routeProps.location;
    if (className && pathname) {
      const path = pathname.split('/');
      const editIndex = path.findIndex(it => it === 'edit');

      if (editIndex !== path.length - 1) {
        const id = path[editIndex + 1];
        const result = await find(id, className);

        if (result) {
          history.replace(pathname, { ...(history.location.state || {}), selectedItem: result });
        }
      }
    }

    setLoaded(true);
  };

  return loaded ? <props.component {...props.routeProps} key={key} /> : <LoadingView />;
};

export default EditViewHandler;
