import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { alertManager, AlertType } from 'summer';
import { useCrispAction } from '../../../../hooks/useCrispAction';
import { GlobalParameter } from '../../../../models/dtos/ger/globalParameter';
import { Reducers } from '../../../../store/ducks';

const ButtonCrisp: FC = () => {
  const { acesso } = useSelector<Reducers, GlobalParameter>(state => state.globalReducer.globalParameter);

  const crispAction = useCrispAction();

  const onClick = () => {
    if (acesso.suporteChat) {
      crispAction.onClick();
    } else {
      alertManager.emit({
        message: 'O serviço SUPORTE VIA CHAT não está habilitado. Deseja adquiri-lo?',
        onOkClick: () => crispAction.openComercial(),
        type: AlertType.WARNING_YES_NO,
      });
    }
  };

  return (
    <>
      {crispAction.modal}
      <Button id="open-crisp" className="btn-pill d-40 p-0" onClick={onClick}>
        <FontAwesomeIcon icon={['fas', 'comment-alt']} />
        <UncontrolledTooltip placement="left" target="open-crisp">
          Falar com um especialista
        </UncontrolledTooltip>
      </Button>
    </>
  );
};

export default ButtonCrisp;
