import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { NavItem, NavLink } from 'reactstrap';
import { useQuickAccess } from '../../../hooks/useQuickAccess';
import { Reducers } from '../../../store/ducks';
import { GlobalState, setCurrentState } from '../../../store/ducks/global.duck';

import './QuickAccessListItem.css';

interface QuickAccessListItemProps {
  modulo: string;
  item: any;
}

const QuickAccessListItem: FC<QuickAccessListItemProps> = ({ modulo, item }) => {
  const { currentState } = useSelector<Reducers, GlobalState>(state => state.globalReducer);
  const quickAccess = useQuickAccess();

  const dispatch = useDispatch();
  const history = useHistory();

  const onClick = e => {
    e.preventDefault();

    if (currentState !== modulo) {
      dispatch(setCurrentState(modulo));
    }

    quickAccess.toggle();

    const url = e.currentTarget.getAttribute('href');
    setTimeout(() => {
      history.push(url);
    }, 50);
  };

  const toggleFav = e => {
    e.preventDefault();
    e.stopPropagation();
    quickAccess.toggleFav(item.to);
  };

  return (
    <NavItem className={`header-search-item header-search-item-${modulo}`}>
      <NavLink href={item.to} onClick={onClick} className="pl-2">
        <div className="mr-2 d-flex">
          <FontAwesomeIcon icon={['fas', 'circle']} className="opacity-8" style={{ fontSize: '8px' }} />
        </div>
        <span className="flex-grow-1">{item.label}</span>
        {quickAccess.isFav && (
          <div className="fav-toggle" onClick={toggleFav}>
            <FontAwesomeIcon icon={[quickAccess.isPendingRemoval(item.to) ? 'far' : 'fas', 'star']} />
          </div>
        )}
      </NavLink>
    </NavItem>
  );
};

export default QuickAccessListItem;
