export const LUCRO_REAL = { value: 'lucroReal', label: 'Lucro Real' };
export const LUCRO_REAL_ARBITRADO = { value: 'lucroRealArbitrado', label: 'Lucro Real/Arbitrado' };
export const LUCRO_PRESUMIDO_REAL = { value: 'lucroPresumidoReal', label: 'Lucro Presumido/Real' };
export const LUCRO_PRESUMIDO_REAL_ARBITRADO = { value: 'lucroPresumidoRealArbitrado', label: 'Lucro Presumido/Real/Arbitrado' };
export const LUCRO_PRESUMIDO = { value: 'lucroPresumido', label: 'Lucro Presumido' };
export const LUCRO_ARBITRADO = { value: 'lucroArbitrado', label: 'Lucro Arbitrado' };
export const LUCRO_PRESUMIDO_ARBITRADO = { value: 'lucroPresumidoArbitrado', label: 'Lucro Presumido/Arbitrado' };
export const IMUNE = { value: 'imune', label: 'Imune do IRPJ' };
export const ISENTO = { value: 'isento', label: 'Isenta do IRPJ' };
export const SIMPLES_NACIONAL = { value: 'simplesNacional', label: 'Simples Nacional' };

export const TIPO_TRIBUTACAO_ENUM_VALUES = [
  IMUNE,
  ISENTO,
  LUCRO_ARBITRADO,
  LUCRO_PRESUMIDO,
  LUCRO_PRESUMIDO_ARBITRADO,
  LUCRO_PRESUMIDO_REAL,
  LUCRO_PRESUMIDO_REAL_ARBITRADO,
  LUCRO_REAL,
  LUCRO_REAL_ARBITRADO,
  SIMPLES_NACIONAL,
];

export const AUTOMATIZACAO_EMPRESA_VALUES = [LUCRO_REAL, LUCRO_PRESUMIDO, ISENTO, IMUNE, SIMPLES_NACIONAL];

export const TRIBUTACAO_LUCRO_REAL_ENUM_VALUES = [LUCRO_REAL, LUCRO_REAL_ARBITRADO, LUCRO_PRESUMIDO_REAL_ARBITRADO];

export const TRIBUTACAO_LUCRO_PRESUMIDO_ENUM_VALUES = [
  LUCRO_PRESUMIDO,
  LUCRO_PRESUMIDO_ARBITRADO,
  LUCRO_PRESUMIDO_REAL,
  LUCRO_PRESUMIDO_REAL_ARBITRADO,
];

export const TRIBUTACAO_LUCRO_ENUM_VALUES = [
  LUCRO_ARBITRADO,
  LUCRO_PRESUMIDO,
  LUCRO_PRESUMIDO_ARBITRADO,
  LUCRO_PRESUMIDO_REAL,
  LUCRO_PRESUMIDO_REAL_ARBITRADO,
  LUCRO_REAL,
  LUCRO_REAL_ARBITRADO,
];
