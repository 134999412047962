import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import 'react-datetime/css/react-datetime.css';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.css';
import { loadIcons } from 'summer';
import 'summer/dist/summer.cjs.development.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import('moment');
import('moment/locale/pt-br');
loadIcons();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
