import axios from 'axios';
import Bowser from 'bowser';

const getBrowserName = () => Bowser.parse(window.navigator.userAgent).browser.name;

export const isChrome = () => getBrowserName() === 'Chrome';
export const isEdge = () => getBrowserName() === 'Microsoft Edge';

export const hasUtilitario = () => window.UTILITARIO_CALIMA_INSTANCE !== undefined && window.UTILITARIO_CALIMA_INSTANCE.version;

export const getClientIP = () => axios.get('https://api64.ipify.org/?format=text', { responseType: 'text' }).then(r => r.data);
