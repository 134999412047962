import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import {
  alertManager,
  AlertType,
  cepFormatter,
  ColumnsProps,
  DefaultSortProps,
  DetailManagerForm,
  DetailManagerFormProps,
  ERROR,
  InputGroupInline,
  InputType,
  isEmpty,
  OrderType,
  persist,
  ProcessBar,
  showToast,
  SUCCESS,
  userActions,
} from 'summer';
import { CLASS_NAME, Empresa } from '../../../../models/dtos/ger/empresa';
import { Endereco } from '../../../../models/dtos/ger/endereco';
import { atualizarGlobalParameter } from '../../../../services/ger.service';
import { Reducers } from '../../../../store/ducks';
import { GlobalState, setGlobalParameter } from '../../../../store/ducks/global.duck';
import { redirectToLogin } from '../../../../utilities/login.util';
import { tipoEnderecoFormatter } from '../../../../utilities/tipoEnderecoFormatter';
import EmpresaEnderecoDetailEditView from '../empresa/EmpresaEnderecoDetailEditView';

const ValidarEnderecoEmpresa: FC = () => {
  const { globalParameter } = useSelector<Reducers, GlobalState>(state => state.globalReducer);

  const formProps = useForm<Empresa>({ mode: 'onBlur' });

  const [isOpenEnderecos, setIsOpenEnderecos] = useState(false);

  const dispatch = useDispatch();

  useEffect(
    () => {
      formProps.reset(globalParameter.empresa);
      if (validarCamposEndereco(globalParameter.empresa.enderecos)) {
        setIsOpenEnderecos(true);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [globalParameter.empresa]
  );

  const validarCamposEndereco = (enderecos: Endereco[]) => {
    let condEndereco = false;
    let condCamposEndereco = false;
    if (enderecos.length === 0) {
      condEndereco = true;
    } else {
      enderecos.forEach(endereco => {
        if (!condCamposEndereco) {
          const campos = ['cep', 'tipoLogradouro', 'uf', 'localidade', 'bairro', 'logradouro'];
          campos.forEach(it => {
            if (isEmpty(endereco[it])) {
              condCamposEndereco = true;
            }
          });
        }
      });
    }
    return condEndereco || condCamposEndereco;
  };

  const validarEndereco = () => {
    let existeEndereco = true;
    let isPrincipal = false;

    if (validarCamposEndereco(formProps.getValues().enderecos)) {
      alertManager.emit({
        message: 'Cadastre um endereço ou complete um dos cadastrados para continuar!',
        type: AlertType.ERROR,
      });
      existeEndereco = false;
    } else {
      formProps.getValues().enderecos.forEach(endereco => {
        if (!endereco.tipoEnderecoPrincipal && !isPrincipal) {
          existeEndereco = false;
        } else {
          existeEndereco = true;
          isPrincipal = true;
        }
      });
      if (!existeEndereco) {
        alertManager.emit({
          message: 'Cadastre um endereço principal para continuar!',
          type: AlertType.ERROR,
        });
      }
    }

    return existeEndereco;
  };

  const hideScreen = () => {
    const emp: Empresa = { ...globalParameter.empresa, ...formProps.getValues() };

    if (validarEndereco()) {
      persist(emp, CLASS_NAME, {
        errorFunction: mensagem => {
          showToast(mensagem, ERROR);
        },
        thenFunction: () => {
          afterPersist();
          setIsOpenEnderecos(false);
          const mensagemToast = { mensagem: 'O endereço da empresa foi salvo com sucesso!', toastProps: SUCCESS };
          showToast(mensagemToast.mensagem, mensagemToast.toastProps);
        },
      });
    }
  };

  const afterPersist = () => {
    atualizarGlobalParameter(globalParameter.usuario, {
      errorFunction: mensagem => {
        showToast(mensagem, ERROR);
      },
      thenFunction: result => {
        dispatch(setGlobalParameter(result));
      },
    });
  };

  const close = () => {
    const enderecoValido = validarEndereco();
    if (!enderecoValido) {
      alertManager.emit({
        message:
          'O endereço da empresa não está cadastrado corretamente. ' +
          'Caso o cadastro não seja corrigido, você não poderá continuar utilizando o Calima e sua sessão será encerrada. Deseja corrigir o cadastro agora?',
        onNoClick: () => {
          dispatch(userActions.logout());
          redirectToLogin();
        },
        onOkClick: () => hideScreen(),
        type: AlertType.WARNING_YES_NO,
      });
    }
  };

  const defaultSortEnderecoProps: DefaultSortProps[] = [
    {
      dataField: 'logradouro',
      order: OrderType.ASC,
    },
  ];

  const columnsEmpresaEndereco: ColumnsProps[] = [
    {
      dataField: 'logradouro',
      text: 'Logradouro',
    },
    {
      dataField: 'numero',
      text: 'Número',
    },
    {
      dataField: 'cep',
      formatter: cepFormatter,
      text: 'CEP',
    },
    {
      dataField: 'bairro.bairro',
      text: 'Bairro',
    },
    {
      dataField: 'localidade.nome',
      text: 'Cidade',
    },
    {
      dataField: 'tipoLogradouro',
      formatter: (cell, row) => {
        return row.tipoLogradouro ? row.tipoLogradouro.nome : null;
      },
      text: 'Tipo de Logradouro',
    },
    {
      dataField: 'tipoEnderecoPrincipal',
      formatter: tipoEnderecoFormatter,
      text: 'Tipo de Endereço',
    },
  ];

  const empresaEnderecoFormProps: DetailManagerFormProps = {
    columns: columnsEmpresaEndereco,
    detailView: EmpresaEnderecoDetailEditView,
    formProps,
    propName: 'enderecos',
    title: 'Endereços',
  };

  return (
    <Modal size="xl" contentClassName="modal-content-full" centered={true} backdrop="static" isOpen={isOpenEnderecos}>
      <ModalBody>
        <div className="alert d-flex align-items-center pl-2 align-content-center alert-warning alert-dismissible fade show mb-0" role="alert">
          <span className="font-size-lg d-block d-40 mr-2 text-center">
            <FontAwesomeIcon icon="exclamation-triangle" />
          </span>
          <span>
            <strong className="d-block">Atenção!</strong>A empresa abaixo não possui um endereço ou o mesmo esta faltando campos obrigatórios. Para o
            correto funcionamento do sistema é necessário que o endereço esteja completo. Ajuste os dados do endereço abaixo.
          </span>
        </div>
        <Row className="mt-3">
          <Col>
            <InputGroupInline
              disabled={true}
              formProps={{ ...formProps, validation: { required: true, maxLength: 100 } }}
              id="razaoSocial"
              label="Razão Social"
            />
          </Col>
          <Col>
            <InputGroupInline disabled={true} formProps={{ ...formProps, validation: { maxLength: 100 } }} id="nomeFantasia" label="Nome Fantasia" />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputGroupInline disabled={true} formProps={formProps} id="cpf" label="CPF" type={InputType.CPF} />
          </Col>
          <Col>
            <InputGroupInline disabled={true} formProps={formProps} id="cnpj" label="CNPJ" type={InputType.CNPJ} />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <DetailManagerForm
              defaultSortDirection={OrderType.DESC}
              defaultSorted={defaultSortEnderecoProps}
              detailViewSize="large"
              {...empresaEnderecoFormProps}
              keyField="rowIndex"
              title="Manutenção de Empresa - Endereço"
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className="justify-content-end">
        <ProcessBar handleClose={close} handleSubmit={hideScreen} btnSubmitLabel="Salvar" btnCloseLabel="Fechar" />
      </ModalFooter>
    </Modal>
  );
};

export default ValidarEnderecoEmpresa;
