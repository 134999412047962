import React, { FC, useEffect } from 'react';
import { Key, ModKey, shortcutManager } from 'summer';
import { QuickAccessProvider, QuickAccessType } from '../../../hooks/useQuickAccess';
import { UsuarioHistAcesso } from '../../../models/dtos/ger/usuarioHistAcesso';
import QuickAccessRoot from './QuickAccessRoot';

import './QuickAccess.css';

interface QuickAccessProps {
  type: QuickAccessType;
  items: UsuarioHistAcesso[];
  single: boolean;
  isOpen: boolean;
  toggle: () => void;
}

const TYPE_CONFIG = {
  [QuickAccessType.FAV]: {
    icon: 'star',
    id: 'list-favoritos',
    label: 'Favoritos',
    shortcut: Key.F,
  },
  [QuickAccessType.REC]: {
    icon: 'history',
    id: 'list-recentes',
    label: 'Recentes',
    shortcut: Key.R,
  },
};

const QuickAccess: FC<QuickAccessProps> = props => {
  const config = TYPE_CONFIG[props.type];

  useEffect(
    () => {
      const listener = shortcutManager.listen([ModKey.CTRL, ModKey.ALT], config.shortcut, handleShortcut);
      return () => listener.unlisten();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleShortcut = () => {
    if (!props.isOpen) {
      props.toggle();
    }
  };

  return (
    <QuickAccessProvider toggle={props.toggle} type={props.type} isSingle={props.single}>
      <QuickAccessRoot open={props.isOpen} config={config} items={props.items} />
    </QuickAccessProvider>
  );
};

export default QuickAccess;
