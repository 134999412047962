/* eslint-disable react-hooks/exhaustive-deps */
import cx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { alertManager, AlertType } from 'summer';
import { Reducers } from '../../../store/ducks';
import { MultiPageState } from '../../../store/ducks/multipage.duck';

const MultiPage: FC = props => {
  const multiPageReducer = useSelector<Reducers, MultiPageState>(state => state.multiPageReducer);
  const history = useHistory<any>();

  const [page, setInternalPage] = useState(null);

  const pushHistory = underMultiPage => history.push({ state: { ...(history.location.state || {}), underMultiPage } });

  const setPage = (state, pushEmptyState = false) => {
    if (page !== state) {
      if (pushEmptyState) {
        pushHistory(false);
      }
      pushHistory(state !== null);
      setInternalPage(state);
    }
  };

  useEffect(() => {
    if ((!history.location.state || !history.location.state.underMultiPage) && page !== null) {
      setInternalPage(null);
    }
  }, [history.location]);

  useEffect(() => {
    if (multiPageReducer.page === null) {
      setPage(null);
    } else {
      if (page === null || !multiPageReducer.showMessage) {
        setPage(multiPageReducer.page);
      } else {
        alertManager.emit({
          message: 'As alterações da página atual serão perdidas, deseja continuar?',
          onOkClick: () => {
            setPage(multiPageReducer.page, true);
          },
          type: AlertType.WARNING_YES_NO,
        });
      }
    }
  }, [multiPageReducer.page]);

  return (
    <>
      <div>{page}</div>
      <div className={cx({ 'd-none': page !== null })}>{props.children}</div>
    </>
  );
};

export default MultiPage;
