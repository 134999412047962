import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ColumnsProps, GridTable, ProcessBar } from 'summer';
import { useCrispAction } from '../../../../hooks/useCrispAction';
import {
  CALIMA_ARMAZENAMENTO_EXTRA,
  CALIMA_BOX,
  CALIMA_CONNECT,
  CALIMA_PRO,
  CALIMA_SITE,
  GERACAO_DIRF,
  GERACAO_RAIS,
  GERACAO_SEFIP,
  GERACAO_SPED_CONTABIL,
  GERACAO_SPED_ECF,
  GERACAO_SPED_FISCAL,
  GERACAO_SPED_FOLHA,
  LIMITE_USUARIOS,
  PERSONALIZACAO_RELATORIOS,
  REMOCAO_PROPAGANDAS,
  SMS_50_CREDITOS,
  SUPORTE_CHAT,
  SUPORTE_TELEFONICO,
  SUPORTE_TICKET,
} from '../../../../models/enumerated/ger/applicationStateEnum';
import { Reducers } from '../../../../store/ducks';
import { GlobalState } from '../../../../store/ducks/global.duck';

export interface InformacaoRegistroProcessViewProps {
  onClose?: () => void;
}

const InformacaoRegistroProcessView: FC<InformacaoRegistroProcessViewProps> = props => {
  const {
    globalParameter: { acesso },
  } = useSelector<Reducers, GlobalState>(state => state.globalReducer);

  const crispAction = useCrispAction();

  const [data, setData] = useState([]);

  useEffect(
    () => {
      setData([
        {
          modulo: REMOCAO_PROPAGANDAS.label,
          status: acesso.remocaoPropagandasHabilitado,
        },
        {
          modulo: PERSONALIZACAO_RELATORIOS.label,
          status: acesso.personalizacaoRelatorios,
        },
        {
          modulo: SUPORTE_CHAT.label,
          status: acesso.suporteChat,
        },
        {
          modulo: SUPORTE_TELEFONICO.label,
          status: acesso.suporteTelefonico,
        },
        {
          modulo: SUPORTE_TICKET.label,
          status: acesso.suporteTicket,
        },
        {
          modulo: GERACAO_SPED_FOLHA.label,
          status: acesso.geracaoSpedFolha,
        },
        {
          modulo: GERACAO_SPED_FISCAL.label,
          status: acesso.geracaoSpedFiscal,
        },
        {
          modulo: GERACAO_SPED_CONTABIL.label,
          status: acesso.geracaoSpedContabil,
        },
        {
          modulo: GERACAO_SPED_ECF.label,
          status: acesso.geracaoSpedECF,
        },
        {
          modulo: GERACAO_DIRF.label,
          status: acesso.geracaoDIRF,
        },
        {
          modulo: GERACAO_RAIS.label,
          status: acesso.geracaoRAIS,
        },
        {
          modulo: GERACAO_SEFIP.label,
          status: acesso.geracaoSEFIP,
        },
        {
          modulo: CALIMA_SITE.label,
          status: acesso.calimaSite,
        },
        {
          modulo: CALIMA_CONNECT.label,
          status: acesso.calimaConnect,
        },
        {
          modulo: CALIMA_BOX.label,
          status: acesso.calimaBox,
        },
        {
          modulo: CALIMA_ARMAZENAMENTO_EXTRA.label,
          status: acesso.calimaArmazenamentoExtra,
        },
        {
          modulo: SMS_50_CREDITOS.label,
          status: acesso.temCreditoSMS,
        },
        {
          modulo: CALIMA_PRO.label,
          status: acesso.calimaPro,
        },
        {
          modulo: LIMITE_USUARIOS.label,
          status: acesso.limiteUsuarios,
        },
      ]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const columns: ColumnsProps[] = [
    {
      dataField: 'modulo',
      text: 'Módulo',
    },
    {
      align: 'center',
      dataField: 'status',
      formatter: (cell, row) => {
        let result = row.status;

        if (row.status === true) {
          result = 'Sim';
        }
        if (row.status === false) {
          result = 'Não';
        }

        return result;
      },
      text: 'Status',
    },
  ];

  const handleSubmit = () => {
    props.onClose();
    crispAction.openComercial();
  };

  return (
    <Modal isOpen={true} centered={true} backdrop="static" toggle={props.onClose}>
      <ModalHeader toggle={props.onClose}>Informações de Registro</ModalHeader>
      <ModalBody>
        <GridTable columns={columns} data={data.map((it, id) => ({ ...it, id }))} />
      </ModalBody>
      <ModalFooter>
        <ProcessBar handleClose={props.onClose} handleSubmit={handleSubmit} btnCloseLabel="Fechar" hideSubmitButton={true}>
          <Button size="sm" color="first" onClick={handleSubmit}>
            Comprar Serviço / Treinamento
          </Button>
        </ProcessBar>
      </ModalFooter>
    </Modal>
  );
};

export default InformacaoRegistroProcessView;
