import React, { FC, useEffect } from 'react';
import { InputGroupInline, InputProps, InputType, isEmpty } from 'summer';
import { SearchFilterProps } from '../../../../hooks/useSearchFilter';
import { CLASS_NAME } from '../../../../models/dtos/ger/cepUF';

export interface CepUFISProps extends InputProps {
  searchFilter?: SearchFilterProps;
}

const CepUFIS: FC<CepUFISProps> = props => {
  const valor = props.formProps.watch(props.id);

  useEffect(
    () => {
      if (props.searchFilter) {
        props.searchFilter.setFilter(CLASS_NAME, valor);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [valor]
  );

  const searchExpressionsFull = defaultExpressions => {
    const expressions = [];

    if (props.searchFilter) {
      const item = props.searchFilter.getFilter(CLASS_NAME);
      if (item) {
        expressions.push({
          fieldName: 'uf.id',
          value: item.id,
        });
      }
    }

    const buscaSomentePorSigla = defaultExpressions.filter(it => it.fieldName === 'sigla');
    if (expressions.length > 0 && isEmpty(valor)) {
      expressions[0].expressions = buscaSomentePorSigla;
    } else {
      expressions.push(...buscaSomentePorSigla);
    }

    return expressions;
  };

  const onChange = value => {
    if (props.onChange) {
      props.onChange(value);
    }
  };

  return (
    <InputGroupInline
      {...props}
      inputSearchProps={{
        ...props.inputSearchProps,
        columns: [
          {
            dataField: 'codigo',
            text: 'Código',
          },
          {
            dataField: 'sigla',
            text: 'Sigla',
          },
          {
            dataField: 'nome',
            text: 'Nome',
          },
        ],
        dtoClassName: CLASS_NAME,
        searchExpressionsFull,
        searchViewId: 'CepUFSearchView',
      }}
      label={props.label ? props.label : 'UF'}
      type={InputType.INPUT_SEARCH}
      onChange={onChange}
      searchViewProps={{ searchFilter: props.searchFilter }}
    />
  );
};

export default CepUFIS;
