import { Basic } from 'summer';
import { CepBairro } from './cepBairro';
import { CepLocalidade } from './cepLocalidade';
import { CepTitulo } from './cepTitulo';
import { CepUF } from './cepUF';

export interface CepLogradouro extends Basic {
  bairro?: CepBairro;
  cep?: string;
  complemento?: string;
  localidade?: CepLocalidade;
  nome?: string;
  titulo?: CepTitulo;
  uf?: CepUF;
}

export const CLASS_NAME = 'br.com.projetusinformatica.calima.ger.model.dto.ceplogradouro';
