import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { alertManager, AlertType, AutoCompleteOff, dec, InputGroupInline, InputType, ProcessBar } from 'summer';
import { AlteracaoSenha } from '../../../../models/dtos/ger/alteracaoSenha';
import { alterarSenha } from '../../../../services/ger.service';
import { Reducers } from '../../../../store/ducks';
import { abrirAlteracaoSenha, fecharAlteracaoSenha, GlobalState } from '../../../../store/ducks/global.duck';

import './AlterarSenhaView.css';

const AlterarSenhaView: FC = () => {
  const formProps = useForm<AlteracaoSenha & { confirmacaoNovaSenha?: string }>({ mode: 'onBlur' });
  const { exibirAlteracaoSenha, globalParameter, usuarioAlteracaoSenha } = useSelector<Reducers, GlobalState>(state => state.globalReducer);

  const [atualizarSenhaObrigatorio, setAtualizarSenhaObrigatorio] = useState(false);

  const dispatch = useDispatch();

  useEffect(
    () => {
      const { usuario, senha } = globalParameter.usuario;
      if (usuario === senha && usuario === 'master') {
        dispatch(abrirAlteracaoSenha());
        setAtualizarSenhaObrigatorio(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const toggle = () => {
    if (!atualizarSenhaObrigatorio) {
      dispatch(fecharAlteracaoSenha());
    }
  };

  const validateExtras = () => {
    const erros = [];

    const { senhaAtual, novaSenha, confirmacaoNovaSenha } = formProps.getValues();

    if (senhaAtual === novaSenha) {
      erros.push('A senha informada não pode ser a mesma da atual.');
    }

    if (novaSenha !== confirmacaoNovaSenha) {
      erros.push('A senha informada não confere com a confirmação de senha.');
    }

    return erros;
  };

  const handleSubmit = formData =>
    new Promise<void>(resolve => {
      formData.usuario = usuarioAlteracaoSenha || dec(globalParameter.usuario.usuario);
      alterarSenha(formData, {
        errorFunction: message => {
          alertManager.emit({
            message: <div dangerouslySetInnerHTML={{ __html: message }} />,
            type: AlertType.ERROR,
          });
          resolve();
        },
        thenFunction: () => {
          alertManager.emit({
            message: 'Senha alterada com sucesso.',
            onOkClick: () => dispatch(fecharAlteracaoSenha()),
            type: AlertType.INFORMATION,
          });
          resolve();
        },
      });
    });

  return (
    <Modal isOpen={exibirAlteracaoSenha} backdrop="static" centered={true} toggle={toggle}>
      <ModalHeader className="alterarsenha-header py-2" toggle={toggle}>
        Alterar Senha {usuarioAlteracaoSenha ? ` - ${usuarioAlteracaoSenha}` : ''}
      </ModalHeader>
      <ModalBody>
        <AutoCompleteOff>
          <Label hidden={!atualizarSenhaObrigatorio}>Por questões de segurança, por favor, altere a senha padrão do usuário 'master'.</Label>
          <Row>
            <Col>
              <InputGroupInline
                id="senhaAtual"
                formProps={{ ...formProps, validation: { required: true, maxLength: 256 } }}
                type={InputType.PASSWORD}
                label="Senha atual"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroupInline
                id="novaSenha"
                formProps={{ ...formProps, validation: { required: true, maxLength: 256 } }}
                type={InputType.PASSWORD}
                label="Nova senha"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroupInline
                id="confirmacaoNovaSenha"
                formProps={{ ...formProps, validation: { required: true, maxLength: 256 } }}
                type={InputType.PASSWORD}
                label="Confirmação de Senha"
              />
            </Col>
          </Row>
        </AutoCompleteOff>
      </ModalBody>
      <ModalFooter>
        <ProcessBar
          handleSubmit={formProps.handleSubmit(handleSubmit)}
          handleClose={toggle}
          hideCancelButton={atualizarSenhaObrigatorio}
          validateExtras={validateExtras}
        />
      </ModalFooter>
    </Modal>
  );
};

export default AlterarSenhaView;
