import cx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { ChevronRight } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import { BadgePro, generateTestId } from 'summer';
import { MenuItem } from '../../../models/dtos/ger/menuItem';
import { Reducers } from '../../../store/ducks';
import { filterVisibles } from '../../../utilities/menu.util';

interface SidebarNavItemProps {
  item: MenuItem;
  isOpen: boolean;
  onClick: (event) => void;
  breadcrumbs: string[];
}

const SidebarNavItem: FC<SidebarNavItemProps> = ({ item, isOpen, onClick, breadcrumbs }) => {
  const {
    globalReducer: { currentState, globalParameter },
    grupoRelatorioReducer: { grupo: grupoRelatorio },
  } = useSelector<Reducers, Reducers>(state => state);
  const [itemOpen, setItemOpen] = useState(false);
  const [lastSubItemClicked, setLastSubItemClicked] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    setItemOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (breadcrumbs.length > 0) {
      setLastSubItemClicked(breadcrumbs[0]);
    }
  }, [breadcrumbs]);

  const toggleItem = event => {
    event.preventDefault();
    const innerText = event.currentTarget.innerText.replace(/\n/g, '');
    setLastSubItemClicked(lastSubItemClicked === innerText ? '' : innerText);
  };

  const handleClick = event => {
    onClick(event);
  };

  function getItemIcon() {
    return item.icon ? <span className={cx('sidebar-icon', item.icon)} /> : null;
  }

  function generateTestIdFromValue(val: string) {
    return generateTestId(
      val
        .toLowerCase()
        .normalize('NFD')
        .replace(/^\//g, '')
        .replace(/\//g, '-')
        .replace(/ /g, '')
        .replace(/[\u0300-\u036f]/g, '')
    );
  }

  function navDropdown() {
    return (
      <li>
        <a href="/" onClick={handleClick} className={cx({ active: itemOpen })} {...generateTestIdFromValue(item.label)}>
          {getItemIcon()}
          <span className="sidebar-item-label">{item.label}</span>
          <span className="sidebar-icon-indicator">
            <ChevronRight />
          </span>
        </a>
        <Collapse isOpen={itemOpen}>
          <ul>
            {item.content
              .filter(it => filterVisibles(it, globalParameter, currentState))
              .map((it, index) => (
                <SidebarNavItem
                  key={index}
                  item={it}
                  onClick={toggleItem}
                  isOpen={it.label === lastSubItemClicked}
                  breadcrumbs={[...breadcrumbs].splice(1)}
                />
              ))}
          </ul>
        </Collapse>
      </li>
    );
  }

  const isActive = match => {
    let active = !!match;
    if (grupoRelatorio) {
      active = item.to.indexOf(`/grupoRel/${grupoRelatorio}`) !== -1;
    }
    return active;
  };

  const execAction = (action, e) => {
    e.preventDefault();
    dispatch(action());
  };

  function navLink() {
    const url = item.to || '';
    const itemIcon = getItemIcon();
    const dataTest = generateTestIdFromValue(url || item.label);
    const calimaPro = item.calimaPro && !globalParameter.acesso.calimaPro ? <BadgePro size={30} style={{ marginLeft: 3 }} /> : null;

    let result = (
      <NavLink to={url} isActive={isActive} {...dataTest}>
        {itemIcon}
        {item.label}
        {calimaPro}
      </NavLink>
    );

    if (isExternal(url)) {
      result = (
        <a href={url} target="_blank" rel="noopener noreferrer" {...dataTest}>
          {itemIcon}
          {item.label}
        </a>
      );
    } else if (item.action) {
      result = (
        <a href="#/" onClick={execAction.bind(null, item.action)} {...dataTest}>
          {itemIcon}
          {item.label}
        </a>
      );
    }

    return <li>{result}</li>;
  }

  function isExternal(url: string) {
    const link = url ? url.substring(0, 4) : '';
    return link === 'http';
  }

  return item.content ? navDropdown() : navLink();
};

export default SidebarNavItem;
