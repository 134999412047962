import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Col, FormGroup, Row } from 'reactstrap';
import { Checkbox, compare, DetailEditForm, DetailEditViewFormProps, InputGroupInline, InputType } from 'summer';
import { ContratoMudancaCategoria } from '../../../../models/dtos/mfp/contratoMudancaCategoria';
import { CATEGORIA_ENUM_VALUES } from '../../../../models/enumerated/mfp/categoriaEnum';

const ProcessoTrabalhistaMudancaCategoriaDetailView: FC<DetailEditViewFormProps<ContratoMudancaCategoria>> = props => {
  const formProps = useForm<ContratoMudancaCategoria>({ defaultValues: props.selectedItem || {}, mode: 'onBlur' });

  const validateExtras = (formData: ContratoMudancaCategoria, _, parentObject) => {
    const erros = [];

    const { dataAdmissao } = parentObject.vinculo;
    if (compare(formData.dataMudanca, dataAdmissao) < 0) {
      erros.push(`Data da mudança deve ser igual ou posterior à data de admissão do vínculo (${dataAdmissao}).`);
    }

    return erros;
  };

  return (
    <DetailEditForm handleSubmit={props.handleSubmit} handleCancel={props.handleCancel} formProps={formProps} validateExtras={validateExtras}>
      <FormGroup tag={Row}>
        <Col>
          <Checkbox formProps={formProps} id="trabalhadorRural" label="Trabalhador rural" />
        </Col>
      </FormGroup>
      <Row>
        <Col>
          <InputGroupInline
            id="categoria"
            label="Categoria"
            formProps={{ ...formProps, validation: { required: true } }}
            inputDropDownProps={{ optionsProvider: CATEGORIA_ENUM_VALUES }}
            type={InputType.DROP_DOWN}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGroupInline
            formProps={{ ...formProps, validation: { required: true } }}
            id="dataMudanca"
            label="Data da mudança"
            type={InputType.DATE}
          />
        </Col>
      </Row>
    </DetailEditForm>
  );
};

export default ProcessoTrabalhistaMudancaCategoriaDetailView;
