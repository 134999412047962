import { isNotEmpty } from 'summer';
import { BasicEnum } from '../models/enumerated/basicEnum';

export interface EnumWithExtraFields extends BasicEnum {
  extraFields?: any;
}

const EnumUtil = {
  contains: (list: EnumWithExtraFields[], search: EnumWithExtraFields | string | number, extraField: string = null) =>
    EnumUtil.find(list, search, extraField) !== undefined,
  find: (list: EnumWithExtraFields[], search: EnumWithExtraFields | string | number, extraField: string = null) => {
    let result;

    if (isNotEmpty(search) || search === 0) {
      result = list.find(item => (extraField ? item.extraFields[extraField] : item.value) === (typeof search === 'object' ? search.value : search));
    }

    return result;
  },
  findAll: (list: EnumWithExtraFields[], search: EnumWithExtraFields | string | number, extraField: string = null) => {
    let result;

    if (isNotEmpty(search) || search === 0) {
      result = list.filter(item => (extraField ? item.extraFields[extraField] : item.value) === (typeof search === 'object' ? search.value : search));
    }

    return result;
  },
};

export default EnumUtil;
