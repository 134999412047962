import cx from 'clsx';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { proActions } from 'summer';
import calimaProBranco from '../../../assets/calimaProBranco.png';

import './ButtonCalimaPro.css';

interface ButtonCalimaProProps {
  label: string;
  className?: string;
  onClick?: () => void;
}

const ButtonCalimaPro: FC<ButtonCalimaProProps> = props => {
  const dispatch = useDispatch();

  const onClick = () => dispatch(proActions.toggleAdModal(true));

  return (
    <button className={cx('button-calima-pro', props.className)} onClick={props.onClick || onClick}>
      {props.label} <img src={calimaProBranco} alt="Calima Pro" />
    </button>
  );
};

export default ButtonCalimaPro;
