import { createLoadable } from 'summer';

const boxRoutes = [
  {
    component: createLoadable(() => import('./dashboard/DashboardBox'), 'DashboardBox'),
    exact: false,
    path: '/box/dashboard',
  },
  {
    component: createLoadable(() => import('./empresas/EmpresasSearchView'), 'EmpresasSearchView'),
    path: '/box/empresasSearchView',
  },
  {
    component: createLoadable(() => import('./manifestacaoNotas/ManifestacaoNotasSearchView'), 'ManifestacaoNotasSearchView'),
    path: '/box/manifestacaoNotasSearchView',
  },
  {
    component: createLoadable(() => import('./log/LogSearchView'), 'LogSearchView'),
    path: '/box/logSearchView',
  },
  {
    component: createLoadable(() => import('./visualizarNotas/VisualizarNotasSearchView'), 'VisualizarNotasSearchView'),
    path: '/box/visualizarNotasSearchView',
  },
  {
    component: createLoadable(() => import('./importarNotas/ImportarNotasProcessView'), 'ImportarNotasProcessView'),
    path: '/box/importarNotasProcessView',
  },
  {
    component: createLoadable(() => import('./report/notasPorPeriodo/NotasPorPeriodoReportView'), 'notasPorPeriodoReportView'),
    path: '/box/notasPorPeriodoReportView',
  },
  {
    component: createLoadable(() => import('./tokenAcessoBoxFile/TokenAcessoBoxFileSearchView'), 'TokenAcessoBoxFileSearchView'),
    path: '/box/tokenAcessoBoxFileSearchView',
  },
];

export default boxRoutes;
