import React from 'react';
import logoConnect from '../../../assets/calima_connect_icon.svg';
import logoGer from '../../../assets/calima_ger_icon.svg';
import logoMcg from '../../../assets/calima_mcg_icon.svg';
import logoMcp from '../../../assets/calima_mcp_icon.svg';
import logoMfp from '../../../assets/calima_mfp_icon.svg';
import logoMho from '../../../assets/calima_mho_icon.svg';
import logoMic from '../../../assets/calima_mic_icon.svg';
import logoMlf from '../../../assets/calima_mlf_icon.svg';
import logoMpr from '../../../assets/calima_mpr_icon.svg';
import logoSite from '../../../assets/calima_site_icon.svg';
import IconeBOX from '../../../views/components/icones/IconeBOX';
import { UsuarioAcessoModulos } from '../../dtos/ger/usuarioAcessoModulos';

export const MCG = { value: 'MCG', label: 'Módulo Contábil', extraFields: { icon: logoMcg, bitrixMarcador: 'Contábil' } };
export const MLF = { value: 'MLF', label: 'Módulo Fiscal', extraFields: { icon: logoMlf, bitrixMarcador: 'Fiscal' } };
export const MFP = { value: 'MFP', label: 'Módulo Folha de Pagamento', extraFields: { icon: logoMfp, bitrixMarcador: 'Folha de Pagamento' } };
export const MHO = { value: 'MHO', label: 'Módulo Honorário', extraFields: { icon: logoMho, bitrixMarcador: 'Honorários' } };
export const GER = { value: 'GER', label: 'Módulo Principal', extraFields: { icon: logoGer, bitrixMarcador: 'Geral/Principal' } };
export const MPR = { value: 'MPR', label: 'Módulo Protocolo', extraFields: { icon: logoMpr, bitrixMarcador: 'Protocolos' } };
export const MCP = { value: 'MCP', label: 'Módulo Controle Patrimonial', extraFields: { icon: logoMcp, bitrixMarcador: 'Controle Patrimonial' } };
export const BOX = {
  extraFields: { icon: <IconeBOX width="30" height="30" className="h1 my-2" />, bitrixMarcador: 'Box' },
  label: 'Box',
  value: 'BOX',
};
export const CONNECT = { value: 'CONNECT', label: 'Connect', extraFields: { icon: logoConnect, bitrixMarcador: 'Connect' } };
export const SITE = { value: 'SITE', label: 'Site', extraFields: { icon: logoSite, bitrixMarcador: 'Site' } };
export const MIC = { value: 'MIC', label: 'Integra Contador', extraFields: { icon: logoMic, bitrixMarcador: 'Integra Contador' } };

export const MODULO_ENUM = [MCG, MLF, MFP, MHO, GER, MPR, MCP, BOX, CONNECT, SITE, MIC];

export const getModulosComAcesso = (acessoModulos: UsuarioAcessoModulos) => {
  return MODULO_ENUM.filter(it => acessoModulos[it.value.toLowerCase()]);
};
