import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ERROR, InputGroupInline, InputType, ProcessBar, showToast } from 'summer';
import { validarAdministradorMaster } from '../../../services/ger.service';
import { Reducers } from '../../../store/ducks';
import { GlobalState } from '../../../store/ducks/global.duck';

export interface ModalSenhaAdministradorProps {
  onClose: () => void;
  onCancel: () => void;
  submit: any;
  message?: string;
  invalidEmp?: any[];
}

const ModalSenhaAdministrador: FC<ModalSenhaAdministradorProps> = props => {
  const formProps = useForm<{ senha?: string }>({ mode: 'onBlur' });
  const { globalParameter } = useSelector<Reducers, GlobalState>(state => state.globalReducer);

  const handleSubmit = formData => {
    validarAdministradorMaster(formData.senha, {
      errorFunction: mensagem => showToast(mensagem, ERROR),
      thenFunction: result => {
        if (result) {
          props.submit();
          props.onClose();
        } else {
          formProps.setValue('senha', null, true);
          formProps.setError('senha', 'required', 'Senha incorreta');
        }
      },
    });
  };

  return (
    <Modal centered={true} backdrop="static" isOpen={true} toggle={props.onCancel}>
      <ModalHeader toggle={props.onCancel} style={{ paddingBottom: 4, paddingTop: 9 }}>
        Confirmar processo
      </ModalHeader>
      <ModalBody>
        <div className="mb-1 d-flex">
          <FontAwesomeIcon className="mr-1 mt-1" color={'#F4762D'} icon="exclamation-triangle" size="lg" />
          <span className="font-weight-bold font-size-lg">Este processo foi bloqueado para este usuário.</span>
        </div>
        {props.message && (
          <div className="my-3">
            <span className="font-size-md">{props.message}</span>
          </div>
        )}
        {props.invalidEmp && (
          <div className="my-3">
            <span className="font-size-md">
              As seguintes empresas estão fora do período de bloqueio deste usuário:{' '}
              <strong>{props.invalidEmp.map(emp => emp.razaoSocial).join(', ')}</strong>
            </span>
          </div>
        )}
        <div className="mb-2 mt-1 ">
          <span className="font-size-md">Para continuar, insira a senha do administrador principal:</span>
        </div>
        <div className="d-flex flex-column mb-2">
          Administrador
          <span className="font-weight-bold opacity-7">{globalParameter.usuarioMaster.nomeCompleto}</span>
        </div>
        <InputGroupInline id="senha" formProps={{ ...formProps, validation: { required: true } }} label="Senha" type={InputType.PASSWORD} />
      </ModalBody>
      <ModalFooter>
        <ProcessBar
          handleSubmit={formProps.handleSubmit(handleSubmit)}
          handleClose={props.onCancel}
          hideMessageLastTimeSaved={true}
          btnSubmitIcon="check"
          btnSubmitLabel="Confirmar"
        />
      </ModalFooter>
    </Modal>
  );
};

export default ModalSenhaAdministrador;
