import React, { useEffect, useRef, useState } from 'react';
import { ERROR, eventManager, EventType, showToast, SUCCESS } from 'summer';
import useLoading from '../../../hooks/useLoading';
import { GRUPO_REL_UPDATE_EVENT } from '../../../models/dtos/ger/grupoRelatorio';
import { addRelatorioNoGrupo } from '../../../services/ger.service';
import GrupoRelatorioModalView from '../../pages/ger/grupoRelatorio/GrupoRelatorioModalView';

const GrupoRelControl = () => {
  const [extraContent, setExtraContent] = useState(null);
  const relatorioSelecionado = useRef<string>(null);
  const loading = useLoading();

  useEffect(
    () => {
      eventManager.on(EventType.GRUPO_REL_OPEN, onGrupoRelatorioClick);
      return () => eventManager.off(EventType.GRUPO_REL_OPEN);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const toggle = (grupo: any) => {
    setExtraContent(null);
    if (grupo) {
      loading.show();
      addRelatorioNoGrupo(
        grupo.id,
        { relatorio: relatorioSelecionado.current },
        {
          errorFunction: mensagem => {
            showToast(mensagem, ERROR);
            loading.hide();
          },
          thenFunction: () => {
            eventManager.emit(GRUPO_REL_UPDATE_EVENT);
            showToast('Relatório adicionado com sucesso!', SUCCESS);
            loading.hide();
          },
        }
      );
    }
  };

  const onGrupoRelatorioClick = (modulo, relatorio) => {
    relatorioSelecionado.current = relatorio;
    setExtraContent(<GrupoRelatorioModalView toggle={toggle} />);
  };

  return <>{extraContent}</>;
};

export default GrupoRelControl;
