export const JANEIRO = { label: 'Janeiro', value: 1 };
export const FEVEREIRO = { label: 'Fevereiro', value: 2 };
export const MARCO = { label: 'Março', value: 3 };
export const ABRIL = { label: 'Abril', value: 4 };
export const MAIO = { label: 'Maio', value: 5 };
export const JUNHO = { label: 'Junho', value: 6 };
export const JULHO = { label: 'Julho', value: 7 };
export const AGOSTO = { label: 'Agosto', value: 8 };
export const SETEMBRO = { label: 'Setembro', value: 9 };
export const OUTUBRO = { label: 'Outubro', value: 10 };
export const NOVEMBRO = { label: 'Novembro', value: 11 };
export const DEZEMBRO = { label: 'Dezembro', value: 12 };

export const MES_ENUM_VALUES = [JANEIRO, FEVEREIRO, MARCO, ABRIL, MAIO, JUNHO, JULHO, AGOSTO, SETEMBRO, OUTUBRO, NOVEMBRO, DEZEMBRO];

export const JANEIRO_STRING = { label: 'Janeiro', value: '01' };
export const FEVEREIRO_STRING = { label: 'Fevereiro', value: '02' };
export const MARCO_STRING = { label: 'Março', value: '03' };
export const ABRIL_STRING = { label: 'Abril', value: '04' };
export const MAIO_STRING = { label: 'Maio', value: '05' };
export const JUNHO_STRING = { label: 'Junho', value: '06' };
export const JULHO_STRING = { label: 'Julho', value: '07' };
export const AGOSTO_STRING = { label: 'Agosto', value: '08' };
export const SETEMBRO_STRING = { label: 'Setembro', value: '09' };
export const OUTUBRO_STRING = { label: 'Outubro', value: '10' };
export const NOVEMBRO_STRING = { label: 'Novembro', value: '11' };
export const DEZEMBRO_STRING = { label: 'Dezembro', value: '12' };

export const MES_STRING_ENUM_VALUES = [
  JANEIRO_STRING,
  FEVEREIRO_STRING,
  MARCO_STRING,
  ABRIL_STRING,
  MAIO_STRING,
  JUNHO_STRING,
  JULHO_STRING,
  AGOSTO_STRING,
  SETEMBRO_STRING,
  OUTUBRO_STRING,
  NOVEMBRO_STRING,
  DEZEMBRO_STRING,
];
