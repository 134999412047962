import { createLoadable } from 'summer';

const connectRoutes = [
  {
    component: createLoadable(() => import('./dashboard/DashboardConnect'), 'DashboardConnect'),
    exact: false,
    path: '/connect/dashboard',
  },
  {
    component: createLoadable(() => import('../connect/connectSolicitacao/SolicitacaoProcessView'), 'SolicitacaoProcessView'),
    path: '/connect/solicitacaoProcessView',
  },
  {
    component: createLoadable(() => import('../connect/connectSolicitacao/solicitacao/SolicitacaoEditProcessView'), 'SolicitacaoEditProcessView'),
    path: '/connect/solicitacaoEditProcessView',
  },
];

export default connectRoutes;
