import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import {
  consultarSituacao,
  CONTAINER_STATUS_OK,
  createLoadable,
  dec,
  dockActions,
  editFormActions,
  environment,
  ERROR,
  gaPageView,
  getRandomUUID,
  initializeGA,
  proActions,
  showToast,
  SuspenseLoading,
  TEMA_ENUM_VALUES,
  useLocalStorage,
  userActions,
  writeInSessionStorage,
} from 'summer';
import { LockAdminProvider } from './hooks/useLockAdmin';
import { checkUsuarioVisualizouTour, obterGlobalParameter, registrarAcessoRecente } from './services/ger.service';
import { grupoRelatorioActions, histAcessoActions, Reducers } from './store/ducks';
import { setCurrentState, setGlobalParameter, setSystemVersion } from './store/ducks/global.duck';
import { actions as multiPageActions } from './store/ducks/multipage.duck';
import { LOCAL_STORAGE_LOGIN_TOKEN, LOCAL_STORAGE_SOCKET_HASH } from './utilities/constantes';
import EnumUtil from './utilities/enum.util';
import { redirectToLogin, validarVersaoFront } from './utilities/login.util';
import { getRoutes } from './utilities/menu.util';
import EditViewHandler from './views/components/editViewHandler/EditViewHandler';
import MultiPage from './views/components/multiPage/MultiPage';
import Sidebar from './views/components/sidebar/Sidebar';
import NotFound from './views/pages/notFound/NotFound';

const LoginForm = createLoadable(() => import('./views/pages/login/LoginRouter'), 'LoginForm');

const pageVariants = {
  in: {
    opacity: 1,
    scale: 1,
  },
  initial: {
    opacity: 0,
    scale: 0.99,
  },
  out: {
    opacity: 0,
    scale: 1.01,
  },
};

const pageTransition = {
  duration: 0.4,
  ease: 'anticipate',
  type: 'tween',
};

declare global {
  interface Window {
    PROJETUS_CRISP?: any;
    UTILITARIO_CALIMA_INSTANCE?: any;
  }
}

const Routes: FC = () => {
  const {
    globalReducer: { globalParameter, currentState },
    grupoRelatorioReducer: { list: listaRelatorios },
    userReducer: { tema },
  } = useSelector<Reducers, Reducers>(state => state);

  const [clienteStorage] = useLocalStorage('u');
  const [versionStorage] = useLocalStorage('v');
  const [tokenStorage, writeTokenStorage] = useLocalStorage(LOCAL_STORAGE_LOGIN_TOKEN);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(
    () => {
      initializeGA('UA-159160015-1');
      const unlisten = gaPageView(history);
      writeInSessionStorage(LOCAL_STORAGE_SOCKET_HASH, getRandomUUID());

      return () => unlisten();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    dispatch(dockActions.destroyDock());
  }, [dispatch, history.location]);

  useEffect(() => {
    if (globalParameter && globalParameter.usuario) {
      const data = history.location.pathname
        .substr(1)
        .split('/')
        .filter(it => !!it);
      if (data.length >= 2) {
        registrarAcessoRecente(globalParameter.usuario.id, data[0].toUpperCase(), data[data.length - 1], {
          errorFunction: message => showToast(message, ERROR),
          thenFunction: res => {
            dispatch(histAcessoActions.setRecents(res));
          },
        });
      }

      dispatch(userActions.setTheme(globalParameter.usuario.configuracao.tema));
    }
  }, [dispatch, history.location, globalParameter]);

  useEffect(
    () => {
      const url = history.location.pathname.substr(1);
      const modulo = url.substr(0, url.indexOf('/')).toUpperCase();

      if (modulo !== currentState) {
        dispatch(setCurrentState(modulo));
      }

      if (listaRelatorios && listaRelatorios.length && listaRelatorios.map(it => it.to).indexOf(history.location.pathname) === -1) {
        dispatch(grupoRelatorioActions.clear());
      }

      dispatch(multiPageActions.clearPage());
      dispatch(editFormActions.resetLastTimeSaved());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, history.location.pathname]
  );

  const getError = mensagem => {
    showToast(mensagem, ERROR);
    redirectToLogin();
  };

  useEffect(
    () => {
      if (!globalParameter && clienteStorage && history.location.search.indexOf('p1=') === -1) {
        const usuario = JSON.parse(clienteStorage);
        const codigoCliente = dec(usuario.codigoCliente);

        if (environment.production) {
          environment.siteURL = dec(usuario.env);
        }

        consultarSituacao({
          codigoCliente,
          dataValidacaoCPF: '',
          errorFunc: getError,
          resultFunc: async (result, tokenAcesso) => {
            if (result.containerStatus === CONTAINER_STATUS_OK) {
              const validarVersao = await validarVersaoFront();
              if (validarVersao) {
                writeTokenStorage(tokenAcesso);
                obterGlobalParameter(
                  dec(usuario.acesso),
                  {
                    codigoCliente,
                    login: usuario.usuario,
                    senha: dec(usuario.senha),
                  },
                  {
                    errorFunction: mensagem => getError(mensagem),
                    thenFunction: globalParameterResult => {
                      if (window.PROJETUS_CRISP && window.PROJETUS_CRISP.hide) {
                        window.PROJETUS_CRISP.hide();
                      }

                      dispatch(setSystemVersion(dec(versionStorage)));
                      dispatch(setGlobalParameter(globalParameterResult));
                      dispatch(
                        userActions.login({
                          codigoCliente,
                          tema: globalParameterResult.usuario.configuracao.tema,
                          usuario: usuario.usuario,
                        })
                      );

                      dispatch(proActions.setIsPro(globalParameterResult.acesso.calimaPro));

                      if (globalParameterResult.acesso.calimaPro) {
                        checkUsuarioVisualizouTour(globalParameterResult.usuario.id, 'BEM_VINDO_PRO', {
                          errorFunction: mensagem => showToast(mensagem, ERROR),
                          thenFunction: visualizou => {
                            if (!visualizou) {
                              dispatch(proActions.toggleWellcomeModal(true));
                            }
                          },
                        });
                      }

                      setTimeout(() => {
                        if (history.location.pathname === '/') {
                          history.push('/ger/dashboard');
                        }
                      }, 200);
                    },
                  }
                );
              } else {
                redirectToLogin();
              }
            }
          },
          tokenAcesso: tokenStorage,
          warningFunc: getError,
        });
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [globalParameter]
  );

  useEffect(() => {
    document.querySelector('body').className = EnumUtil.find(TEMA_ENUM_VALUES, tema).extraFields.className;
  }, [tema]);

  const refreshPageOrGoToLogin = () => (clienteStorage && history.location.search.indexOf('p1=') === -1 ? <SuspenseLoading /> : <Redirect to="/" />);

  const render = (component, props) => <EditViewHandler component={component} routeProps={props} />;

  const getRoute = (it, i) => {
    const { component, exact, ...routeProps } = it;
    return routeProps.path.indexOf('/edit') === -1 ? (
      <Route key={i} component={component} exact={exact} {...routeProps} />
    ) : (
      <Route key={i} {...routeProps} render={render.bind(null, component)} />
    );
  };

  return (
    <AnimatePresence>
      <Suspense fallback={<SuspenseLoading />}>
        <Switch>
          <Route exact={true} path="/">
            <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
              {clienteStorage && history.location.search.indexOf('p1=') === -1 ? (
                <SuspenseLoading />
              ) : (
                <Route exact={true} path="/" component={LoginForm} />
              )}
            </motion.div>
          </Route>

          <Route>
            {globalParameter ? (
              <LockAdminProvider>
                <Sidebar>
                  <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
                    <MultiPage>
                      <Switch>
                        {getRoutes(globalParameter).map(getRoute)}
                        <Route path="*" component={NotFound} />
                      </Switch>
                    </MultiPage>
                  </motion.div>
                </Sidebar>
              </LockAdminProvider>
            ) : (
              refreshPageOrGoToLogin()
            )}
          </Route>
        </Switch>
      </Suspense>
    </AnimatePresence>
  );
};

export default Routes;
