import { createLoadable } from 'summer';

const mhoRoutes = [
  {
    component: createLoadable(() => import('./dashboard/DashboardMHO'), 'DashboardMHO'),
    path: '/mho/dashboard',
  },
  {
    component: createLoadable(() => import('./report/fluxoCaixa/FluxoCaixaMHOReportView'), 'FluxoCaixaMHOReportView'),
    path: '/mho/fluxoCaixaMHOReportView',
  },
  {
    component: createLoadable(() => import('./historicoPagar/HistoricoPagarMHOEditView'), 'HistoricoPagarMHOEditView'),
    path: '/mho/historicoPagarMHOSearchView/edit',
  },
  {
    component: createLoadable(() => import('./historicoPagar/HistoricoPagarMHOSearchView'), 'HistoricoPagarMHOSearchView'),
    exact: true,
    path: '/mho/historicoPagarMHOSearchView',
  },
  {
    component: createLoadable(() => import('./contaPagar/ContaPagarMHOEditView'), 'ContaPagarMHOEditView'),
    path: '/mho/contaPagarMHOSearchView/edit',
  },
  {
    component: createLoadable(() => import('./contaPagar/ContaPagarMHOSearchView'), 'ContaPagarMHOSearchView'),
    exact: true,
    path: '/mho/contaPagarMHOSearchView',
  },
  {
    component: createLoadable(() => import('./codigoTributacaoServico/CodigoTributacaoServicoEditView'), 'CodigoTributacaoServicoEditView'),
    path: '/mho/codigoTributacaoServicoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./codigoTributacaoServico/CodigoTributacaoServicoSearchView'), 'CodigoTributacaoServicoSearchView'),
    exact: true,
    path: '/mho/codigoTributacaoServicoSearchView',
  },
  {
    component: createLoadable(() => import('./listaServico/ListaServicoEditView'), 'ListaServicoEditView'),
    path: '/mho/listaServicoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./listaServico/ListaServicoSearchView'), 'ListaServicoSearchView'),
    exact: true,
    path: '/mho/listaServicoSearchView',
  },
  {
    component: createLoadable(() => import('./evento/EventoMHOEditView'), 'EventoMHOEditView'),
    path: '/mho/eventoMHOSearchView/edit',
  },
  {
    component: createLoadable(() => import('./evento/EventoMHOSearchView'), 'EventoMHOSearchView'),
    exact: true,
    path: '/mho/eventoMHOSearchView',
  },
  {
    component: createLoadable(() => import('./configEmpresa/ConfigEmpresaMHOSearchView'), 'ConfigEmpresaMHOSearchView'),
    exact: true,
    path: '/mho/configEmpresaMHOSearchView',
  },
  {
    component: createLoadable(() => import('./configEmpresa/ConfigEmpresaMHOEditView'), 'ConfigEmpresaMHOEditView'),
    exact: true,
    path: '/mho/configEmpresaMHOSearchView/edit',
  },
  {
    component: createLoadable(() => import('./report/reciboHonorario/ReciboHonorarioReportView'), 'ReciboHonorarioReportView'),
    exact: true,
    path: '/mho/reciboHonorarioReportView',
  },
  {
    component: createLoadable(() => import('./report/contaPagar/ContaPagarMHOReportView'), 'ContaPagarMHOReportView'),
    exact: true,
    path: '/mho/contaPagarMHOReportView',
  },
  {
    component: createLoadable(() => import('./report/totalFaturamentoEvento/TotalFaturamentoEventoReportView'), 'TotalFaturamentoEventoReportView'),
    exact: true,
    path: '/mho/totalFaturamentoEventoReportView',
  },
  {
    component: createLoadable(() => import('./report/totalFaturamento/TotalFaturamentoReportView'), 'TotalFaturamentoReportView'),
    exact: true,
    path: '/mho/totalFaturamentoReportView',
  },
  {
    component: createLoadable(() => import('./contabilizacaoEventoMHO/ContabilizacaoEventoEditView'), 'ContabilizacaoEventoEditView'),
    path: '/mho/contabilizacaoEventoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./contabilizacaoEventoMHO/ContabilizacaoEventoSearchView'), 'ContabilizacaoEventoSearchView'),
    exact: true,
    path: '/mho/contabilizacaoEventoSearchView',
  },
  {
    component: createLoadable(() => import('./report/cartaCobranca/CartaCobrancaReportView'), 'CartaCobrancaReportView'),
    exact: true,
    path: '/mho/cartaCobrancaReportView',
  },
  {
    component: createLoadable(() => import('./customizacaoTermos/CustomizacaoTermoMHOEditView'), 'CustomizacaoTermoMHOEditView'),
    path: '/mho/customizacaoTermoMHOSearchView/edit',
  },
  {
    component: createLoadable(() => import('./customizacaoTermos/CustomizacaoTermoMHOSearchView'), 'CustomizacaoTermoMHOSearchView'),
    exact: true,
    path: '/mho/customizacaoTermoMHOSearchView',
  },
  {
    component: createLoadable(() => import('./report/boletoExportados/BoletoExportadosReportView'), 'BoletoExportadosReportView'),
    exact: true,
    path: '/mho/boletoExportadosReportView',
  },
  {
    component: createLoadable(
      () => import('./report/contratoPrestacaoServico/ContratoPrestacaoServicoReportView'),
      'ContratoPrestacaoServicoReportView'
    ),
    exact: true,
    path: '/mho/contratoPrestacaoServicoReportView',
  },
  {
    component: createLoadable(() => import('./administradoraMHO/AdministradoraMHOEditView'), 'AdministradoraMHOEditView'),
    path: '/mho/administradoraMHOEditView',
  },
  {
    component: createLoadable(() => import('./reajusteValoresFixos/ReajusteValoresFixosProcessView'), 'ReajusteValoresFixosProcessView'),
    exact: true,
    path: '/mho/reajusteValoresFixosProcessView',
  },
  {
    component: createLoadable(() => import('./report/baixaHonorario/BaixaHonorarioReportView'), 'BaixaHonorarioReportView'),
    exact: true,
    path: '/mho/baixaHonorarioReportView',
  },
  {
    component: createLoadable(() => import('./calcularHonorario/CalcularHonorarioProcessView'), 'CalcularHonorarioProcessView'),
    exact: true,
    path: '/mho/calcularHonorarioProcessView',
  },
  {
    component: createLoadable(() => import('./fechamentoRestauracaoMHO/FechamentoRestauracaoMHOEditView'), 'FechamentoRestauracaoMHOEditView'),
    exact: true,
    path: '/mho/fechamentoRestauracaoMHOEditView',
  },
  {
    component: createLoadable(() => import('./configBoleto/ConfigBoletoMHOSearchView'), 'ConfigBoletoMHOSearchView'),
    path: '/mho/configBoletoMHOSearchView',
  },
  {
    component: createLoadable(
      () => import('./consultaCalculosHonorario/ConsultarCalculosHonorarioProcessView'),
      'ConsultarCalculosHonorarioProcessView'
    ),
    exact: true,
    path: '/mho/consultarCalculosHonorarioProcessView',
  },
  {
    component: createLoadable(() => import('./configAdministradoraMHO/ConfigAdministradoraMHOEditView'), 'ConfigAdministradoraMHOEditView'),
    exact: true,
    path: '/mho/configAdministradoraMHOEditView',
  },
  {
    component: createLoadable(() => import('./movimentacaoMensal/MovimentacaoMensalSearchView'), 'MovimentacaoMensalSearchView'),
    exact: true,
    path: '/mho/movimentacaoMensalSearchView',
  },
  {
    component: createLoadable(() => import('./movimentacaoMensal/MovimentacaoMensalEditView'), 'MovimentacaoMensalEditView'),
    exact: true,
    path: '/mho/movimentacaoMensalSearchView/edit',
  },
  {
    component: createLoadable(() => import('../mlf/multa/MultaSearchView'), 'MultaSearchView'),
    exact: true,
    path: '/mho/multaSearchView',
  },
  {
    component: createLoadable(() => import('../mlf/multa/MultaEditView'), 'MultaEditView'),
    exact: true,
    path: '/mho/multaSearchView/edit',
  },
  {
    component: createLoadable(() => import('../mlf/selic/SelicSearchView'), 'SelicSearchView'),
    exact: true,
    path: '/mho/selicSearchView',
  },
  {
    component: createLoadable(() => import('../mlf/selic/SelicEditView'), 'SelicEditView'),
    exact: true,
    path: '/mho/selicSearchView/edit',
  },
  {
    component: createLoadable(() => import('./emissaoNfse/EmissaoNfseProcessView'), 'EmissaoNfseProcessView'),
    exact: true,
    path: '/mho/emissaoNfseProcessView',
  },
  {
    component: createLoadable(() => import('./baixaHonorario/BaixaHonorarioProcessView'), 'BaixaHonorarioProcessView'),
    exact: true,
    path: '/mho/baixaHonorarioProcessView',
  },
  {
    component: createLoadable(() => import('./gerarRemessaMHO/GerarRemessaMHOSearchView'), 'GerarRemessaMHOSearchView'),
    exact: true,
    path: '/mho/gerarRemessaMHOSearchView',
  },
  {
    component: createLoadable(() => import('./gerarRemessaMHO/GerarRemessaMHOEditView'), 'GerarRemessaMHOEditView'),
    exact: true,
    path: '/mho/gerarRemessaMHOSearchView/edit',
  },
  {
    component: createLoadable(() => import('./gerarRemessaMHO/MovimentacaoMensalMHOSearchView'), 'MovimentacaoMensalMHOSearchView'),
    exact: true,
    path: '/mho/movimentacaoMensalMHOSearchView',
  },
  {
    component: createLoadable(() => import('./baixaHonorario/baixaHonorarioTipo/BaixaHonorarioTipoEditView'), 'BaixaHonorarioTipoEditView'),
    exact: true,
    path: '/mho/baixaHonorarioTipoEditView',
  },
  {
    component: createLoadable(
      () => import('./importacaoContasPagarExcel/ImportacaoContasPagarExcelProcessView'),
      'ImportacaoContasPagarExcelProcessView'
    ),
    exact: true,
    path: '/mho/importacaoExcelContasPagarProcessView',
  },
  {
    component: createLoadable(() => import('./dashboard/DashboardNitrus'), 'DashboardNitrus'),
    path: '/mho/dashboardnitrus',
  },
  {
    component: createLoadable(() => import('./integracaoCalimaNitrus/IntegracaoNitrusView'), 'IntegracaoNitrusView'),
    exact: true,
    path: '/mho/integracaoNitrusView',
  },
  {
    component: createLoadable(() => import('./report/consentimentoLGPD/ConsentimentoLGPDReportView'), 'ConsentimentoLGPDReportView'),
    path: '/mho/consentimentoLGPDReportView',
  },
];

export default mhoRoutes;
