import React, { FC, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { blockAccessActions, blockAccessState } from 'summer';
import modalBlock from '../../../../assets/modalBlock.png';
import { useCrispAction } from '../../../../hooks/useCrispAction';
import { Reducers } from '../../../../store/ducks';
import FullSizeModal, { FullSizeModalRef } from '../../../components/fullSizeModal/FullSizeModal';

import './ModalAdBlockAccess.css';

const ModalAdBlockAccess: FC = () => {
  const { showModal } = useSelector<Reducers, blockAccessState>(state => state.blockAccessReducer);

  const modal = useRef<FullSizeModalRef>();
  const dispatch = useDispatch();
  const crispAction = useCrispAction();

  const onClose = () => dispatch(blockAccessActions.toggleModal(false));

  const onContratar = e => {
    e.preventDefault();
    modal.current.close();
    crispAction.openComercial();
  };

  return (
    showModal && (
      <FullSizeModal className="modal-block-access" onClose={onClose} ref={modal} aspectRatio={1.5399}>
        <div className="slide">
          <img src={modalBlock} alt="" />
        </div>
        <div className="ad-footer">
          <a href="https://www.calimaerp.com/pro" target="_blank" rel="noopener noreferrer">
            Conheça nossos planos
          </a>
          <button onClick={onContratar}>Contratar</button>
        </div>
      </FullSizeModal>
    )
  );
};

export default ModalAdBlockAccess;
