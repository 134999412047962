import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import IFrame from 'react-frame-component';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Alert } from 'reactstrap';
import { SuspenseLoading } from 'summer';
import { useCrispAction } from '../../../../hooks/useCrispAction';
import { Reducers } from '../../../../store/ducks';
import { GlobalState, setTaExibindoPropaganda } from '../../../../store/ducks/global.duck';
import { URL_CONTROLLER_PROPAGANDA } from '../../../../utilities/wsUtil';

import './PropagandaView.css';

const FRAME_HTML =
  '<!DOCTYPE html><html><head><style>body { margin: 0; overflow: hidden; }</style></head><body><div class="frame-root"></div></body></html>';

const PropagandaView: FC = () => {
  const {
    globalParameter: { acesso },
    taExibindoPropaganda,
  } = useSelector<Reducers, GlobalState>(state => state.globalReducer);

  const dispatch = useDispatch();
  const crisp = useCrispAction();

  const [htmlContent, setHtmlContent] = useState(null);

  useEffect(
    () => {
      if (acesso) {
        axios.get(URL_CONTROLLER_PROPAGANDA + acesso.codigoCliente).then(response => setHtmlContent(response.data));
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const hideScreen = () => dispatch(setTaExibindoPropaganda(false));

  const onClick = () => {
    hideScreen();
    crisp.openComercial();
  };

  return (
    <Modal centered={true} backdrop="static" isOpen={taExibindoPropaganda} toggle={hideScreen} size="lg" className="modal-propaganda">
      <ModalHeader toggle={hideScreen}>Projetus TI - Propaganda</ModalHeader>
      <ModalBody>
        {htmlContent ? (
          <>
            <Alert color="info" className="modal-propaganda-alert text-center" onClick={onClick}>
              <FontAwesomeIcon icon="times" /> Remover Propagandas!
            </Alert>
            <div className="frame-wrapper">
              <IFrame initialContent={FRAME_HTML}>{ReactHtmlParser(htmlContent, {})}</IFrame>
              <div className="frame-mask" onClick={onClick} />
            </div>
          </>
        ) : (
          <SuspenseLoading message="Carregando..." />
        )}
      </ModalBody>
      <ModalFooter className="justify-content-end">
        <Button size="sm" onClick={hideScreen} color="danger">
          <span>Fechar</span>
          <FontAwesomeIcon icon="times" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PropagandaView;
