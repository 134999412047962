import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { ERROR, navegarWikiController, showToast, SUCCESS } from 'summer';
import logoMic from '../../../assets/calima_mic_icon.svg';
import useLoading from '../../../hooks/useLoading';
import { ConfiguracaoMIC } from '../../../models/dtos/mic/configuracaoMIC';
import { atualizarGlobalParameter, checkVisualizouTour, salvarUsuarioVisualizouTour } from '../../../services/ger.service';
import { salvarConfiguracaoGeral } from '../../../services/mic.service';
import { Reducers } from '../../../store/ducks';
import { GlobalState, setGlobalParameter } from '../../../store/ducks/global.duck';
import { defaultValues } from '../../pages/mic/configuracao/ConfiguracaoView';

import './HabilitarAutomatizacaoIntegraContadorModalView.css';

const itemTour = 'NOTIFICACAO_HABILITAR_AUTOMATIZACAO_MIC';

const HabilitarAutomatizacaoIntegraContadorModalView: FC = () => {
  const { currentState, globalParameter } = useSelector<Reducers, GlobalState>(state => state.globalReducer);
  const [exibir, setExibir] = useState(false);

  const loading = useLoading();
  const dispatch = useDispatch();

  useEffect(
    () => {
      if (globalParameter && globalParameter.usuario && globalParameter.acesso) {
        if (currentState === 'MIC' && globalParameter.usuario.administrador && globalParameter.acesso.calimaPro) {
          checkVisualizouTour(itemTour, {
            errorFunction: message => showToast(message, ERROR),
            thenFunction: res => {
              setExibir(!res);
            },
          });
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [globalParameter, currentState]
  );

  const errorFunction = (mensagem: string) => {
    loading.hide();
    showToast(mensagem, ERROR);
  };

  const habilitar = () => {
    loading.show();
    fechar();
    const configuracaoMIC: ConfiguracaoMIC = {
      ...globalParameter.configuracaoMIC,
      ...defaultValues,
    };
    salvarConfiguracaoGeral(configuracaoMIC, false, {
      errorFunction,
      thenFunction: () => {
        atualizarGlobalParameter(globalParameter.usuario, {
          errorFunction,
          thenFunction: result => {
            loading.hide();
            dispatch(setGlobalParameter(result));
            showToast('Automatização do Integra Contador habilitada com sucesso!', SUCCESS);
          },
        });
      },
    });
  };

  const fechar = () => {
    toggle();
    salvarUsuarioVisualizouTour(globalParameter.usuario.id, itemTour, {
      errorFunction,
      thenFunction: () => null,
    });
  };

  const toggle = () => setExibir(!exibir);

  const openAjudaMIC = async () => await navegarWikiController('ajudaInicialMIC');

  return (
    exibir && (
      <Modal isOpen={true} centered={true} size="lg" backdrop="static">
        <ModalBody>
          <div className="text-center mx-3 mt-3">
            <div>
              <img src={logoMic} alt="Integra Contador Icon" className="mb-3 modal-habilitar-automatizacao-icon" />
            </div>
            <strong className="fa-lg">Deseja habilitar os robôs de geração, consulta e busca de dados do Integra Contador?</strong>
            <div className="mt-3 mb-1">
              <Button onClick={habilitar} color="primary" size="sm" className="btn-habilitar-agora">
                Sim, desejo habilitar agora
              </Button>
            </div>
            <div>
              <Button onClick={fechar} color="link" size="sm" className="btn-habilitar-mais-tarde mt-2">
                <small className="font-weight-bold">Não, irei habilitar manualmente mais tarde</small>
              </Button>
            </div>
            <div>
              <Button onClick={toggle} color="link" size="sm">
                <small>
                  <u>Perguntar novamente mais tarde</u>
                </small>
              </Button>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="modal-habilitar-automatizacao-footer" onClick={openAjudaMIC}>
          <strong>Clique aqui e saiba mais sobre o Integra Contador</strong>
          <FontAwesomeIcon icon="external-link-square-alt" />
        </ModalFooter>
      </Modal>
    )
  );
};

export default HabilitarAutomatizacaoIntegraContadorModalView;
