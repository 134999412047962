import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import { Cancelable } from 'lodash';
import debounce from 'lodash.debounce';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Select, { components } from 'react-select';
import { BeatLoader } from 'react-spinners';
import { Button, Dropdown, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, UncontrolledPopover, UncontrolledTooltip } from 'reactstrap';
import {
  adjustExpressions,
  clearSearchDataGridStore,
  cnpjFormatter,
  ComparatorEnum,
  cpfFormatter,
  ERROR,
  findAllByExpression,
  generateTestId,
  OperationEnum,
  Option,
  refToFormat,
  SearchCriteria,
  SearchViewModal,
  showToast,
  TEMA_ENUM_VALUES,
  useLocalStorage,
} from 'summer';
import { CLASS_NAME, EmpresaVW } from '../../../models/dtos/ger/empresaVW';
import { UsuarioEmpresaAtual } from '../../../models/dtos/ger/usuarioEmpresaAtual';
import { salvarUsuarioEmpresaAtual } from '../../../services/ger.service';
import { Reducers } from '../../../store/ducks';
import { referenciaFiscal, setGlobalParameter } from '../../../store/ducks/global.duck';
import { atualizarEmpresaGlobalParameter } from '../../../utilities/ger.util';
import { checkContabilistaResponsavel } from '../../../utilities/mlf.util';

import './HeaderCompany.css';

const DEBOUNCE_TIMEOUT = 500;
const SEARCH_CRITERIA: SearchCriteria = {
  className: CLASS_NAME,
  expressions: [
    {
      expressions: [
        {
          expressions: [],
          fieldName: 'paralisada',
          value: false,
        },
      ],
      fieldName: 'inativa',
      value: false,
    },
  ],
  fetchType: 'PAGED',
  max: 10,
  sortFieldName: 'codigo',
  start: 0,
};

type EmpresaOption = EmpresaVW & Option;

const MODULOS_SEM_EMPRESA = ['MHO', 'CONNECT', 'MIC'];
const MODULOS_SEM_REFERENCIA = ['CONNECT', 'GER', 'MPR'];
const TODOS = 'Todos';

const HeaderCompany: FC = () => {
  const {
    globalReducer: { currentState, globalParameter },
    userReducer: { tema },
  } = useSelector<Reducers, Reducers>(state => state);

  const dispatch = useDispatch();
  const history = useHistory();

  const debounceRef = useRef<((search: string) => void) & Cancelable>();
  const selectRef = useRef<Select>();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenFiltros, setIsOpenFiltros] = useState(false);
  const [filtroEmpresa, writeFiltroEmpresa] = useLocalStorage('filtroEmpresa');
  const [activeFiltro, setActiveFiltro] = useState(filtroEmpresa || TODOS);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [tmpValue, setTmpValue] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [empresas, setEmpresas] = useState<EmpresaOption[]>([]);

  const listagemCompleta = !globalParameter || !globalParameter.usuario.empresas || globalParameter.usuario.empresas.length === 0;
  const showEmpresa = MODULOS_SEM_EMPRESA.indexOf(currentState) === -1;
  const showReferencia = MODULOS_SEM_REFERENCIA.indexOf(currentState) === -1;

  useEffect(
    () => {
      debounceRef.current = debounce(onSearch, DEBOUNCE_TIMEOUT);
      return () => debounceRef.current.flush();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      findEmpresas();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchValue, currentState]
  );

  const getSearchCriteria = (expressions = null) => {
    const criteria = { ...SEARCH_CRITERIA };
    if (expressions) {
      criteria.expressions[0].expressions[0].expressions = expressions;
    } else {
      criteria.expressions[0].expressions[0].expressions = [];
    }
    return criteria;
  };

  const findEmpresas = async () => {
    setIsLoading(true);
    const criteria = getSearchCriteria();
    if (searchValue) {
      if (activeFiltro === TODOS) {
        criteria.expressions[0].expressions[0].expressions = filtroItems.filter(it => it.label !== TODOS).map(it => it.expression);
      } else {
        criteria.expressions[0].expressions[0].expressions = [getActiveFiltro().expression];
      }
      criteria.fetchType = 'ALL';
    }
    setEmpresas(await find(criteria));
    setIsLoading(false);
  };

  const changeCurrentEmpresa = useCallback(
    (value: EmpresaOption) => {
      const usuarioEmpresaAtual: UsuarioEmpresaAtual = {
        empresaAtual: value[0] ? value[0] : value,
        id: globalParameter.usuario.id,
      };

      setIsOpen(false);

      salvarUsuarioEmpresaAtual(usuarioEmpresaAtual, {
        errorFunction: mensagem => {
          showToast(mensagem, ERROR);
        },
        thenFunction: result => {
          dispatch(setGlobalParameter(atualizarEmpresaGlobalParameter(globalParameter, result)));
          clearSearchDataGridStore();

          setTimeout(() => {
            if (currentState === 'MLF' && history.location.pathname === '/mlf/dashboard') {
              checkContabilistaResponsavel(result.empresa.empresaMLF);
            }
            history.push(`/${currentState.toLowerCase()}/dashboard`);
          }, 500);
        },
      });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentState, globalParameter]
  );

  const getRef = (empresa: EmpresaVW) => {
    let result;
    switch (currentState) {
      case 'MCG':
        result = empresa.referenciaMCG;
        break;
      case 'MCP':
        result = empresa.referenciaMCP;
        break;
      case 'MFP':
        result = empresa.referenciaMFP;
        break;
      case 'MLF':
        result = empresa.referenciaMLF;
        break;
    }
    return result ? ` - Ref.: ${refToFormat(result)}` : '';
  };

  const find = criteria =>
    new Promise<EmpresaOption[]>((resolve, reject) => {
      if (!listagemCompleta) {
        criteria.expressions[0].expressions = [
          {
            conditionalGroup: 'd_',
            expressions: [...criteria.expressions[0].expressions],
            fieldName: 'id',
            operation: OperationEnum.NUMBER_IN,
            value: globalParameter.usuario.empresas.map(it => it.id),
          },
        ];
      }

      criteria.expressions = adjustExpressions(criteria.expressions);

      findAllByExpression<EmpresaOption>(criteria, {
        errorFunction: mensagem => {
          showToast(mensagem, ERROR);
          reject();
        },
        thenFunction: result => {
          resolve(result.content);
        },
      });
    });

  const openEmpresaSearchView = () => {
    onFocusOut();
    setShowModal(true);
  };

  const toggleSearch = () => setIsOpen(p => !p);
  const onFocusOut = () => setIsOpen(false);

  const onHideModal = () => {
    setShowModal(false);
    setTmpValue(null);
  };

  const onSelectModal = value => setTmpValue(value);

  const onConfirmModal = () => {
    if (tmpValue) {
      changeCurrentEmpresa({ ...tmpValue });
    }
    setShowModal(false);
    setTmpValue(null);
  };

  const getCnpjCpf = data => (data.cnpj ? cnpjFormatter(data.cnpj, null) : cpfFormatter(data.cpf, null));

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <button onClick={openEmpresaSearchView} id="header-company" className="header-company-button">
          <FontAwesomeIcon icon={['far', 'building']} size="sm" className="text-primary" />
        </button>
        <UncontrolledTooltip placement="right" target="header-company">
          Visualizar empresas
        </UncontrolledTooltip>
        <Dropdown isOpen={isOpenFiltros} toggle={toggleFiltros} className="dropdown-filtros">
          <DropdownToggle className="p-0 border-0 font-size-xs dropdown-toggle-user text-primary">
            {getLabelActiveFiltro()} <FontAwesomeIcon icon="chevron-down" className="ml-1" />
          </DropdownToggle>
          <DropdownMenu right={true} className="overflow-hidden p-0">
            <Nav pills={true} className="nav-neutral-primary flex-column font-size-xs">
              {filtroItems.map(({ className, label }) => (
                <NavItem key={label} {...(className && { className })} active={getActiveFiltro().label === label}>
                  <NavLink onClick={changeFiltro(label)} className="py-1">
                    <span>{label}</span>
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </DropdownMenu>
        </Dropdown>
      </components.DropdownIndicator>
    );
  };

  const LoadingIndicator = props => {
    return (
      <components.LoadingIndicator {...props} className="header-company-loading text-primary">
        <BeatLoader loading={true} />
      </components.LoadingIndicator>
    );
  };

  const OptionItem = props => {
    return (
      <components.Option {...props}>
        <div className="header-company-option" title={props.label}>
          <span className="title">{props.label}</span>
          <span className="subtitle">
            {getCnpjCpf(props.data)}
            {getRef(props.data)}
          </span>
        </div>
      </components.Option>
    );
  };

  const bypassInternalSearch = () => true;
  const onSearch = value => setSearchValue(value);
  const noOptionsMessage = () => 'Nenhum resultado encontrado.';
  const control = base => ({ ...base, borderRadius: 0 });

  const onScrollDown = async () => {
    if (!searchValue) {
      setIsLoading(true);
      const result = await find(
        getSearchCriteria([
          {
            comparator: ComparatorEnum.GT,
            fieldName: 'codigo',
            value: Math.max(...empresas.map(it => it.codigo)),
          },
        ])
      );
      setEmpresas(p => [...p, ...result]);
      setIsLoading(false);
    }
  };

  const option = (styles, { isFocused, isSelected }) => {
    const config = TEMA_ENUM_VALUES.find(it => it.value === tema).extraFields.dropDown;

    let backgroundColor = config.default;
    if (isSelected) {
      backgroundColor = config.selected;
    } else if (isFocused) {
      backgroundColor = config.focused;
    }

    return { ...styles, backgroundColor, color: config.color };
  };

  const toggleFiltros = () => {
    if (isOpenFiltros) {
      selectRef.current.blur();
      selectRef.current.focus();
    }
    setIsOpenFiltros(p => !p);
  };

  const expressionDefault = (fieldName, replace = false) => {
    return {
      expression: {
        conditionalGroup: 'd_',
        fieldName,
        value: replace ? (searchValue || '').replace(/\./g, '') : searchValue,
      },
    };
  };

  const filtroItems = [
    {
      className: 'todos',
      customLabel: 'Filtro (todos)',
      label: TODOS,
    },
    {
      className: 'mt-2',
      expression: {
        alias: 'codigo',
        conditionalGroup: 'd_',
        fieldName: 'razaoSocial',
        operation: "cast(p.codigo as text) like '%_value%'",
        value: searchValue,
      },
      label: 'Código',
    },
    {
      ...expressionDefault('razaoSocial'),
      label: 'Razão social',
    },
    {
      ...expressionDefault('nomeFantasia'),
      label: 'Nome fantasia',
    },
    {
      ...expressionDefault('cnpj', true),
      label: 'CNPJ',
    },
    {
      ...expressionDefault('cpf', true),
      label: 'CPF',
    },
    {
      ...expressionDefault('cei'),
      label: 'CEI',
    },
    {
      ...expressionDefault('ie'),
      label: 'IE',
    },
    {
      ...expressionDefault('im'),
      label: 'IM',
    },
    {
      ...expressionDefault('grupo'),
      label: 'Grupo',
    },
  ];

  const getActiveFiltro = () => filtroItems.find(it => it.label === activeFiltro);

  const getLabelActiveFiltro = () => {
    const active = getActiveFiltro();
    return active.customLabel || active.label;
  };

  const changeFiltro = filtro => () => {
    writeFiltroEmpresa(filtro);
    setActiveFiltro(filtro);
    toggleFiltros();
  };

  return (
    <>
      {showModal && (
        <SearchViewModal
          modalProps={{ isOpen: true, toggle: onHideModal, bsSize: 'xl', backdrop: 'static' }}
          onSelect={onConfirmModal}
          searchDataGridProps={{
            selectRow: {
              clickToSelect: true,
              hideSelectAll: true,
              mode: 'radio',
              onSelect: onSelectModal,
            },
          }}
          searchViewId="EmpresaSearchView"
          searchViewProps={{ somenteAtivas: true }}
        />
      )}
      {showEmpresa && (
        <>
          <Button
            id="companySelectorPopover"
            className={cx({ 'company-selector-active': isOpen })}
            color="link"
            onClick={toggleSearch}
            {...generateTestId('companySelectorPopover')}
          >
            <div className="d-flex align-items-center seletor-emp">
              <FontAwesomeIcon icon={['fas', 'building']} size="lg" />
              <div className="seletor-emp-mobile">
                <div className={cx('company-box pl-2', { 'only-reference': showEmpresa })}>
                  <div className="font-weight-bold" style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                    {globalParameter && globalParameter.empresa && `${globalParameter.empresa.codigo} - ${globalParameter.empresa.razaoSocial}`}
                  </div>
                  {showReferencia && <span className="referencia-desktop text-black-50">{referenciaFiscal(globalParameter, currentState)}</span>}
                </div>
              </div>
              <FontAwesomeIcon icon={['fas', 'angle-down']} />
            </div>
            <div className="referencia-mobile">
              {showReferencia && <span className="text-black-50">{referenciaFiscal(globalParameter, currentState, true)}</span>}
            </div>
            <UncontrolledTooltip placement="left" target="companySelectorPopover">
              Alterar empresa
            </UncontrolledTooltip>
          </Button>
          <UncontrolledPopover
            target="companySelectorPopover"
            trigger="legacy"
            isOpen={isOpen}
            container="body"
            className="popover-custom-wrapper popover-custom-lg popover-custom-emp"
            placement="auto"
            toggle={onFocusOut}
          >
            <div className="company-selector">
              <Select
                ref={selectRef}
                id="company-selector-input"
                components={{ DropdownIndicator, LoadingIndicator, Option: OptionItem }}
                styles={{ control, option }}
                options={empresas}
                onChange={changeCurrentEmpresa}
                onMenuScrollToBottom={onScrollDown}
                menuIsOpen={true}
                autoFocus={true}
                onInputChange={debounceRef.current}
                filterOption={bypassInternalSearch}
                isLoading={isLoading}
                placeholder="Selecione uma empresa..."
                noOptionsMessage={noOptionsMessage}
              />
            </div>
          </UncontrolledPopover>
        </>
      )}
    </>
  );
};

export default HeaderCompany;
