import { createLoadable } from 'summer';

const micRoutes = [
  {
    component: createLoadable(() => import('./dashboard/DashboardMIC'), 'DashboardMIC'),
    path: '/mic/dashboard',
  },
  {
    component: createLoadable(() => import('./empresa/EmpresaMICVWSearchView'), 'EmpresaMICVWSearchView'),
    exact: true,
    path: '/mic/empresasSearchView',
  },
  {
    component: createLoadable(() => import('./configuracao/ConfiguracaoView'), 'ConfiguracaoView'),
    exact: true,
    path: '/mic/configuracaoView',
  },
  {
    component: createLoadable(() => import('./geracao/GeracaoSearchView'), 'GeracaoSearchView'),
    exact: true,
    path: '/mic/geracaoSearchView',
  },
  {
    component: createLoadable(() => import('./caixaPostal/CaixaPostalSearchView'), 'CaixaPostalSearchView'),
    exact: true,
    path: '/mic/caixaPostalSearchView',
  },
  {
    component: createLoadable(() => import('./caixaPostal/CaixaPostalEmpresaSearchView'), 'CaixaPostalEmpresaSearchView'),
    exact: true,
    path: '/mic/caixaPostalSearchView/:id',
  },
  {
    component: createLoadable(() => import('./report/detalhamentoCobranca/DetalhamentoCobrancaReportView'), 'DetalhamentoCobrancaReportView'),
    path: '/mic/detalhamentoCobrancaReportView',
  },
];

export default micRoutes;
