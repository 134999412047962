import { MenuItem } from '../../../models/dtos/ger/menuItem';

const boxMenu: MenuItem[] = [
  {
    icon: 'pe-7s-home',
    label: 'Home',
    to: '/box/dashboard',
  },
  {
    content: [
      {
        label: 'Empresas',
        to: '/box/empresasSearchView',
      },
    ],
    icon: 'pe-7s-edit',
    label: 'Manutenção',
  },
  {
    content: [
      {
        label: 'Manifestação de notas',
        to: '/box/manifestacaoNotasSearchView',
      },
      {
        label: 'Importação de notas',
        to: '/box/importarNotasProcessView',
      },
      {
        label: 'Visualização de notas',
        to: '/box/visualizarNotasSearchView',
      },
    ],
    icon: 'pe-7s-play',
    label: 'Processos',
  },
  {
    content: [
      {
        label: 'Relatórios importados por período',
        to: '/box/notasPorPeriodoReportView',
      },
    ],
    icon: 'pe-7s-print',
    isReportList: true,
    label: 'Relatórios',
  },
  {
    content: [
      {
        label: 'Registro de eventos',
        to: '/box/logSearchView',
      },
      {
        label: 'Código de acesso - Box File',
        to: '/box/tokenAcessoBoxFileSearchView',
      },
    ],
    icon: 'pe-7s-tools',
    label: 'Utilitários',
  },
];

export default boxMenu;
