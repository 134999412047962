export const MAXIMO_DIAS_EXPERIENCIA_CONTRATO_TRABALHO = 90;
export const NRO_HORAS_CONTRATUAIS_MENSALISTA = 220;
export const MAX_INT_VALUE = 2147483647;
export const ARQUIVO_DIGITAL_SUCCESS = 'O download do seu arquivo começará em instantes...';
export const ARQUIVO_DIGITAL_INFORMATION =
  'Seu arquivo está sendo gerado. Uma notificação será exibida quando estiver finalizado e o mesmo poderá ser salvo.';
export const OPERACOES_CONSULTA_VALORES: object[] = [
  { label: '>', value: '>' },
  { label: '<', value: '<' },
  { label: '≥', value: '≥' },
  { label: '≤', value: '≤' },
  { label: '=', value: '=' },
  { label: '<>', value: '<>' },
];

export const LOCAL_STORAGE_LOGIN_TOKEN = 'loginToken';
export const LOCAL_STORAGE_SOCKET_HASH = 'socketHash';
export const LOCAL_STORAGE_UNIQUE_USER_HASH = 'uniqueUserHash';
