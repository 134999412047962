import { MenuItem } from '../../../models/dtos/ger/menuItem';

const mcpMenu: MenuItem[] = [
  {
    icon: 'pe-7s-home',
    label: 'Home',
    to: '/mcp/dashboard',
  },
  {
    content: [
      {
        label: 'Conta',
        to: '/mcp/contasSearchView',
      },
      {
        label: 'Bem',
        to: '/mcp/bensSearchView',
      },
      {
        label: 'Configuração de Empresa',
        to: '/mcp/configEmpresaMCPEditView',
      },
    ],
    icon: 'pe-7s-edit',
    label: 'Manutenção',
  },
  {
    content: [
      {
        label: 'Baixas',
        to: '/mcp/baixaSearchView',
      },
      {
        label: 'Transferência de Centro de Custos',
        to: '/mcp/transferenciaCentroCustoSearchView',
      },
      {
        label: 'Fechamento/Restauração',
        to: '/mcp/fechamentoRestauracaoMCPProcessView',
      },
    ],
    icon: 'pe-7s-play',
    label: 'Processos',
  },
  {
    content: [
      {
        content: [
          {
            label: 'Bens - Planilha do Excel (Formato CSV, XLS e XLSX)',
            to: '/mcp/importacaoBemCSVProcessView',
          },
        ],
        label: 'Importação',
      },
    ],
    icon: 'pe-7s-folder',
    label: 'Arquivos digitais',
  },
  {
    content: [
      {
        label: 'Bens Adquiridos na Referência',
        to: '/mcp/bensAdquiridosReportView',
      },
      {
        label: 'Bens Depreciados',
        to: '/mcp/bemDepreciadoReportView',
      },
      {
        label: 'Depreciação do Exercício',
        to: '/mcp/bemDepreciadoExercicioReportView',
      },
      {
        label: 'Etiqueta de Bens',
        to: '/mcp/bensEtiquetaReportView',
      },
      {
        label: 'Ficha Analítica',
        to: '/mcp/bemFichaAnaliticaReportView',
      },
      {
        label: 'Relação de Bens',
        to: '/mcp/relacaoBensContaReportView',
      },
      {
        label: 'Relação de Baixas',
        to: '/mcp/relacaoBaixaReportView',
      },
      {
        label: 'Resumo Contábil',
        to: '/mcp/bemResumoContabilReportView',
      },
      {
        label: 'Relação de Contas',
        to: '/mcp/relacaoContaReportView',
      },
    ],
    icon: 'pe-7s-print',
    isReportList: true,
    label: 'Relatórios',
  },
];

export default mcpMenu;
