import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalFooter, ModalHeader } from 'reactstrap';
import { dec, ERROR, showToast } from 'summer';
import { getUrlAcessoChat } from '../services/ger.service';
import { Reducers } from '../store/ducks';
import { GlobalState } from '../store/ducks/global.duck';
import {
  ID_ATENDIMENTO_COMERCIAL,
  ID_ATENDIMENTO_MFP,
  ID_ATENDIMENTO_MFP_ESOCIAL,
  ID_ATENDIMENTO_MLF,
  ID_ATENDIMENTO_MODULO,
} from '../utilities/wsUtil';
import useLoading from './useLoading';

declare global {
  interface Window {
    PROJETUS_CRISP?: any;
  }
}

export const useCrispAction = () => {
  const { globalParameter, currentState } = useSelector<Reducers, GlobalState>(state => state.globalReducer);
  const [modal, setModal] = useState(null);
  const loading = useLoading();

  const onClose = () => {
    setModal(null);
  };

  const showModal = (label1, label2, action1, action2) => {
    setModal(
      <Modal isOpen={true} centered={true} toggle={onClose} backdrop="static">
        <ModalHeader toggle={onClose}>
          <h5 className="text-center">Selecione o tipo de atendimento desejado</h5>
        </ModalHeader>
        <ModalFooter className="justify-content-center">
          <Button color="first" onClick={action1}>
            {label1}
          </Button>
          <Button color="first" onClick={action2}>
            {label2}
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const open = (idAtendimento: string = null) => {
    if (idAtendimento) {
      openCrispChat(idAtendimento);
    } else {
      switch (currentState) {
        case 'MFP':
          showModal('eSocial', 'Demais Solicitações', openChatESocial, openChatMFP);
          break;
        case 'MIC':
          showModal('DCTFWeb', 'Demais Solicitações', openChatESocial, openChatMLF);
          break;
        case 'GER':
          if (window.PROJETUS_CRISP) {
            window.PROJETUS_CRISP.requestSetor(false).then(id => {
              if (id) {
                openCrispChat(id);
              }
            });
          }
          break;
        default:
          openCrispChat(ID_ATENDIMENTO_MODULO[currentState]);
          break;
      }
    }
  };

  const openChatESocial = () => openCrispChat(ID_ATENDIMENTO_MFP_ESOCIAL);
  const openChatMFP = () => openCrispChat(ID_ATENDIMENTO_MFP);
  const openChatMLF = () => openCrispChat(ID_ATENDIMENTO_MLF);

  const openCrispChat = idAtendimento => {
    setModal(null);
    loading.show();

    getUrlAcessoChat(btoa(encodeURI(dec(globalParameter.usuario.usuario))), globalParameter.acesso.codigoCliente, idAtendimento, {
      errorFunction: message => showToast(message, ERROR),
      thenFunction: res => {
        loading.hide();
        if (res.atendimentoEncontrado && window.PROJETUS_CRISP) {
          window.PROJETUS_CRISP.iniciarChat(res.informacoesAcesso, res.setorSegmentoCrisp, false);
        }
      },
    });
  };

  return { modal, open, onClick: () => open(), openComercial: () => open(ID_ATENDIMENTO_COMERCIAL) };
};
