import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { ERROR, LIGHT, showToast } from 'summer';
import useLoading from '../../../hooks/useLoading';
import { trocarTema } from '../../../services/ger.service';
import { Reducers } from '../../../store/ducks';
import { GlobalState, setGlobalParameter } from '../../../store/ducks/global.duck';

const SidebarLightDarkMode = () => {
  const { globalParameter } = useSelector<Reducers, GlobalState>(state => state.globalReducer);
  const dispatch = useDispatch();
  const loading = useLoading();

  const changeMode = () => {
    loading.show();

    trocarTema(globalParameter.usuario.id, {
      errorFunction: mensagem => {
        loading.hide();
        showToast(mensagem, ERROR);
      },
      thenFunction: usuario => {
        loading.hide();
        dispatch(setGlobalParameter({ ...globalParameter, usuario }));
      },
    });
  };

  return globalParameter.usuario.configuracao.tema === LIGHT.value ? (
    <>
      <Button onClick={changeMode} id="dark-mode" size="sm" color="link" className="p-0 btn-light-dark-mode">
        <FontAwesomeIcon icon="moon" color="#FFF" />
      </Button>
      <UncontrolledTooltip target="dark-mode" placement="right">
        Ativar modo escuro
      </UncontrolledTooltip>
    </>
  ) : (
    <>
      <Button onClick={changeMode} id="light-mode" size="sm" color="link" className="p-0 btn-light-dark-mode">
        <FontAwesomeIcon icon="sun" color="#FFF" />
      </Button>
      <UncontrolledTooltip target="light-mode" placement="right">
        Ativar modo claro
      </UncontrolledTooltip>
    </>
  );
};

export default SidebarLightDarkMode;
